import React from 'react';
import {
    isSideProductOverviewItemType,
    SideProductOverviewItemType,
} from '../../../../../model/sideProductOverview/SideProductOverview';
import SideProductOverviewItem from './SideProductOverviewItem';
import Heading from '../../../../shared/heading/Heading';
import styles from './HighlightedSideProductsList.module.scss';
import createClassName from 'classnames';

type Props = {
    heading: string;
    sideProducts: SideProductOverviewItemType[];
};

const HighlightedSideProductsList = ({ heading, sideProducts }: Props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    <Heading tag="h4">{heading}</Heading>
                    <ul className={createClassName('list-unstyled list-unstyled--medium-spacing', styles.list)}>
                        {sideProducts.map((sideProduct) => {
                            if (!isSideProductOverviewItemType(sideProduct)) return null;
                            return (
                                <li key={sideProduct.id}>
                                    <SideProductOverviewItem
                                        key={sideProduct.id}
                                        title={sideProduct.title}
                                        url={sideProduct.path || ''}
                                        trackId="download-highlighted-side-product"
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HighlightedSideProductsList;
