import React from 'react';
import Heading from '../../../../shared/heading/Heading';
import type { ArticleStandard } from '../../../../../model/Article';
import ArrowRightIcon from '../../../../../../../icons/ggz/arrow-right.svg';
import { Link } from 'react-router-dom';
import useCurrentTenant from '../../../../../hooks/useCurrentTenant';

type Props = {
    standard: ArticleStandard;
};

const RelatedStandard = ({ standard }: Props) => {
    const { isGGZ } = useCurrentTenant();

    if (standard.lastPublication === null) {
        return null;
    }

    return (
        <div className="related-standard">
            <Link to={standard.lastPublication._path}>
                <ArrowRightIcon className="related-standard__icon" aria-hidden />
                <Heading tag="h4" weight="semi-bold" className="related-standard__title">
                    {isGGZ
                        ? `Zorgstandaard ${standard.lastPublication.title}`
                        : `Richtlijn ${standard.lastPublication.title}`}
                </Heading>
            </Link>
            <p className="related-standard__description">{standard.introduction}</p>
        </div>
    );
};

export default RelatedStandard;
