import {
    FETCH_PARAGRAPH_DETAILS,
    UPDATE_PROJECT_PARAGRAPH,
    UPDATE_PROJECT_PARAGRAPH_TITLE,
    START_SUB_PARAGRAPH,
    UPDATE_PROJECT_SUBPARAGRAPH,
    ADD_SUB_PARAGRAPH_TO_PARAGRAPH,
    PARAGRAPH_DETAIL_SUBPARAGRAPH_HAS_CHANGES_CHANGED,
    PARAGRAPH_DETAIL_HAS_CHANGES_CHANGED,
    MOVE_SUB_PARAGRAPH,
    PROJECT_REMOVE_SUB_PARAGRAPH,
    REMOVE_NON_PERSISTED_SUB_PARAGRAPH,
    PUBLISH_PROJECT,
    PROJECT_POST_COMMENT,
    PROJECT_REACT_ON_COMMENT,
    PROJECT_FETCH_COMMENTS,
    RESET_PARAGRAPH_DETAILS,
} from '../actions/types';
import { ActionType } from 'redux-promise-middleware';
import { createParagraphDetailFromApiInput } from '../model/paragraphDetail/factory/paragraphDetailFactory';
import { createEmptyParagraphDetailSubParagraph } from '../model/paragraphDetail/factory/paragraphDetailSubParagraphFactory';
import ParagraphDetail from '../model/paragraphDetail/ParagraphDetail';
import type { Action } from '../actions/factory';
import ParagraphDetailSubParagraph from '../model/paragraphDetail/ParagraphDetailSubParagraph';
import { Subject } from '../context/comments/CommentSubjectContext';
import ParagraphDetailProject from '../model/paragraphDetail/ParagraphDetailProject';

export type ParagraphDetailsReducerState = ParagraphDetail | undefined | null;

const RESET_STATE: ParagraphDetailsReducerState = null;

function _handleUpdateProjectParagraphPendingAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.title = action.payload.title;
    newState.content = action.payload.content;
    newState.contentType = 'lexical';
    newState.hasChanges = false;

    return newState;
}

function _handleUpdateProjectParagraphTitlePendingAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.title = action.payload.title;
    newState.hasChanges = false;

    return newState;
}

function _handleProjectRemoveSubParagraphAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const idOfSubParagraphThatNeedsToBeRemoved = action.payload.subParagraphId;

    newState.removeSubParagraphWithId(idOfSubParagraphThatNeedsToBeRemoved);

    return newState;
}

function _handleUpdateProjectSubParagraphPendingAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    const { subParagraphId, title, content } = action.payload;

    const subParagraphToEdit = newState.subParagraphs.find((subParagraph) => subParagraph.id === subParagraphId);

    if (!subParagraphToEdit) {
        return currentState;
    }

    subParagraphToEdit.title = title;
    subParagraphToEdit.content = content;
    subParagraphToEdit.contentType = 'lexical';
    subParagraphToEdit.hasChanges = false;

    return newState;
}

function _handleMoveSubParagraphPendingAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    const { subParagraphId, newIndex } = action.payload;

    newState.moveSubParagraphToIndex(subParagraphId, newIndex);

    return newState;
}

function _handleUpdateProjectSubParagraphFulfilledAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.hasChanges = false;

    // @ts-ignore -> typescript does not know the action contents
    const externalSubParagraphId = action.payload.data.id;

    const subParagraphThatWasUpdated = newState.subParagraphs.find(
        (subParagraph) => subParagraph.externalId === externalSubParagraphId
    );

    if (!subParagraphThatWasUpdated) {
        return newState;
    }

    subParagraphThatWasUpdated.hasChanges = false;

    return newState;
}

function _handleFetchParagraphDetailsFullfilledAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    // @ts-ignore -> typescript does not know the action contents
    const apiInput: any = action.payload.data.result;

    const incomingState = createParagraphDetailFromApiInput(apiInput);

    // if we have no current state, we can replace the complete state with a new one
    if (!(currentState instanceof ParagraphDetail)) {
        return incomingState;
    }

    // if another paragraph is coming in then the one we have in current state, we cannot merge them.
    if (incomingState.externalId !== currentState.externalId) {
        return incomingState;
    }

    const newState = currentState.clone();

    newState.merge(incomingState);

    return newState;
}

function _handleAddSubParagraphToParagraphFullfilledAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    // as we have a client side sub paragraph that does not yet have an externalId, and it was just persisted, we need to
    // apply the incoming server side id (externalId) to that client side sub paragraph to be able to update it on the server
    // later on.

    const newState = currentState.clone();
    const idOfServerSidePersistedSubParagraph = action.meta.subParagraphId;
    const subParagraph = newState.subParagraphs.find(
        (subParagraph) => subParagraph.id === idOfServerSidePersistedSubParagraph
    );

    if (!subParagraph) {
        return currentState;
    }

    // @ts-ignore -> typescript does not know the action contents
    subParagraph.externalId = action.payload.data.id;

    newState.hasChanges = false;

    return newState;
}

function _handleAddSubParagraphToParagraphPendingAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    const { paragraphId } = action.payload;

    if (paragraphId !== currentState.id) {
        return currentState;
    }

    // find subParagraph which is create as empty object and update title/content when paragraph is submitted to server for first time
    const idOfServerSidePersistedSubParagraph = action.payload.subParagraphId;
    const subParagraph = newState.subParagraphs.find(
        (subParagraph) => subParagraph.id === idOfServerSidePersistedSubParagraph
    );

    // @ts-ignore -> typescript does not know the action contents
    subParagraph.title = action.payload.title;

    // @ts-ignore -> typescript does not know the action contents
    subParagraph.content = action.payload.content ? action.payload.content : null;

    return newState;
}

function _handleStartSubParagraphAction(currentState: ParagraphDetailsReducerState): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const newSubParagraph = createEmptyParagraphDetailSubParagraph();

    newState.subParagraphs.push(newSubParagraph);

    return newState;
}

function _handleRemoveNonPersistedSubParagraphAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    // @ts-ignore -> typescript does not know the action contents
    const idOfSubParagraphToDelete = action.payload.data.id;

    const subParagraphToDelete = newState.getSubParagraphById(idOfSubParagraphToDelete);

    if (!subParagraphToDelete) {
        console.error('Sub paragraph not found. Cannot remove it from global state');

        return currentState;
    }

    if (subParagraphToDelete.isPersistedOnTheServer()) {
        console.error('Sub paragraph is already persisted on the server and can therefor not be deleted this way');

        return currentState;
    }

    newState.removeSubParagraphWithId(idOfSubParagraphToDelete);

    return newState;
}

function _handleParagraphDetailSubParagraphHasChangesChanged(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const data = action.payload.data;

    // @ts-ignore -> typescript does not know the action contents
    const newHasChangesForSubParagraph = data.hasChanges;

    // @ts-ignore -> typescript does not know the action contents
    const subParagraphIdThatChanged = data.subParagraphId;

    const subParagraph = newState.subParagraphs.find((subParagraph) => subParagraph.id === subParagraphIdThatChanged);

    if (!subParagraph) {
        return currentState;
    }

    subParagraph.hasChanges = newHasChangesForSubParagraph;

    return newState;
}

function _handleParagraphDetailHasChangesChanged(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const data = action.payload.data;

    // @ts-ignore -> typescript does not know the action contents
    const { hasChanges, paragraphId } = data;

    if (paragraphId !== newState.id) {
        return currentState;
    }

    newState.hasChanges = hasChanges;

    return newState;
}

function _handleProjectPostCommentPendingAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail) || !currentState.externalId) {
        return currentState;
    }

    const subject: Subject = action.meta.subject;

    if (subject instanceof ParagraphDetail) {
        if (subject.externalId !== currentState.externalId) {
            // comments were put on another paragraph
            return currentState;
        }

        const newState: ParagraphDetail = currentState.clone();

        newState.incrementCommentCount();

        return newState;
    } else if (subject instanceof ParagraphDetailSubParagraph) {
        const indexOfSubParagraph: number = currentState.subParagraphs.findIndex(
            (subParagraph: ParagraphDetailSubParagraph) => subParagraph.externalId === subject.externalId
        );

        if (indexOfSubParagraph === -1) {
            // eslint-disable-line no-magic-numbers
            // comments were put on another sub-pararaph

            return currentState;
        }

        const newState = currentState.clone();

        newState.subParagraphs[indexOfSubParagraph].incrementCommentCount();

        return newState;
    } else if (subject instanceof ParagraphDetailProject) {
        const newState = currentState.clone();

        newState.chapter.project.incrementCommentCount();

        return newState;
    }

    return currentState;
}

function _handleProjectReactOnCommentPendingAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail) || !currentState.externalId) {
        return currentState;
    }

    const isConcept: boolean = action.payload.concept;

    if (isConcept) {
        return currentState;
    }

    const subject: Subject = action.meta.subject;

    if (subject instanceof ParagraphDetail) {
        if (subject.externalId !== currentState.externalId) {
            // comments were put on another paragraph
            return currentState;
        }

        const newState: ParagraphDetail = currentState.clone();

        newState.incrementAnsweredCommentCount();

        return newState;
    } else if (subject instanceof ParagraphDetailSubParagraph) {
        const indexOfSubParagraph: number = currentState.subParagraphs.findIndex(
            (subParagraph: ParagraphDetailSubParagraph) => subParagraph.externalId === subject.externalId
        );

        if (indexOfSubParagraph === -1) {
            // eslint-disable-line no-magic-numbers
            // comments were put on another sub-pararaph

            return currentState;
        }

        const newState = currentState.clone();

        newState.subParagraphs[indexOfSubParagraph].incrementAnsweredCommentCount();

        return newState;
    } else if (subject instanceof ParagraphDetailProject) {
        const newState = currentState.clone();

        newState.chapter.project.incrementAnsweredCommentCount();

        return newState;
    }

    return currentState;
}

function _handleProjectFetchCommentsFulfilledAction(
    currentState: ParagraphDetailsReducerState,
    action: Action
): ParagraphDetailsReducerState {
    if (!(currentState instanceof ParagraphDetail) || !currentState.externalId) {
        return currentState;
    }

    const subject: Subject = action.meta.subject;

    if (subject instanceof ParagraphDetail) {
        if (subject.externalId !== currentState.externalId) {
            // comments were put on another paragraph

            return currentState;
        }

        const newState: ParagraphDetail = currentState.clone();

        newState.commentCount = action.payload.length;

        return newState;
    } else if (subject instanceof ParagraphDetailSubParagraph) {
        const indexOfSubParagraph: number = currentState.subParagraphs.findIndex(
            (subParagraph: ParagraphDetailSubParagraph) => subParagraph.externalId === subject.externalId
        );

        if (indexOfSubParagraph === -1) {
            // eslint-disable-line no-magic-numbers
            // comments were put on another sub-pararaph

            return currentState;
        }

        const newState = currentState.clone();

        newState.subParagraphs[indexOfSubParagraph].commentCount = action.payload.length;

        return newState;
    }

    return currentState;
}

export default function (
    currentState: ParagraphDetailsReducerState = RESET_STATE,
    action: Action
): ParagraphDetailsReducerState {
    switch (action.type) {
        case `${FETCH_PARAGRAPH_DETAILS}_${ActionType.Fulfilled}`:
            return _handleFetchParagraphDetailsFullfilledAction(currentState, action);

        case `${ADD_SUB_PARAGRAPH_TO_PARAGRAPH}_${ActionType.Pending}`:
            return _handleAddSubParagraphToParagraphPendingAction(currentState, action);

        case `${ADD_SUB_PARAGRAPH_TO_PARAGRAPH}_${ActionType.Fulfilled}`:
            return _handleAddSubParagraphToParagraphFullfilledAction(currentState, action);

        case START_SUB_PARAGRAPH:
            return _handleStartSubParagraphAction(currentState);

        case REMOVE_NON_PERSISTED_SUB_PARAGRAPH:
            return _handleRemoveNonPersistedSubParagraphAction(currentState, action);

        case `${UPDATE_PROJECT_PARAGRAPH}_${ActionType.Pending}`:
            return _handleUpdateProjectParagraphPendingAction(currentState, action);

        case `${UPDATE_PROJECT_PARAGRAPH_TITLE}_${ActionType.Pending}`:
            return _handleUpdateProjectParagraphTitlePendingAction(currentState, action);

        case `${PROJECT_REMOVE_SUB_PARAGRAPH}_${ActionType.Pending}`:
            return _handleProjectRemoveSubParagraphAction(currentState, action);

        case `${UPDATE_PROJECT_SUBPARAGRAPH}_${ActionType.Pending}`:
            return _handleUpdateProjectSubParagraphPendingAction(currentState, action);

        case `${UPDATE_PROJECT_SUBPARAGRAPH}_${ActionType.Fulfilled}`:
            return _handleUpdateProjectSubParagraphFulfilledAction(currentState, action);

        case PARAGRAPH_DETAIL_SUBPARAGRAPH_HAS_CHANGES_CHANGED:
            return _handleParagraphDetailSubParagraphHasChangesChanged(currentState, action);

        case PARAGRAPH_DETAIL_HAS_CHANGES_CHANGED:
            return _handleParagraphDetailHasChangesChanged(currentState, action);

        case `${MOVE_SUB_PARAGRAPH}_${ActionType.Pending}`:
            return _handleMoveSubParagraphPendingAction(currentState, action);

        case `${PUBLISH_PROJECT}_${ActionType.Pending}`:
            // force new retrieval of project details with closed state, that makes sure it is no longer accessible
            return RESET_STATE;

        case `${PROJECT_POST_COMMENT}_${ActionType.Pending}`:
            return _handleProjectPostCommentPendingAction(currentState, action);

        case `${PROJECT_REACT_ON_COMMENT}_${ActionType.Pending}`:
            return _handleProjectReactOnCommentPendingAction(currentState, action);

        case `${PROJECT_FETCH_COMMENTS}_${ActionType.Fulfilled}`:
            return _handleProjectFetchCommentsFulfilledAction(currentState, action);

        case RESET_PARAGRAPH_DETAILS:
            return RESET_STATE;

        default:
            return currentState;
    }
}
