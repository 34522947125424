import appMockupImageUrl from '../../../../../../images/ggz/mockup-ggz-app.png';
import styles from '../DecisionAidApp.module.scss';
import React, { VFC } from 'react';
import createClassName from 'classnames';

type Props = {
    additionalClassName?: string;
};

const MockupImage: VFC<Props> = ({ additionalClassName }) => {
    const classNames = createClassName(styles.mockUpImage, additionalClassName);

    return <img alt="GGZ Standaarden keuzehulp-app" src={appMockupImageUrl} className={classNames} />;
};

export default MockupImage;
