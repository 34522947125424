import React from 'react';
import { OverviewArticle } from '../../../../../model/Article';
import classnames from '../articleList.module.scss';
import { formatLongDay } from '../../../../../helper/dateTimeHelper';
import { Link } from 'react-router-dom';
import { createArticleDetailPath } from '../../../../../routing/urlGenerator';
import Heading from '../../../../shared/heading/Heading';
import TruncateMarkup from 'react-truncate-markup';

type Props = {
    article: OverviewArticle;
    isSmall?: boolean;
    showImage: boolean;
};

const ListItem: React.FC<Props> = ({ article, showImage, isSmall = false }) => {
    const { publishedAt, id, slug, title, description } = article;

    return (
        <>
            {showImage && article.imageUrl && (
                <Link to={createArticleDetailPath(id, slug)}>
                    <img
                        src={article.imageUrl}
                        alt={title}
                        width="540"
                        height="310"
                        loading="lazy"
                        className={classnames.image}
                    />
                </Link>
            )}
            {publishedAt && (
                <time dateTime={publishedAt} className={classnames.publishingDate}>
                    {formatLongDay(publishedAt)}
                </time>
            )}

            <Link className="link link--primary" to={createArticleDetailPath(id, slug)}>
                {isSmall ? (
                    <Heading tag="h3" variant="h4" weight="semi-bold" flatten className={classnames.heading}>
                        {title}
                    </Heading>
                ) : (
                    <Heading tag="h2" variant="h3" flatten className={classnames.heading}>
                        {title}
                    </Heading>
                )}
            </Link>
            <TruncateMarkup lines={3}>
                <p className={classnames.previewContent}>{description}</p>
            </TruncateMarkup>
        </>
    );
};

export default ListItem;
