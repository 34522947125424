import React from 'react';
import Page from '../../../shared/page/Page';
import useTrackVisitedPageTypeForPage from '../hooks/useTrackVisitedPageTypeForPage';
import Heading from '../../../../shared/heading/Heading';
import DefaultHeader from '../../../../shared/header/DefaultHeader';
import ContactTeaser from '../../shared/ContactTeaser/ContactTeaser';
import Footer from '../../shared/Footer';

type Props = {
    renderForModal: boolean | undefined | null;
};

const DisclaimerPage = ({ renderForModal = false }: Props) => {
    const title = 'Algemene Voorwaarden';

    useTrackVisitedPageTypeForPage(title);

    const renderContent = () => {
        return (
            <div>
                <p>
                    De inhoud van de website www.ggzstandaarden.nl is met de grootste zorg samengesteld. Het is ons
                    streven om zo actueel mogelijke informatie te geven. Ondanks de betrachte zorgvuldigheid kan het
                    voorkomen dat informatie is verouderd of onjuistheden bevat. Het copyright op alle teksten berust
                    bij Akwa GGZ. U mag deze downloaden voor eigen gebruik, maar het is niet toegestaan om zonder
                    voorafgaande toestemming van Akwa GGZ gepubliceerde teksten of gedeelten daarvan over te nemen, te
                    (doen) publiceren of anderszins openbaar te maken of te verveelvoudigen. Akwa GGZ is in geen geval
                    aansprakelijk voor enige schade die het directe of indirecte gevolg is van of in verband staat met
                    het gebruik van de op deze website beschikbare informatie.
                </p>
                <Heading tag="h2" variant="h3">
                    Copyright
                </Heading>
                <p>
                    Alle teksten van deze website zijn auteursrechtelijk beschermd en zijn intellectueel eigendom van
                    Akwa GGZ. Wilt u teksten van deze website overnemen, dan is dat slechts toegestaan onder de
                    voorwaarde dat u bij deze tekst:
                </p>
                <ul>
                    <li>een bronvermelding plaatst;</li>
                    <li>vermeld dat er copyright van Akwa GGZ op rust;</li>
                    <li>een werkende link naar deze website opneemt.</li>
                </ul>
                <Heading tag="h2" variant="h3">
                    Vragen?
                </Heading>
                <p>
                    Heeft u een vraag over onze algemene voorwaarden? Stuur dan een e-mail naar{' '}
                    <a href="mailto:ggzstandaarden@akwaggz.nl">ggzstandaarden@akwaggz.nl</a>.
                </p>
                <Heading tag="h3" variant="h4">
                    Ontwerp en technische realisatie
                </Heading>
                <p>
                    <a href="https://www.freshheads.com" target="_blank" rel="noopener noreferrer">
                        Freshheads
                    </a>
                </p>
                <p>© Copyright 2018 Akwa GGZ</p>
            </div>
        );
    };

    if (renderForModal) {
        return renderContent();
    }

    return (
        <>
            <DefaultHeader />
            <Page title={title}>{renderContent()}</Page>
            <ContactTeaser />
            <Footer />
        </>
    );
};

export default DisclaimerPage;
