import styles from '../StandardOverviewPage.module.scss';
import { Link } from 'react-scroll';
import React, { VFC } from 'react';
import { StandardOverviewItem } from '../../../../../model/standardOverview/StandardOverviewItem';
import createClassName from 'classnames';

type Props = {
    standardListItems: Record<string, StandardOverviewItem[]>;
    hasActiveFilter: boolean;
};

const AlphabeticalNavigation: VFC<Props> = ({ standardListItems, hasActiveFilter }) => {
    // prettier-ignore
    const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

    return (
        <div className={styles.alphabeticalNavigationContainer}>
            <ol type="A" className={styles.alphabeticalNavigation}>
                {alphabet.map((letter) => {
                    if (!hasActiveFilter && letter in standardListItems) {
                        return (
                            <li key={letter} className={styles.alphabeticalNavigationItem}>
                                <Link
                                    className={styles.link}
                                    activeClass={styles.activeLink}
                                    to={letter}
                                    smooth
                                    spy
                                    offset={-35} // to be at same level as sticky menu
                                >
                                    {letter}
                                </Link>
                            </li>
                        );
                    }

                    return (
                        <li key={letter} className={styles.alphabeticalNavigationItem}>
                            <span className={createClassName(styles.link, styles.isDisabled)}>{letter}</span>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

export default AlphabeticalNavigation;
