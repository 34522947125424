import { ProjectSideProductToolType, ProjectSideProductType } from '../ProjectSideProduct';
import { ContentType } from '../../constants/content';

export interface SideProductOverviewItemType {
    id: string;
    title: string;
    type: ProjectSideProductType;
    toolType: ProjectSideProductToolType;
    filename: string | undefined | null;
    path: string | undefined | null;
    description: string | null;
    highlightText: string | null;
    resourceThumbnailUrl: string | null;
    resourceUri: string | null;
    resourceDurationInSeconds: number | null;
    publishedAt: string | null;
    breadcrumbs: string[] | null;
}

export interface SideProductOverview {
    all: SideProductOverviewItemType[];
    publishedRecently: SideProductOverviewItemType[];
    highlighted: SideProductOverviewItemType[];
}

export const isSideProductOverviewItemType = (item: SideProductOverviewItemType): item is SideProductOverviewItemType =>
    item.hasOwnProperty('id') && item.hasOwnProperty('type');

export const isSideProductOverviewVideoType = (item: SideProductOverviewItemType) =>
    isSideProductOverviewItemType(item) &&
    item.toolType === ProjectSideProductToolType.video &&
    item.hasOwnProperty('resourceUri') &&
    item.resourceUri !== null;

export const isSideProductOverviewRegularCaseCardType = (item: SideProductOverviewItemType) =>
    isSideProductOverviewItemType(item) &&
    item.toolType === ProjectSideProductToolType.caseCard &&
    item.hasOwnProperty('filename') &&
    item.filename !== null;

export const isSideProductOverviewInteractiveCaseCardType = (item: SideProductOverviewItemType) =>
    isSideProductOverviewItemType(item) &&
    item.toolType === ProjectSideProductToolType.caseCard &&
    item.hasOwnProperty('resourceUri') &&
    item.resourceUri !== null;

export interface SideProductCategoryDetails {
    id: string;
    title: string;
    shortTitle: string;
    slug: string;
    identifier: string;
    shortDescription: string;
    description: string;
    descriptionType: ContentType;
    imageUrl: string;
}
