import React from 'react';
import PrimarySearchResults from './PrimarySearchResults';
import NestedSearchResults from './NestedSearchResults';
import PaginatedCollection from '../../../../../model/collection/paginatedCollection';
import NoResultsMessage from './NoResultsMessage';
import ProjectSearchItem from '../../../../../model/ProjectSearchItem';
import {
    SIDE_PRODUCT_SEARCHRESULTS_CONTAINER_ID,
    TOPLEVEL_SEARCHRESULTS_CONTAINER_ID,
} from '../../../../../constants/searchResults';

type Props = {
    toplevelResults: PaginatedCollection<ProjectSearchItem>;
    sideProductResults: PaginatedCollection<ProjectSearchItem>;
    nestedResults: PaginatedCollection<ProjectSearchItem>;
    currentQuery: string;
};

const SearchResultOverview = ({ toplevelResults, sideProductResults, nestedResults, currentQuery }: Props) => {
    if (toplevelResults.hasNoResults() && nestedResults.hasNoResults()) {
        return <NoResultsMessage />;
    }

    const toplevelResultsPrefixes = {
        single: 'standaard',
        plural: 'standaarden',
    };

    const sideProductResultsPrefixes = {
        single: 'hulpmiddel',
        plural: 'hulpmiddelen',
    };

    return (
        <div>
            <PrimarySearchResults
                results={toplevelResults}
                currentQuery={currentQuery}
                containerId={TOPLEVEL_SEARCHRESULTS_CONTAINER_ID}
                prefixes={toplevelResultsPrefixes}
            />
            <PrimarySearchResults
                results={sideProductResults}
                currentQuery={currentQuery}
                containerId={SIDE_PRODUCT_SEARCHRESULTS_CONTAINER_ID}
                prefixes={sideProductResultsPrefixes}
            />
            <NestedSearchResults nestedResults={nestedResults} currentQuery={currentQuery} />
        </div>
    );
};

export default SearchResultOverview;
