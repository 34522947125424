import React from 'react';

const NoResultsMessage = () => (
    <div className="search-results__no-results">
        <p>
            Uw zoekactie heeft helaas geen zoekresultaten opgeleverd. Probeer het opnieuw met andere zoektermen of maak
            de zoektermen algemener.
        </p>
    </div>
);

export default NoResultsMessage;
