import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOneWithSlug } from '../repository/pageRepository';
import { logError } from '../utility/Logger';
import { Page } from '../model/Page';

export default function useFetchPageOnSlugChange(incomingSlug?: string) {
    const [page, setPage] = useState<Page | null>(null);
    const { slug } = useParams<{ slug: string }>();

    useEffect(() => {
        getOneWithSlug(incomingSlug ?? slug)
            .then((page) => setPage(page))
            .catch((error) => logError(error));
    }, [slug]);

    return { page };
}
