import React, { VFC } from 'react';
import styles from '../OverviewPageLayout.module.scss';

export type Props = {
    children: React.ReactNode;
    title: string;
};

const OverviewFilterGroup: VFC<Props> = ({ children, title }) => {
    return (
        <div key={title} className={styles.filterGroup}>
            <span className={styles.filterGroupTitle}>{title}</span>
            <ul className={styles.filterList}>{children}</ul>
        </div>
    );
};

export default OverviewFilterGroup;
