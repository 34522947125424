import React, { useState } from 'react';
import Tabs from '../../../../shared/tabs/Tabs';
import Tab from '../../../../shared/tabs/components/Tab';
import {
    isSideProductOverviewInteractiveCaseCardType,
    isSideProductOverviewRegularCaseCardType,
    SideProductOverviewItemType,
} from '../../../../../model/sideProductOverview/SideProductOverview';
import SideProductOverviewItem from './SideProductOverviewItem';
import SideProductOverview from '../SideProductOverview';
import SideProductOverviewInteractiveCaseCardItem from './SideProductOverviewInteractiveCaseCardItem';
import styles from '../SideProductOverview.module.scss';
import { sortByField } from '../../../../../helper/sortingHelper';

const TEST_YOURSELF = 'Test jezelf';
const READ_A_CASE_CARD = 'Lees een casus';

type Props = {
    caseCardSideProducts: SideProductOverviewItemType[];
};

const CaseCardSideProductOverview = ({ caseCardSideProducts }: Props) => {
    const [activeTab, setActiveTab] = useState(TEST_YOURSELF);
    const regularCaseCards = caseCardSideProducts.filter((sideProduct) =>
        isSideProductOverviewRegularCaseCardType(sideProduct)
    );

    const interactiveCaseCards = sortByField<SideProductOverviewItemType, Array<string>, string>(
        caseCardSideProducts.filter((sideProduct) => isSideProductOverviewInteractiveCaseCardType(sideProduct)),
        'breadcrumbs',
        (value) => value?.join('')
    );

    if (!caseCardSideProducts) {
        return null;
    }

    return (
        <div>
            <div>
                <Tabs style={'outline'}>
                    <Tab isActive={activeTab === TEST_YOURSELF} onClick={() => setActiveTab(TEST_YOURSELF)}>
                        <span className={styles.tab}>{TEST_YOURSELF}</span>
                    </Tab>
                    <Tab isActive={activeTab === READ_A_CASE_CARD} onClick={() => setActiveTab(READ_A_CASE_CARD)}>
                        <span className={styles.tab}>{READ_A_CASE_CARD}</span>
                    </Tab>
                </Tabs>
            </div>
            <div>
                {activeTab === TEST_YOURSELF && (
                    <>
                        <p className={styles.description}>
                            Met een interactieve casus leer je in 15 minuten over aanbevelingen uit zorgstandaarden en
                            hoe je die gebruikt. Vanuit het perspectief van een professional beantwoord je vragen over
                            dilemma’s uit de praktijk. Vervolgens vergelijk je jouw antwoord met de aanbevelingen uit de
                            zorgstandaard.
                        </p>
                        <SideProductOverview emptyStateText="Geen resultaten..." style="grid">
                            {interactiveCaseCards.map((interactiveCaseCard) => (
                                <SideProductOverviewInteractiveCaseCardItem
                                    key={interactiveCaseCard.id}
                                    title={interactiveCaseCard.title}
                                    url={interactiveCaseCard.resourceUri || ''}
                                    thumbnailUrl={interactiveCaseCard.resourceThumbnailUrl || ''}
                                    breadcrumbs={interactiveCaseCard.breadcrumbs}
                                    trackId="download-side-product"
                                    trackData={JSON.stringify({
                                        type: `${interactiveCaseCard.toolType}-interactive`,
                                    })}
                                />
                            ))}
                        </SideProductOverview>
                    </>
                )}
                {activeTab === READ_A_CASE_CARD && (
                    <>
                        <p className={styles.description}>
                            Een concrete casus uit de praktijk met een aantal dilemma’s. Aan de hand daarvan lees je
                            welke informatie je in de zorgstandaarden vindt en waarvoor je die kan gebruiken.
                        </p>
                        <SideProductOverview emptyStateText="Geen resultaten..." style="list">
                            {regularCaseCards.map((regularCaseCard) => (
                                <SideProductOverviewItem
                                    key={regularCaseCard.id}
                                    title={regularCaseCard.title}
                                    url={regularCaseCard.path || ''}
                                    trackId="download-side-product"
                                    trackData={JSON.stringify({
                                        type: `${regularCaseCard.toolType}-regular`,
                                    })}
                                />
                            ))}
                        </SideProductOverview>
                    </>
                )}
            </div>
        </div>
    );
};

export default CaseCardSideProductOverview;
