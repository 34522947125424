import React, { VFC } from 'react';
import StandardListGroup from './StandardListGroup';
import StandardListItem from './StandardListItem';
import { StandardOverviewItem } from '../../../../../model/standardOverview/StandardOverviewItem';

type Props = {
    items: Record<string, StandardOverviewItem[]>;
};

const StandardList: VFC<Props> = ({ items }) => (
    <>
        <nav role="navigation" className="standard-list">
            {Object.keys(items).map((firstLetter) => (
                <StandardListGroup key={firstLetter} firstLetter={firstLetter} name={firstLetter}>
                    {items[firstLetter].map((item) => (
                        <StandardListItem item={item} key={item.externalId} />
                    ))}
                </StandardListGroup>
            ))}
        </nav>
    </>
);
export default StandardList;
