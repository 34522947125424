import useTrackVisitedPageTypeOnChange from '../../../../../hooks/useTrackVisitedPageTypeOnChange';
import { pageType } from '../../../../../constants/pageTypes';

export default function useTrackVisitedPageTypeForPage(title: string) {
    useTrackVisitedPageTypeOnChange(
        pageType.page,
        {
            title,
        },
        [title]
    );
}
