import { ProjectTabIdentifier, resolveVisibleTabs } from '../../../../../resolver/projectDetailTabsResolver';
import { StandardOverviewItem } from '../../../../../model/standardOverview/StandardOverviewItem';

export const resolveTabQuicklinks = (item: StandardOverviewItem) => {
    const visibleTabs = resolveVisibleTabs(item.sideProductTypes, item.linkTypes);

    // don't show accountability tab as there is no need to put additional
    // focus on it.
    return visibleTabs.filter((identifier) => identifier !== ProjectTabIdentifier.accountability);
};
