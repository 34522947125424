import React, { lazy, Suspense } from 'react';
import LazyLoadingLoader from '../../../shared/lazyLoading/LazyLoadingLoader';
import ErrorBoundary from '../../shared/errorBoundary/ErrorBoundary';

const LazyProjectDetail = lazy(
    () => import(/* webpackChunkName: "frontend_project_detail" */ '../projectDetail/ProjectDetail')
);

const LazyLoadingProjectDetail = () => (
    <ErrorBoundary>
        <Suspense fallback={<LazyLoadingLoader />}>
            <LazyProjectDetail />
        </Suspense>
    </ErrorBoundary>
);

export default LazyLoadingProjectDetail;
