import React, { ReactElement } from 'react';
import classnames from '../articleList.module.scss';
import createClassName from 'classnames';

type Props = {
    children: ReactElement[];
    isCompact?: boolean;
    isHorizontal?: boolean;
    isSmall?: boolean;
};

const List: React.FC<Props> = ({ children, isCompact = false, isHorizontal = false, isSmall = false }) => {
    const classname = createClassName([classnames.list], {
        [classnames.isCompact]: isCompact,
        [classnames.isHorizontal]: isHorizontal,
        [classnames.isSmall]: isSmall,
    });

    return (
        <ul className={classname}>
            {React.Children.map(children, (child: ReactElement) => (
                <li key={child.key} className={classnames.item}>
                    {child}
                </li>
            ))}
        </ul>
    );
};

export default List;
