import React, { RefObject } from 'react';
import SearchIcon from '../../../../../../../icons/ggz/search.svg';
import styles from '../StandardOverviewPage.module.scss';
import createClassName from 'classnames';

type Props = {
    searchRef: RefObject<HTMLInputElement>;
    onChange: () => void;
};

const StandardOverviewSearchForm = ({ searchRef, onChange }: Props) => {
    const classNames = createClassName(
        'form__field form__field--rounded form__field--solid form__field--dark',
        styles.searchFormField
    );

    return (
        <form onSubmit={(e) => e.preventDefault()} className={styles.searchForm} noValidate>
            <div className={styles.formGroup}>
                <input
                    ref={searchRef}
                    type="search"
                    name="search"
                    className={classNames}
                    placeholder="Zoek naar standaarden"
                    onChange={onChange}
                />
                <SearchIcon className={styles.icon} />
            </div>
        </form>
    );
};

export default StandardOverviewSearchForm;
