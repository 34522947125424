import React, { FC } from 'react';
import styles from '../OverviewPageLayout.module.scss';
import OverviewFilterGroup from './OverviewFilterGroup';
import OverviewFilterItem from './OverviewFilterItem';
import createClassName from 'classnames';
import OverviewFilterToggle from './OverviewFilterToggle';
import { useIsMobile } from '../../../../../hooks/useIsMobile';
import { getToolTypeFromSideProductLabel } from '../../sideProductOverviewPage/utility/getToolTypeFromSideProductLabel';
import { ProjectSideProductToolType } from '../../../../../model/ProjectSideProduct';
import { CategoryGroup } from '../../../../../utility/sideProductCategories';
import Button from '../../../../shared/buttons/Button';

export type Props = {
    children?: React.ReactNode;
    filterGroups: CategoryGroup[];
    activeFilter: string | null;
    setActiveFilter: (filter: string | null) => void;
    clearFiltersTitle?: string;
};

const OverviewAsideFilters: FC<Props> = ({
    children,
    filterGroups,
    activeFilter,
    setActiveFilter,
    clearFiltersTitle,
}) => {
    const isMobile = useIsMobile();

    const clearFiltersButtonClassNames = createClassName(styles.clearFiltersButton, {
        [styles.isActive]: !activeFilter,
    });

    return (
        <div className={styles.asideFilters}>
            {isMobile && <span className={createClassName(styles.activeFilter)}>{activeFilter}</span>}
            <OverviewFilterToggle>
                {(isMobile && !activeFilter) || !clearFiltersTitle ? null : (
                    <Button
                        isCleared
                        additionalClassName={clearFiltersButtonClassNames}
                        onClick={() => setActiveFilter(null)}
                    >
                        {clearFiltersTitle}
                    </Button>
                )}
                {children}
                {filterGroups.map((group) => (
                    <OverviewFilterGroup key={group.title} title={group.title}>
                        {group.filters.map((filterTitle) => {
                            const filterAsToolType = getToolTypeFromSideProductLabel(filterTitle);

                            // don't include other category in list
                            if (filterAsToolType === ProjectSideProductToolType.other) return;

                            if (isMobile && activeFilter === filterTitle) {
                                return null;
                            }

                            // remove filter from list on mobile if it is active
                            return (
                                <OverviewFilterItem
                                    key={filterTitle}
                                    isActive={activeFilter === filterTitle}
                                    onClick={() => setActiveFilter(filterTitle)}
                                >
                                    {filterTitle}
                                </OverviewFilterItem>
                            );
                        })}
                    </OverviewFilterGroup>
                ))}
            </OverviewFilterToggle>
        </div>
    );
};

export default OverviewAsideFilters;
