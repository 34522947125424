import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import DefaultHeader from '../../../shared/header/DefaultHeader';
import styles from './OverviewPageLayout.module.scss';
import Footer from '../shared/Footer';
import ContactTeaser from '../shared/ContactTeaser/ContactTeaser';
import { getDefaultSeoTitle } from '../../../../helper/seoHelper';

type Props = {
    children: React.ReactNode;
    seoTitle?: string;
};

const OverviewPageLayout: FC<Props> = ({ children, seoTitle }) => {
    return (
        <>
            <Helmet>
                <title>{seoTitle ?? getDefaultSeoTitle()}</title>
            </Helmet>
            <DefaultHeader />
            <section className="section">
                <div className="container">
                    <div className={styles.sectionsWrapper}>{children}</div>
                </div>
            </section>
            <ContactTeaser />
            <Footer />
        </>
    );
};

export default OverviewPageLayout;
