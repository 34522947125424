import { useProjectDetails } from './useProjectDetails';
import useCurrentUser from './useCurrentUser';
import { UserPlatformRole } from '../model/User';
import {
    EditorSettings,
    getProjectEditorSettingsByUserPlatformRole,
} from '../components/dashboard/lexicalEditor/utils/editorSettings';
import useSettings from './useSettings';

export const useGetProjectEditorSettingsForUser = (): EditorSettings | null => {
    const user = useCurrentUser();
    const { details } = useProjectDetails();
    const settings = useSettings();

    if (!user || !details) {
        return null;
    }

    if (user.hasSuperAdminRole() || user.hasRole(UserPlatformRole.Admin)) {
        return getProjectEditorSettingsByUserPlatformRole(UserPlatformRole.SuperAdmin, {
            headings: false,
        });
    }

    const isWorkingGroupMember = details.workingGroupMembers.find(
        (member) => member.externalUserId === user.externalId
    );

    if (isWorkingGroupMember) {
        if (!details.workingGroupMembersWritingAccess) {
            return getProjectEditorSettingsByUserPlatformRole(undefined, {
                comments: true,
            });
        }

        return getProjectEditorSettingsByUserPlatformRole(UserPlatformRole.WorkingGroupMember, {
            headings: false,
        });
    }

    const featureRestrictedCommentsEnabled = Boolean(settings?.featureFlags.restrictedCommentsEnabled);

    const isExternalPartyMember = details.externalPartyMembers.find(
        (member) => member.externalUserId === user.externalId
    );

    if (isExternalPartyMember) {
        return getProjectEditorSettingsByUserPlatformRole(undefined, {
            comments: details.isInConsultationPhase && !featureRestrictedCommentsEnabled,
        });
    }

    const isSoundingBoardMember = details.soundingBoardMembers.find(
        (member) => member.externalUserId === user.externalId
    );

    if (isSoundingBoardMember) {
        return getProjectEditorSettingsByUserPlatformRole(undefined, {
            comments: !featureRestrictedCommentsEnabled,
        });
    }

    return getProjectEditorSettingsByUserPlatformRole();
};
