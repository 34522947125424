import React, { FC, useEffect, useState } from 'react';
import OverviewPageLayout from '../overviewPageLayout/OverviewPageLayout';
import OverviewAsideFilters from '../overviewPageLayout/components/OverviewAsideFilters';
import OverviewListWrapper from '../overviewPageLayout/components/OverviewListWrapper';
import SideProductOverview from '../../shared/sideProductOverview/SideProductOverview';
import SideProductOverviewItem from '../../shared/sideProductOverview/components/SideProductOverviewItem';
import {
    projectSideProductCategorySlugLabels,
    ProjectSideProductToolType,
    projectSideProductToolTypeLabels,
} from '../../../../model/ProjectSideProduct';
import useFetchSideProductOverviewItems from '../../../../hooks/useFetchSideProductOverviewItems';
import Loader from '../../../shared/Loader';
import {
    isSideProductOverviewItemType,
    isSideProductOverviewVideoType,
} from '../../../../model/sideProductOverview/SideProductOverview';
import { SIDE_PRODUCTS_PAGE_TITLE } from '../../../../helper/seoHelper';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { getToolTypeFromSideProductCategorySlug } from './utility/getToolTypeFromSideProductCategorySlug';
import SideProductOverviewVideoItem from '../../shared/sideProductOverview/components/SideProductOverviewVideoItem';
import CategoryIntroduction from './components/CategoryIntroduction';
import { getCategoryGroupLabels } from '../../../../utility/sideProductCategories';
import { getToolTypeFromSideProductLabel } from './utility/getToolTypeFromSideProductLabel';
import { decideTogetherVideo, explainerDirectiveVideo } from './constants';
import {
    createCasecardsSideProductsOverviewPagePath,
    createToolkitsSideProductsOverviewPagePath,
    createVideosSideProductsOverviewPagePath,
} from '../../../../routing/ggzUrlGenerator';
import HighlightedSideProductsList from '../../shared/sideProductOverview/components/HighlightedSideProductsList';
import CaseCardSideProductOverview from '../../shared/sideProductOverview/components/CaseCardSideProductOverview';
import ToolkitsSideProductOverview from '../../shared/sideProductOverview/components/ToolkitsSideProductOverview';

export type RouteParams = {
    category: string;
};

const SideProductOverviewPage: FC = () => {
    const { sideProducts, recentSideProducts, highlightedSideProducts } = useFetchSideProductOverviewItems();
    const history = useHistory();
    const isVideoSideProductsPage = useRouteMatch({ path: createVideosSideProductsOverviewPagePath(), exact: true });
    const isCasecardSideProductsPage = useRouteMatch({
        path: createCasecardsSideProductsOverviewPagePath(),
        exact: true,
    });
    const isToolkitsSideProductsPage = useRouteMatch({
        path: createToolkitsSideProductsOverviewPagePath(),
        exact: true,
    });
    const { category } = useParams<RouteParams>();
    const initialFilter =
        projectSideProductToolTypeLabels[
            getToolTypeFromSideProductCategorySlug(category) as ProjectSideProductToolType
        ] ?? null;
    const [activeFilter, setActiveFilter] = useState<string | null>(initialFilter || '');

    const activeFilterAsToolType = activeFilter && getToolTypeFromSideProductLabel(activeFilter);
    const isGridView = activeFilterAsToolType === ProjectSideProductToolType.video;

    useEffect(() => {
        const newSlug = projectSideProductCategorySlugLabels[activeFilterAsToolType as ProjectSideProductToolType];
        history.replace(newSlug);
    }, [activeFilter]);

    if (!sideProducts) {
        return <Loader />;
    }

    const filteredSideProducts = activeFilter
        ? {
              all: sideProducts.filter((sideProduct) => sideProduct.toolType === activeFilterAsToolType),
              publishedRecently: recentSideProducts?.filter(
                  (sideProduct) =>
                      sideProduct.toolType === activeFilterAsToolType &&
                      sideProduct.toolType === ProjectSideProductToolType.workCard
              ),
              highlighted: highlightedSideProducts?.filter(
                  (sideProduct) => sideProduct.toolType === activeFilterAsToolType
              ),
          }
        : { all: sideProducts };

    return (
        <OverviewPageLayout seoTitle={SIDE_PRODUCTS_PAGE_TITLE}>
            <OverviewAsideFilters
                filterGroups={[getCategoryGroupLabels()]}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
            />
            <OverviewListWrapper>
                <CategoryIntroduction activeFilter={activeFilterAsToolType} />
                {filteredSideProducts.highlighted && filteredSideProducts.highlighted.length > 0 && (
                    <HighlightedSideProductsList
                        heading={`Populaire ${projectSideProductToolTypeLabels[
                            activeFilterAsToolType as ProjectSideProductToolType
                        ].toLowerCase()}`}
                        sideProducts={filteredSideProducts.highlighted}
                    />
                )}
                {filteredSideProducts.publishedRecently && filteredSideProducts.publishedRecently.length > 0 && (
                    <HighlightedSideProductsList
                        heading={`Nieuwe ${projectSideProductToolTypeLabels[
                            activeFilterAsToolType as ProjectSideProductToolType
                        ].toLowerCase()}`}
                        sideProducts={filteredSideProducts.publishedRecently}
                    />
                )}
                {isCasecardSideProductsPage || isToolkitsSideProductsPage ? (
                    // case cards and toolkits have a different kind of overview
                    isCasecardSideProductsPage ? (
                        <CaseCardSideProductOverview caseCardSideProducts={filteredSideProducts.all} />
                    ) : (
                        <ToolkitsSideProductOverview toolkitsSideProducts={filteredSideProducts.all} />
                    )
                ) : (
                    <SideProductOverview
                        emptyStateText={'Geen resultaten in deze categorie...'}
                        style={isGridView ? 'grid' : 'list'}
                    >
                        {isVideoSideProductsPage && (
                            <SideProductOverviewVideoItem
                                title={explainerDirectiveVideo.title}
                                thumbnailUrl={explainerDirectiveVideo.thumbnailUrl}
                                videoUrl={explainerDirectiveVideo.videoUrl}
                                durationInSeconds={explainerDirectiveVideo.duration}
                            />
                        )}

                        {isVideoSideProductsPage && (
                            <SideProductOverviewVideoItem
                                title={decideTogetherVideo.title}
                                thumbnailUrl={decideTogetherVideo.thumbnailUrl}
                                videoUrl={decideTogetherVideo.videoUrl}
                                durationInSeconds={decideTogetherVideo.duration}
                            />
                        )}

                        {filteredSideProducts.all.map((sideProduct) => {
                            if (isSideProductOverviewVideoType(sideProduct)) {
                                return (
                                    <SideProductOverviewVideoItem
                                        key={sideProduct.id}
                                        title={sideProduct.title}
                                        thumbnailUrl={sideProduct.resourceThumbnailUrl}
                                        videoUrl={sideProduct.resourceUri}
                                        durationInSeconds={sideProduct.resourceDurationInSeconds}
                                    />
                                );
                            }

                            if (isSideProductOverviewItemType(sideProduct)) {
                                return (
                                    <SideProductOverviewItem
                                        key={sideProduct.id}
                                        title={sideProduct.title}
                                        url={sideProduct.path || ''}
                                        trackId="download-side-product"
                                    />
                                );
                            }

                            return null;
                        })}
                    </SideProductOverview>
                )}
            </OverviewListWrapper>
        </OverviewPageLayout>
    );
};

export default SideProductOverviewPage;
