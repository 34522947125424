import DefaultHeader from '../../../shared/header/DefaultHeader';
import React, { FC } from 'react';
import Page from '../../shared/page/Page';
import ContactTeaser from '../shared/ContactTeaser/ContactTeaser';
import Footer from '../shared/Footer';
import useFetchPageOnSlugChange from '../../../../hooks/useFetchPageOnSlugChange';
import { createFrontendEntryPath } from '../../../../routing/urlGenerator';
import PageDetailMeta from './components/PageDetailMeta';
import ContentRender from '../../../shared/lexicalRender/ContentRender';

const PageDetail: FC = () => {
    const { page } = useFetchPageOnSlugChange();

    if (!page) {
        return null;
    }

    return (
        <div>
            <PageDetailMeta page={page} />
            <DefaultHeader />
            {!page ? (
                <Page title="Er is iets mis gegaan">
                    <p>
                        Deze pagina kon niet gevonden worden of er is iets fout gegaan bij het laden. Probeer de pagina
                        te verversen of klik op de onderstaande knop om terug te gaan naar de homepage.
                    </p>
                    <a href={createFrontendEntryPath()} className="button button--primary">
                        Naar de homepage
                    </a>
                </Page>
            ) : (
                <Page title={page.title}>
                    <ContentRender content={page.content} contentType={page.contentType} />
                </Page>
            )}
            <ContactTeaser />
            <Footer />
        </div>
    );
};

export default PageDetail;
