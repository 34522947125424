import React from 'react';
import DefaultHeader from '../../../shared/header/DefaultHeader';
import ContactTeaser from '../shared/ContactTeaser/ContactTeaser';
import Footer from '../shared/Footer';
import Loader from '../../../shared/Loader';
import CombinedAmountOfResults from './components/CombinedAmountOfResults';
import SearchResultOverview from './components/SearchResultOverview';
import CollectionPagination from '../../shared/CollectionPagination';
import useSearchUrlParams from '../../shared/searchResults/hooks/useSearchUrlParams';
import useFetchSearchResultsOnFilterChange from '../../shared/searchResults/hooks/useFetchSearchResultsOnFilterChange';
import useUpdateUrlOnFilterChange from '../../shared/searchResults/hooks/useUpdateUrlOnFilterChange';
import useTrackVisitedPageTypeForSearch from '../../shared/chapterDetail/hooks/useTrackVisitedPageTypeForSearch';
import SearchForm, { SearchFormStyle, SearchIconPlacement } from '../../shared/searchForm/SearchForm';
import SearchIcon from '../../../../../../icons/ggz/search.svg';
import NoResults from '../../shared/searchResults/components/NoResults';

const GlobalSearchResults = () => {
    const { query, page } = useSearchUrlParams();
    const { isLoading, toplevelResults, sideProductResults, nestedResults } = useFetchSearchResultsOnFilterChange(
        query,
        page
    );
    const { onFormSubmit, onPageChange } = useUpdateUrlOnFilterChange(query);

    useTrackVisitedPageTypeForSearch(query, page);

    return (
        <>
            <DefaultHeader />
            <div className="section section--secondary">
                <div className="container">
                    <div className="layout layout--align-center">
                        <div className="layout__item layout__item--lg-8">
                            <SearchForm
                                onSubmit={onFormSubmit}
                                query={query}
                                placeholder="Bijv. angst herstel"
                                buttonClassName="button button--primary"
                                buttonText="Zoeken"
                                style={SearchFormStyle.light}
                                searchIconPlacement={SearchIconPlacement.field}
                                hasAutoFocus={query.length === 0}
                                icon={<SearchIcon className="icon--color-primary form__field-icon" />}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <section className="section section--light">
                {isLoading && <Loader />}
                {(nestedResults.hasResults() || toplevelResults.hasResults()) && (
                    <div className="container">
                        <div className="layout layout--align-center">
                            <div className="layout__item layout__item--lg-8">
                                <CombinedAmountOfResults
                                    toplevelResults={toplevelResults}
                                    sideProductResults={sideProductResults}
                                    nestedResults={nestedResults}
                                    currentPage={page}
                                    currentQuery={query}
                                />
                                <SearchResultOverview
                                    toplevelResults={toplevelResults}
                                    sideProductResults={sideProductResults}
                                    nestedResults={nestedResults}
                                    currentQuery={query}
                                />
                                <CollectionPagination
                                    collection={nestedResults}
                                    currentPage={page}
                                    onPageChange={onPageChange}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {nestedResults.hasNoResults() &&
                    sideProductResults.hasNoResults() &&
                    toplevelResults.hasNoResults() &&
                    !isLoading && (
                        <div className="container">
                            <div className="layout layout--align-center">
                                <div className="layout__item layout__item--lg-8">
                                    <NoResults />
                                </div>
                            </div>
                        </div>
                    )}
            </section>
            <ContactTeaser />
            <Footer />
        </>
    );
};

export default GlobalSearchResults;
