export const decideTogetherVideo = {
    title: 'Samen beslissen in de praktijk',
    thumbnailUrl:
        'https://i.vimeocdn.com/video/1389012554-e568cba878dec94f1578777b250521f49bdbe3c86382678e430290b0a05dc09b-d_640',
    videoUrl: 'https://vimeo.com/681304141',
    duration: 206,
};

export const explainerDirectiveVideo = {
    title: 'Wat zijn zorgstandaarden?',
    thumbnailUrl:
        'https://i.vimeocdn.com/video/1827990403-a9dad5a6e8012a5e7b43e2e9644fc146623279c80bc977b9b4e63c842fadd82d-d?mw=640',
    videoUrl: 'https://vimeo.com/930998229',
    duration: 195, // 3:15 in seconds
};
