import React, { Fragment, VFC } from 'react';
import DefaultHeader from '../../../shared/header/DefaultHeader';
import { Helmet } from 'react-helmet';
import ContactTeaser from '../shared/ContactTeaser/ContactTeaser';
import Footer from '../shared/Footer';
import Heading from '../../../shared/heading/Heading';
import { SIDE_PRODUCTS_PAGE_TITLE } from '../../../../helper/seoHelper';
import Card from '../../shared/card/components/Card';
import CardOverview from '../../shared/card/CardOverview';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import useFetchSideProductsCategoryDetails from '../../../../hooks/useFetchSideProductCategoryDetails';
import { createSideProductsOverviewPath } from '../../../../routing/urlGenerator';
import { createTeamTalksPagePath } from '../../../../routing/ggzUrlGenerator';
import teamTalksThumbUrl from '../../../../../images/ggz/team-talks-thumb.jpg';
import evaluationThumbUrl from '../../../../../images/ggz/evaluation-thumb.jpg';
import questionnairesThumbUrl from '../../../../../images/ggz/questionnaires-thumb.png';
import styles from '../forTeachersPage/ForTeachersPage.module.scss';

const SideProductsPage: VFC = () => {
    const { categoryDetails } = useFetchSideProductsCategoryDetails();
    const headingVariant = useIsMobile() ? 'h3' : 'h1';

    if (!categoryDetails || categoryDetails?.length === 0) return null;

    const teamTalksCategoryPosition = 3; // needs to be fourth in the list

    return (
        <>
            <Helmet>
                <title>{SIDE_PRODUCTS_PAGE_TITLE}</title>
            </Helmet>
            <DefaultHeader />
            <section className="section section--light">
                <div className="container">
                    <Heading tag="h1" variant={headingVariant}>
                        Hulpmiddelen voor het gebruik van zorgstandaarden
                    </Heading>
                    <p className={styles.description}>
                        Met deze materialen en video’s leer je de zorgstandaarden snel kennen. En ze bieden hulp bij het
                        toepassen van standaarden in de praktijk.
                    </p>
                    <CardOverview>
                        {categoryDetails.map((item, index) => (
                            <Fragment key={item.id}>
                                {index === teamTalksCategoryPosition && (
                                    <Card
                                        title={'GGZ Team Talks'}
                                        linkText={'Bekijk GGZ Team Talks'}
                                        description={
                                            'Boek hier kosteloos deskundigheidsbevordering met je team en krijg een expert op bezoek.'
                                        }
                                        imageUrl={teamTalksThumbUrl}
                                        path={createTeamTalksPagePath()}
                                    />
                                )}
                                <Card
                                    title={item.title}
                                    linkText={'Bekijk ' + item.shortTitle}
                                    description={item.shortDescription}
                                    imageUrl={item.imageUrl}
                                    path={createSideProductsOverviewPath(item.slug)}
                                />
                            </Fragment>
                        ))}
                        <Card
                            title={'Online Evaluatieondersteuning'}
                            linkText={'Bekijk Evaluatieondersteuning'}
                            description={
                                'Vul samen met de patiënt in voor een concreet evaluatieplan en tips voor het evaluatiegesprek.'
                            }
                            imageUrl={evaluationThumbUrl}
                            path={'https://www.alii.care/evaluatieondersteuning'}
                            external
                        />
                        <Card
                            title={'Vragenlijsten'}
                            linkText={'Bekijk Vragenlijsten'}
                            description={
                                'Vind de vragenlijst passend bij jouw patiënt en het behandeldoel voor Evalueren in de behandeling.'
                            }
                            imageUrl={questionnairesThumbUrl}
                            path={'https://ggzdataportaal.nl/bibliotheek/'}
                            external
                        />
                    </CardOverview>
                </div>
            </section>
            <ContactTeaser />
            <Footer />
        </>
    );
};

export default SideProductsPage;
