import React from 'react';
import { Element } from 'react-scroll';
import PaginatedCollection from '../../../../../model/collection/paginatedCollection';
import PrimarySearchResult from './PrimarySearchResult';
import ProjectSearchItem from '../../../../../model/ProjectSearchItem';
import Heading from '../../../../shared/heading/Heading';

type Props = {
    results: PaginatedCollection<ProjectSearchItem>;
    currentQuery: string;
    containerId: string;
    prefixes: { single: string; plural: string };
};

const PrimarySearchResults = ({ results, currentQuery, containerId, prefixes }: Props) => {
    const totalNumberOfResults = results.totalNumberOfResults;

    if (results.results.length <= 0) {
        return null;
    }

    const prefix = totalNumberOfResults === 1 ? `1 ${prefixes.single}` : `${totalNumberOfResults} ${prefixes.plural}`;

    return (
        <Element name={containerId}>
            <Heading
                tag={'h2'}
                variant={'h3'}
                className="heading u-margin--bottom-md"
            >{`${prefix} voor '${currentQuery}'`}</Heading>
            {results.totalNumberOfResults > 0 && (
                <ul className="list-bordered list-bordered__item--muted u-margin--bottom-xl">
                    {results.map((result) => (
                        <PrimarySearchResult key={result.id} result={result} />
                    ))}
                </ul>
            )}
        </Element>
    );
};

export default PrimarySearchResults;
