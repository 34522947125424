import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import ProjectSearchItem, {
    ProjectSearchItemType,
    ProjectSearchItemTypeLabels,
} from '../../../../../model/ProjectSearchItem';
import { getProjectTypeTitleSingular } from '../../../../../constants/types';
import TruncateMarkup from 'react-truncate-markup';
import { TenantType } from '../../../../../constants/settings';
import Heading from '../../../../shared/heading/Heading';

type Props = {
    result: ProjectSearchItem;
};

const PrimarySearchResults = ({ result }: Props) => {
    const isSideProduct = result.type === ProjectSearchItemType.ProjectSideProduct;
    const label = isSideProduct
        ? ProjectSearchItemTypeLabels[result.type].toUpperCase()
        : getProjectTypeTitleSingular(TenantType.GGZ, result.projectType);

    return (
        <li className="list-bordered__item list-bordered__item--muted">
            <div className="list-bordered__item-content">
                <h6 className="heading heading--type">{label}</h6>
                <Heading tag={'h3'} variant={'h4'} className="heading heading--flat search-result__toplevel-title">
                    <Link to={result.path} className="link link--primary">
                        {parse(result.title)}
                    </Link>
                </Heading>
                {result.description && (
                    <TruncateMarkup lines={3}>
                        <p className="search-result__description">{parse(result.description)}</p>
                    </TruncateMarkup>
                )}
            </div>
        </li>
    );
};

export default PrimarySearchResults;
