import { GgzProjectStatusType, ggzProjectStatusTypeLabels } from '../../../../../model/SkilzProjectStatusType';
import React from 'react';
import { createDevelopmentPhasesPagePath } from '../../../../../routing/skilzUrlGenerator';
import Button, { ButtonStyles, ButtonTypes } from '../../../../shared/buttons/Button';
import useFetchProjectOverviewPreviews from '../../../../../hooks/useFetchProjectOverviewPreviews';
import styles from './ProjectPreviewList.module.scss';
import ArrowRightIcon from '../../../../../../../icons/ggz/arrow-right.svg';

const ProjectPreviewList = () => {
    const { previewItems } = useFetchProjectOverviewPreviews();

    if (!previewItems) return null;

    return (
        <>
            <div className={styles.divider} />
            <ul className={styles.list}>
                {previewItems.map((item) => (
                    <li key={item.id} className={styles.item}>
                        <span className={styles.itemTitle}>{item.short_title ?? item.title}</span>
                        {item.status && (
                            <span className={styles.itemStatus}>
                                {ggzProjectStatusTypeLabels[item.status as GgzProjectStatusType]}
                            </span>
                        )}
                    </li>
                ))}
            </ul>
            <div className={styles.footnote}>
                <p className={styles.additionalDescription}>
                    De zorgstandaarden worden gemaakt door patiënten, hun naasten en professionals. Lees hier meer over
                    de ontwikkelfases
                </p>
                <Button type={ButtonTypes.link} to={createDevelopmentPhasesPagePath()} style={ButtonStyles.tertiary}>
                    <strong>Ontwikkelfases</strong>
                    <ArrowRightIcon className="button__icon" />
                </Button>
            </div>
        </>
    );
};

export default ProjectPreviewList;
