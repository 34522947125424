import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserRegistrationForm from './components/UserRegistrationForm';
import { createRegisterUserAction } from '../../../../actions/userActionFactory';
import { createUserRegistrationConstraintSet } from '../../../../validation/constraint/Factory/UserConstraintSetFactory';
import { createFrontendEntryPath, createLoginPath } from '../../../../routing/urlGenerator';
import { extractFormErrorsFromResponseError } from '../../../../helper/formErrorHelper';
import Logo from '../../../../../images/ggz/ggz-logo.svg';

class UserRegistration extends Component {
    /**
     * @param {Object} props
     */
    constructor(props) {
        super(props);

        this._handleRegisterUserSubmit = this._handleRegisterUserSubmit.bind(this);

        // Refs
        this._userRegistrationFormEl = null;
    }

    /**
     * @param {Object} formData
     * @param {String} reCaptchaToken
     *
     * @private
     */
    _handleRegisterUserSubmit(formData, reCaptchaToken) {
        var action = createRegisterUserAction(
            formData.firstName,
            formData.preposition,
            formData.lastName,
            formData.profession,
            formData.email,
            formData.password,
            formData.agreement,
            reCaptchaToken
        );

        this.props
            .dispatch(action)
            .then(() => this._userRegistrationFormEl.onSubmitSuccess())
            .catch((error) => {
                if (!this._userRegistrationFormEl) {
                    return;
                }

                this._userRegistrationFormEl.onSubmitFailure(extractFormErrorsFromResponseError(error));
            });
    }

    /**
     * @returns {String}
     */
    render() {
        return (
            <div className="wrapper">
                <section className="login-page">
                    <aside className="login-page__switch">
                        <div className="login-page__switch-content">
                            <h3 className="heading">Heb je al een account?</h3>
                            <a href={createLoginPath()} className="link link--action">
                                Gebruik deze om in te loggen
                            </a>
                        </div>
                    </aside>
                    <main className="login-page__content-wrapper">
                        <div className="login-page__content">
                            <header className="login-page__header">
                                <a href={createFrontendEntryPath()}>
                                    <Logo />
                                </a>
                            </header>
                            <h1 className="heading">Registreren</h1>
                            <p className="lead">Maak een account aan zodat u favoriete standaarden kunt bewaren.</p>
                            <UserRegistrationForm
                                onSubmit={this._handleRegisterUserSubmit}
                                constraintSet={createUserRegistrationConstraintSet()}
                                ref={(userRegistrationFormEl) =>
                                    (this._userRegistrationFormEl = userRegistrationFormEl)
                                }
                            />
                        </div>
                    </main>
                </section>
            </div>
        );
    }
}

UserRegistration.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect()(UserRegistration);
