import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { GlobalState } from '../store/types';
import useOnMount from './useOnMount';
import { sortByField } from '../helper/sortingHelper';
import { createFetchSideProductOverviewItemsAction } from '../actions/sideProductFactory';
import { SideProductOverview, SideProductOverviewItemType } from '../model/sideProductOverview/SideProductOverview';

export default function useFetchSideProductOverviewItems(toolType?: string): {
    sideProducts?: SideProductOverviewItemType[];
    recentSideProducts?: SideProductOverviewItemType[];
    highlightedSideProducts?: SideProductOverviewItemType[];
    isLoading: boolean;
} {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch = useDispatch<Promise<void>>();

    useOnMount(() => {
        setIsLoading(true);

        // @ts-ignore don't know how to fix this..
        dispatch(createFetchSideProductOverviewItemsAction(toolType)).finally(() => setIsLoading(false));
    });

    const sideProducts = useSelector<GlobalState, SideProductOverview | null>(({ sideProductOverview }) => {
        if (!sideProductOverview) {
            return null;
        }

        return {
            all: sortByField<SideProductOverviewItemType, string>(sideProductOverview.all, 'title', (value) =>
                value.toLowerCase()
            ),
            publishedRecently: sideProductOverview.publishedRecently,
            highlighted: sideProductOverview.highlighted,
        };
    });

    return {
        sideProducts: sideProducts?.all,
        recentSideProducts: sideProducts?.publishedRecently,
        highlightedSideProducts: sideProducts?.highlighted,
        isLoading,
    };
}
