import ProjectDetail from '../model/projectDetail/ProjectDetail';
import { ProjectSideProductType } from '../model/ProjectSideProduct';
import { ProjectDetailLinkType } from '../model/projectDetail/ProjectDetailLink';
import { isString } from 'lodash';
import { getProjectTypeTitleSingular } from '../constants/types';
import { createProjectDetailPath, createProjectDetailTabPath } from '../routing/urlGenerator';
import { TenantType } from '../constants/settings';

export enum ProjectTabIdentifier {
    standard = 'standard',
    application = 'application',
    indepth = 'indepth',
    accountability = 'accountability',
    patients = 'patients',
}

export const projectTabSlugs: Record<ProjectTabIdentifier, string> = {
    [ProjectTabIdentifier.standard]: '', // is dynamic due to title and type
    [ProjectTabIdentifier.application]: 'hulpmiddelen',
    [ProjectTabIdentifier.indepth]: 'aanvullend',
    [ProjectTabIdentifier.patients]: 'voor-patienten',
    [ProjectTabIdentifier.accountability]: 'status',
};

export const projectTabTitles: Record<ProjectTabIdentifier, string> = {
    [ProjectTabIdentifier.standard]: '', // is dynamic due to type
    [ProjectTabIdentifier.application]: 'Hulpmiddelen',
    [ProjectTabIdentifier.indepth]: 'Aanvullend',
    [ProjectTabIdentifier.patients]: 'Voor patiënten',
    [ProjectTabIdentifier.accountability]: 'Status',
};

export const skilzProjectTabSlugs: Record<ProjectTabIdentifier, string> = {
    ...projectTabSlugs,
    [ProjectTabIdentifier.application]: 'in-de-praktijk',
    [ProjectTabIdentifier.indepth]: 'bijlagen',
    [ProjectTabIdentifier.patients]: 'voor-clienten',
};

export const skilzProjectTabTitles: Record<ProjectTabIdentifier, string> = {
    ...projectTabTitles,
    [ProjectTabIdentifier.application]: 'In de praktijk',
    [ProjectTabIdentifier.indepth]: 'Bijlagen',
    [ProjectTabIdentifier.patients]: 'Voor cliënten',
};

type MixedTypeCollection = Array<ProjectSideProductType | ProjectDetailLinkType>;

type TypesForTabs = Record<
    ProjectTabIdentifier,
    {
        sideProducts: ProjectSideProductType[];
        links: ProjectDetailLinkType[];
    }
>;

type LinkInformation = {
    title: string;
    path: string;
};

const getProjectTabTitle = (tenant: TenantType, identifier: ProjectTabIdentifier): string => {
    return tenant === TenantType.SKILZ ? skilzProjectTabTitles[identifier] : projectTabTitles[identifier];
};

const getProjectTagSlug = (tenant: TenantType, identifier: ProjectTabIdentifier): string => {
    return tenant === TenantType.SKILZ ? skilzProjectTabSlugs[identifier] : projectTabSlugs[identifier];
};

export const resolveLinkInformationForTab = (
    identifier: ProjectTabIdentifier,
    tenant: TenantType,
    projectType: string,
    projectTypeSlug: string,
    projectSlug: string
): LinkInformation => {
    switch (identifier) {
        case ProjectTabIdentifier.standard:
            return {
                title: getProjectTypeTitleSingular(tenant, projectType),
                path: createProjectDetailPath(projectTypeSlug, projectSlug),
            };

        default:
            return {
                title: getProjectTabTitle(tenant, identifier),
                path: createProjectDetailTabPath(projectTypeSlug, projectSlug, getProjectTagSlug(tenant, identifier)),
            };
    }
};

export const linkTypesForTabs: TypesForTabs = {
    [ProjectTabIdentifier.standard]: {
        sideProducts: [],
        links: [],
    },
    [ProjectTabIdentifier.application]: {
        sideProducts: [ProjectSideProductType.apply],
        links: [ProjectDetailLinkType.apply],
    },
    [ProjectTabIdentifier.indepth]: {
        sideProducts: [ProjectSideProductType.default, ProjectSideProductType.transparency],
        links: [
            ProjectDetailLinkType.relatedStandards,
            ProjectDetailLinkType.additionalLinks,
            ProjectDetailLinkType.transparency,
        ],
    },
    [ProjectTabIdentifier.patients]: {
        sideProducts: [ProjectSideProductType.patientTool],
        links: [ProjectDetailLinkType.notProfessionalReference],
    },
    [ProjectTabIdentifier.accountability]: {
        sideProducts: [],
        links: [],
    },
};

const checkTypesInProject = (requestedTypes: MixedTypeCollection, availableProjectTypes: MixedTypeCollection) => {
    if (requestedTypes.length === 0) {
        return false;
    }

    return requestedTypes.some((type) => availableProjectTypes.includes(type));
};

const determineApplicationTabShouldBeDisplayed = (
    projectSideProductTypes: ProjectSideProductType[],
    projectLinkTypes: ProjectDetailLinkType[]
): boolean => {
    return (
        checkTypesInProject(linkTypesForTabs.application.sideProducts, projectSideProductTypes) ||
        checkTypesInProject(linkTypesForTabs.application.links, projectLinkTypes)
    );
};

const determinePatientsTabShouldBeDisplayed = (
    projectSideProductTypes: ProjectSideProductType[],
    projectLinkTypes: ProjectDetailLinkType[],
    project?: ProjectDetail
): boolean => {
    if (project && isString(project.patientIntroduction)) {
        return true;
    }

    return (
        checkTypesInProject(linkTypesForTabs.patients.sideProducts, projectSideProductTypes) ||
        checkTypesInProject(linkTypesForTabs.patients.links, projectLinkTypes)
    );
};

const determineIndepthTabShouldBeDisplayed = (
    projectSideProductTypes: ProjectSideProductType[],
    projectLinkTypes: ProjectDetailLinkType[],
    project?: ProjectDetail
): boolean => {
    if (project && project.articleCount > 0) {
        return true;
    }

    return (
        checkTypesInProject(linkTypesForTabs.indepth.sideProducts, projectSideProductTypes) ||
        checkTypesInProject(linkTypesForTabs.indepth.links, projectLinkTypes)
    );
};

export const resolveVisibleTabs = (
    sideProductTypes: ProjectSideProductType[],
    linkTypes: ProjectDetailLinkType[],
    project?: ProjectDetail
): ProjectTabIdentifier[] => {
    const visibleTabs: ProjectTabIdentifier[] = [ProjectTabIdentifier.standard];

    if (determineApplicationTabShouldBeDisplayed(sideProductTypes, linkTypes)) {
        visibleTabs.push(ProjectTabIdentifier.application);
    }

    if (determineIndepthTabShouldBeDisplayed(sideProductTypes, linkTypes, project)) {
        visibleTabs.push(ProjectTabIdentifier.indepth);
    }

    if (determinePatientsTabShouldBeDisplayed(sideProductTypes, linkTypes, project)) {
        visibleTabs.push(ProjectTabIdentifier.patients);
    }

    visibleTabs.push(ProjectTabIdentifier.accountability);

    return visibleTabs;
};

type TabVisibility = {
    [key in ProjectTabIdentifier]: {
        visible: boolean;
    };
};

export const resolveTabsForProjectDetail = (project: ProjectDetail): TabVisibility => {
    const projectSideProductTypes = project.sideProducts.map((sideProduct) => sideProduct.type);
    const projectLinkTypes = project.links.map((link) => link.type);

    const visibleTabs: TabVisibility = {
        [ProjectTabIdentifier.standard]: {
            visible: false,
        },
        [ProjectTabIdentifier.application]: {
            visible: false,
        },
        [ProjectTabIdentifier.indepth]: {
            visible: false,
        },
        [ProjectTabIdentifier.patients]: {
            visible: false,
        },
        [ProjectTabIdentifier.accountability]: {
            visible: false,
        },
    };

    resolveVisibleTabs(projectSideProductTypes, projectLinkTypes, project).forEach((visibleIdentifier) => {
        visibleTabs[visibleIdentifier].visible = true;
    });

    return visibleTabs;
};
