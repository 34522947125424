import React, { Fragment } from 'react';
import Page from '../../../shared/page/Page';
import DefaultHeader from '../../../../shared/header/DefaultHeader';
import ContactTeaser from '../../shared/ContactTeaser/ContactTeaser';
import Footer from '../../shared/Footer';
import useFetchPageOnSlugChange from '../../../../../hooks/useFetchPageOnSlugChange';
import { EXPLAIN_STANDARD_TYPES_SLUG } from '../../../../../constants/pageSlugs';
import PageToggleItem from '../../../shared/page/PageToggleItem';
import Divider from '../../../../shared/divider/Divider';
import useFetchFaqItems from '../../../../../hooks/useFetchFaqItems';
import ProjectInProgressOverview from '../../../shared/projectOverview/components/ProjectInProgressOverview';
import ProjectPreviewList from '../../projectOverview/component/ProjectPreviewList';
import ProjectOverview from '../../../shared/projectOverview/ProjectOverview';
import ContentRender from '../../../../shared/lexicalRender/ContentRender';

const ExplainStandardTypesPage: React.FC = () => {
    const { page } = useFetchPageOnSlugChange(EXPLAIN_STANDARD_TYPES_SLUG);
    const { faqItems } = useFetchFaqItems();

    if (!page) return null;

    return (
        <>
            <DefaultHeader />
            <Page title={page.title}>
                <ContentRender content={page.content} contentType={page.contentType} />
                {faqItems &&
                    faqItems.map((faqItem) => (
                        <Fragment key={faqItem.id}>
                            <Divider isSecondary muted />
                            <PageToggleItem title={faqItem.title}>
                                <ContentRender content={faqItem.content} contentType={faqItem.contentType} />
                            </PageToggleItem>
                        </Fragment>
                    ))}
                <ProjectOverview>
                    <ProjectInProgressOverview>
                        <ProjectPreviewList />
                    </ProjectInProgressOverview>
                </ProjectOverview>
            </Page>
            <ContactTeaser />
            <Footer />
        </>
    );
};

export default ExplainStandardTypesPage;
