type GroupedType<T> = Record<string, Array<T>>;

export function groupByCallbackValue<T>(items: Array<T>, callback: (a: T) => string): GroupedType<T> {
    const grouped: GroupedType<T> = {};

    items.forEach((item) => {
        const value = callback(item);

        if (typeof grouped[value] === 'undefined') {
            grouped[value] = [];
        }

        grouped[value].push(item);
    });

    return grouped;
}
