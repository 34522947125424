import React, { createElement, Fragment } from 'react';
import {
    LexicalChildrenNode,
    LexicalDefinitionNode,
    LexicalImageNode,
    LexicalLinkNode,
    LexicalListItemNode,
    LexicalListNode,
    LexicalNode,
    LexicalParagraphNode,
    LexicalReferenceNode,
    LexicalRootNode,
    LexicalTableNode,
    LexicalTableRowNode,
    LexicalTableCellNode,
    LexicalTextNode,
    LexicalVimeoNode,
    NodeFormat,
    NodeType,
    LexicalInternalLinkNode,
    LexicalFileLinkNode,
    LexicalHighlightNode,
    LexicalQuoteNode,
    LexicalSideProductLinkNode,
    LexicalHeadingNode,
} from '../model/Node';
import Link from '../../editorContent/components/Link';
import DisplayVideo from '../../editorContent/components/Video';
import Image from '../../editorContent/components/Image';
import ReferenceComponent from '../../../dashboard/lexicalPlugins/addOrCreateReferencePlugin/components/ReferenceComponent';
import Definition from '../../editorContent/components/Definition';
import ProjectBreadcrumb from '../../../../model/ProjectBreadcrumb';
import HighlightBlock from '../../editor/components/HighlightBlock';
import { createSideProductOpenFileApiPath } from '../../../../routing/apiUrlGenerator';

let nextIndex = 0;

const renderChildrenOfNode = (lexicalNode: LexicalChildrenNode): (JSX.Element | null)[] =>
    lexicalNode.children.map((childLexicalNode: LexicalNode) => renderNode(childLexicalNode)).filter(Boolean);

const renderTableNode = (lexicalNode: LexicalTableNode): JSX.Element => (
    <div key={nextIndex} className={'block-list__table'}>
        <table>
            <tbody>{renderChildrenOfNode(lexicalNode)}</tbody>
        </table>
    </div>
);

const renderTableRowNode = (lexicalNode: LexicalTableRowNode): JSX.Element => (
    <tr key={nextIndex}>{renderChildrenOfNode(lexicalNode)}</tr>
);

const renderTableCellNode = (lexicalNode: LexicalTableCellNode): JSX.Element => {
    const { headerState } = lexicalNode;

    if (headerState === 1) {
        return <th key={nextIndex}>{renderChildrenOfNode(lexicalNode)}</th>;
    }

    return <td key={nextIndex}>{renderChildrenOfNode(lexicalNode)}</td>;
};

const renderLinkNode = (lexicalNode: LexicalLinkNode): JSX.Element => {
    const { rel, url } = lexicalNode;

    return (
        <Link key={nextIndex} href={url} external rel={rel}>
            {renderChildrenOfNode(lexicalNode)}
        </Link>
    );
};

const renderInternalLink = (lexicalNode: LexicalInternalLinkNode) => {
    const { path } = lexicalNode;

    return (
        <Link key={nextIndex} href={path}>
            {renderChildrenOfNode(lexicalNode)}
        </Link>
    );
};

const renderFileLink = (lexicalNode: LexicalFileLinkNode) => {
    const { file } = lexicalNode;

    return (
        <Link key={nextIndex} href={file} className="link link--file">
            <i className="icon-docs" />
            {renderChildrenOfNode(lexicalNode)}
        </Link>
    );
};

const renderSideProductLink = (lexicalNode: LexicalSideProductLinkNode) => {
    const { sideProductId } = lexicalNode;

    const url = createSideProductOpenFileApiPath(sideProductId);

    return (
        <Link key={nextIndex} href={url} className="link link--file">
            <i className="icon-docs" />
            {renderChildrenOfNode(lexicalNode)}
        </Link>
    );
};

const renderVimeoNode = (lexicalNode: LexicalVimeoNode): JSX.Element => {
    const { videoID } = lexicalNode;

    return <DisplayVideo key={nextIndex} code={videoID} />;
};

const renderImageNode = (lexicalNode: LexicalImageNode): JSX.Element => {
    const { src } = lexicalNode;

    const filename = src.split('/').pop();
    const originalImagesDirectory = '/uploads/images/';
    const originalFilePath = originalImagesDirectory + filename;

    return <Image key={nextIndex} src={src} originalSrc={originalFilePath} title={filename} />;
};

const renderReference = (lexicalNode: LexicalReferenceNode) => {
    const { referenceId } = lexicalNode;

    return <ReferenceComponent key={nextIndex} referenceId={referenceId} />;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderLineBreakNode = (): JSX.Element => <br key={nextIndex} />;

const renderTabNode = (): JSX.Element => <span>{'\t'}</span>;

const renderListItemNode = (lexicalNode: LexicalListItemNode): JSX.Element => {
    const hasNestedList = lexicalNode.children.some((node) => node.type === NodeType.LIST);

    return (
        <li key={nextIndex} className={hasNestedList ? 'has-nested-list' : ''}>
            {renderChildrenOfNode(lexicalNode)}
        </li>
    );
};

const renderListNode = (lexicalNode: LexicalListNode): JSX.Element => {
    const { listType } = lexicalNode;

    if (listType === 'number') {
        return <ol key={nextIndex}>{renderChildrenOfNode(lexicalNode)}</ol>;
    }

    return <ul key={nextIndex}>{renderChildrenOfNode(lexicalNode)}</ul>;
};

const renderTextNode = (lexicalNode: LexicalTextNode): JSX.Element => {
    const { format, text } = lexicalNode;

    const textFormats = [];

    if (format & NodeFormat.BOLD) {
        textFormats.push('strong');
    }

    if (format & NodeFormat.ITALIC) {
        textFormats.push('em');
    }

    if (format & NodeFormat.STRIKETHROUGH) {
        textFormats.push('s');
    }

    if (format & NodeFormat.UNDERLINE) {
        textFormats.push('u');
    }

    if (format & NodeFormat.CODE) {
        textFormats.push('code');
    }

    if (format & NodeFormat.SUBSCRIPT) {
        textFormats.push('sub');
    }

    if (format & NodeFormat.SUPERSCRIPT) {
        textFormats.push('sup');
    }

    return <span key={nextIndex}>{renderInnerTextNode(textFormats, 0, text)}</span>;
};

const renderInnerTextNode = (
    textFormats: Array<string>,
    index = 0,
    childElement: JSX.Element | string
): JSX.Element | string => {
    const textFormat = textFormats[index] || null;

    if (textFormat) {
        return renderInnerTextNode(textFormats, index + 1, createElement(textFormat, {}, childElement));
    }

    if (!childElement) {
        return <></>;
    }

    return childElement;
};

const renderParagraphNode = (lexicalNode: LexicalParagraphNode): JSX.Element => {
    return <p key={nextIndex}>{renderChildrenOfNode(lexicalNode)}</p>;
};

const renderDefinitionNode = (lexicalNode: LexicalDefinitionNode): JSX.Element => {
    const { definition } = lexicalNode;

    const link = createLinkFromDefinitionNode(lexicalNode);

    return (
        <Definition key={nextIndex} definition={definition} link={link}>
            {renderChildrenOfNode(lexicalNode)}
        </Definition>
    );
};

export const createLinkFromDefinitionNode = (
    lexicalNode: LexicalDefinitionNode
): { href: string; external: boolean } | undefined => {
    const { externalLink, internalLink } = lexicalNode;

    if (internalLink === null && externalLink === '') {
        // don't add a link if the definition node has no link
        return undefined;
    }
    if (internalLink !== null) {
        // add an internal link if the definition node has an internal link
        const internalLinkObject = internalLink as ProjectBreadcrumb;
        return {
            href: internalLinkObject.path,
            external: false,
        };
    }
    if (externalLink !== null) {
        // add an external link if the definition node has an external link
        return {
            href: externalLink,
            external: true,
        };
    }

    return undefined;
};

const renderHighlightNode = (lexicalNode: LexicalHighlightNode): JSX.Element => {
    return <HighlightBlock key={nextIndex}>{renderChildrenOfNode(lexicalNode)}</HighlightBlock>;
};

const renderQuoteNode = (lexicalNode: LexicalQuoteNode): JSX.Element => {
    return <blockquote key={nextIndex}>{renderChildrenOfNode(lexicalNode)}</blockquote>;
};

const renderRootNode = (lexicalNode: LexicalRootNode): JSX.Element | null => {
    nextIndex = 0;
    return <Fragment key={nextIndex}>{renderChildrenOfNode(lexicalNode)}</Fragment>;
};

const renderHeadingNode = (lexicalNode: LexicalHeadingNode): JSX.Element => {
    const { tag } = lexicalNode;

    return createElement(tag, { key: nextIndex }, renderChildrenOfNode(lexicalNode));
};

const renderNode = (lexicalNode: LexicalNode): JSX.Element | null => {
    nextIndex = nextIndex + 1;

    if (NodeType.ROOT === lexicalNode.type) {
        return renderRootNode(lexicalNode as LexicalRootNode);
    }

    if (NodeType.PARAGRAPH === lexicalNode.type) {
        return renderParagraphNode(lexicalNode as LexicalParagraphNode);
    }

    if (NodeType.TEXT === lexicalNode.type) {
        return renderTextNode(lexicalNode as LexicalTextNode);
    }

    if (NodeType.TAB === lexicalNode.type) {
        return renderTabNode();
    }

    if (NodeType.TABLE === lexicalNode.type) {
        return renderTableNode(lexicalNode as LexicalTableNode);
    }

    if (NodeType.TABLE_ROW === lexicalNode.type) {
        return renderTableRowNode(lexicalNode as LexicalTableRowNode);
    }

    if (NodeType.TABLE_CELL === lexicalNode.type) {
        return renderTableCellNode(lexicalNode as LexicalTableCellNode);
    }

    if (NodeType.LIST === lexicalNode.type) {
        return renderListNode(lexicalNode as LexicalListNode);
    }

    if (NodeType.LIST_ITEM === lexicalNode.type) {
        return renderListItemNode(lexicalNode as LexicalListItemNode);
    }

    if (NodeType.LINE_BREAK === lexicalNode.type) {
        return renderLineBreakNode();
    }

    if (NodeType.LINK === lexicalNode.type) {
        return renderLinkNode(lexicalNode as LexicalLinkNode);
    }

    if (NodeType.INTERNAL_LINK === lexicalNode.type) {
        return renderInternalLink(lexicalNode as LexicalInternalLinkNode);
    }

    if (NodeType.FILE_LINK === lexicalNode.type) {
        return renderFileLink(lexicalNode as LexicalFileLinkNode);
    }

    if (NodeType.SIDE_PRODUCT_LINK === lexicalNode.type) {
        return renderSideProductLink(lexicalNode as LexicalSideProductLinkNode);
    }

    if (NodeType.VIMEO === lexicalNode.type) {
        return renderVimeoNode(lexicalNode as LexicalVimeoNode);
    }

    if (NodeType.IMAGE === lexicalNode.type) {
        return renderImageNode(lexicalNode as LexicalImageNode);
    }

    if (NodeType.DEFINITION === lexicalNode.type) {
        return renderDefinitionNode(lexicalNode as LexicalDefinitionNode);
    }

    if (NodeType.REFERENCE === lexicalNode.type) {
        return renderReference(lexicalNode as LexicalReferenceNode);
    }

    if (NodeType.HIGHLIGHT === lexicalNode.type) {
        return renderHighlightNode(lexicalNode as LexicalHighlightNode);
    }

    if (NodeType.QUOTE === lexicalNode.type) {
        return renderQuoteNode(lexicalNode as LexicalQuoteNode);
    }

    if (NodeType.COMMENT === lexicalNode.type) {
        return renderRootNode(lexicalNode as LexicalRootNode);
    }

    if (NodeType.HEADING === lexicalNode.type) {
        return renderHeadingNode(lexicalNode as LexicalHeadingNode);
    }

    console.error('Unsupported Lexical node type "' + lexicalNode.type + '". Skipped render.');

    return null;
};

export { renderNode };
