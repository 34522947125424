import { createFetchFaqOverviewListApiPath } from '../routing/apiUrlGenerator';
import { logError } from '../utility/Logger';
import useOnMount from './useOnMount';
import { Faq } from '../model/Faq';
import { useState } from 'react';
import { get } from '../api/client/apiClient';

export default function useFetchFaqItems() {
    const [faqItems, setFaqItems] = useState<Faq[] | null>(null);

    useOnMount(() => {
        fetchFaqItems()
            .then((data) => setFaqItems(data.results))
            .catch((error) => logError(error));
    });

    return { faqItems };
}

async function fetchFaqItems(): Promise<{ results: Faq[] }> {
    const response = await get(createFetchFaqOverviewListApiPath());

    return await response.data;
}
