import React, { FC } from 'react';
import styles from './CardOverview.module.scss';

type Props = {
    children: React.ReactNode;
};

const CardOverview: FC<Props> = ({ children }) => <div className={styles.cardOverview}>{children}</div>;

export default CardOverview;
