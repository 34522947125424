import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import { createFrontendEntryPath } from './routing/urlGenerator';
import { ToastContainer, toast } from 'react-toastify';
import DashboardApp from './components/dashboard/DashboardApp';
import GgzApp from './components/frontend/ggz/GgzApp';
import { createDashboardEntryPath } from './routing/dashboardUrlGenerator';
import EntryWrapper from './components/shared/entryWrapper/EntryWrapper';
import { Notification, showServerNotifications } from './utility/serverNotificationsHelper';
import { ExperimentContextProvider } from './optimize';

const containerEl = document.getElementById('js-app-container');

if (containerEl) {
    const notifications: Array<Notification> = JSON.parse(containerEl.getAttribute('data-notifications') || '[]');

    ReactDOM.render(
        <EntryWrapper containerEl={containerEl}>
            <ExperimentContextProvider>
                <Switch>
                    <Route
                        path={createDashboardEntryPath()}
                        // @ts-ignore -> don't know why this error is shown..
                        component={DashboardApp}
                    />
                    <Route path={createFrontendEntryPath()} component={GgzApp} />
                </Switch>
                <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
            </ExperimentContextProvider>
        </EntryWrapper>,
        containerEl,
        // show notifications that are passed from server
        () => showServerNotifications(notifications)
    );
}
