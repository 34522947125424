import { StandardOverviewItem } from '../../../../../model/standardOverview/StandardOverviewItem';
import { groupByCallbackValue } from '../../../../../helper/groupingHelper';
import { getProjectTagByLabel, projectTypesFilterCategories } from '../../../../../constants/types';

const sortStandardItemsByTitle = (items: StandardOverviewItem[]): StandardOverviewItem[] => {
    return [...items].sort((first, second) => {
        const firstTitle = first.shortTitle || first.title;
        const secondTitle = second.shortTitle || second.title;

        return firstTitle.localeCompare(secondTitle);
    });
};

const groupItemsByFirstLetter = (items: StandardOverviewItem[]): Record<string, StandardOverviewItem[]> => {
    return groupByCallbackValue<StandardOverviewItem>(items, (item) =>
        (item.shortTitle || item.title)[0].toUpperCase()
    );
};

export const filterItemsByActiveFilter = (
    items: StandardOverviewItem[],
    activeFilter: string | null | undefined
): StandardOverviewItem[] => {
    if (!activeFilter) return items;

    const activeProjectTag = getProjectTagByLabel(activeFilter);

    return items.filter((item: StandardOverviewItem) => {
        if (activeProjectTag) {
            return item.tags.includes(activeProjectTag);
        }

        return projectTypesFilterCategories[item.type] === activeFilter;
    });
};

export const prepareItemsForDisplay = (
    items: StandardOverviewItem[],
    activeFilter: string | null
): Record<string, StandardOverviewItem[]> => {
    const filteredItems = activeFilter ? filterItemsByActiveFilter(items, activeFilter) : items;

    const sortedItems = sortStandardItemsByTitle(filteredItems);

    return groupItemsByFirstLetter(sortedItems);
};
