import React from 'react';
import Page from '../../../shared/page/Page';
import useTrackVisitedPageTypeForPage from '../hooks/useTrackVisitedPageTypeForPage';
import Heading from '../../../../shared/heading/Heading';
import DefaultHeader from '../../../../shared/header/DefaultHeader';
import ContactTeaser from '../../shared/ContactTeaser/ContactTeaser';
import Footer from '../../shared/Footer';

type Props = {
    renderForModal?: boolean;
};

const PrivacyCookiesPage = ({ renderForModal = false }: Props) => {
    const title = 'Privacy- en cookiebeleid';

    useTrackVisitedPageTypeForPage(title);

    const renderContent = () => {
        return (
            <div>
                <p>
                    Akwa GGZ hecht veel waarde aan de bescherming van persoonsgegevens die aan ons zijn toevertrouwd. In
                    privacybeleid leggen we helder en transparant uit hoe wij omgaan met persoonsgegevens.
                </p>
                <p>
                    Akwa GGZ doet er alles aan om de privacy te waarborgen en gaat daarom zorgvuldig om met
                    persoonsgegevens. Wij houden ons in alle gevallen aan de toepasselijke wet- en regelgeving,
                    waaronder de Algemene Verordening Gegevensbescherming. Dit brengt onder andere met zich mee dat wij
                    in ieder geval:
                </p>
                <ol>
                    <li>
                        Persoonsgegevens verwerken in overeenstemming met het doel waarvoor deze zijn verstrekt. Deze
                        doelen en type persoonsgegevens zijn beschreven in dit privacybeleid.
                    </li>
                    <li>
                        Verwerking van de persoonsgegevens beperkt blijft tot alleen die gegevens die minimaal nodig
                        zijn voor de doeleinden waarvoor ze worden verwerkt.
                    </li>
                    <li>
                        Aan betrokkenen uitdrukkelijke toestemming vragen voor de verwerking van persoonsgegevens,
                        behoudens in de gevallen die overeenkomstig het doel zijn, dan wel rechtstreeks voortvloeien uit
                        de wet.
                    </li>
                    <li>
                        Passende technische en/of organisatorische maatregelen hebben genomen, zodat de beveiliging van
                        de persoonsgegevens gewaarborgd is.
                    </li>
                    <li>
                        Geen persoonsgegevens doorgeven aan andere partijen, tenzij dit nodig is voor uitvoering van de
                        doeleinden waarvoor ze zijn verstrekt, dan wel voortvloeit uit de wet.
                    </li>
                    <li>
                        Op de hoogte zijn van de rechten van betrokkenen omtrent de persoonsgegevens en deze
                        respecteren.
                    </li>
                </ol>
                <p>
                    Wij bewaren persoonsgegevens niet langer dan noodzakelijk voor het doel waarvoor deze zijn verstrekt
                    dan wel op grond van de wet is vereist.
                </p>
                <Heading tag="h2" variant="h3">
                    Cookies
                </Heading>
                <p>
                    Deze website gebruikt cookies om bezoekersgedrag te registreren. Een cookie is een bestandje dat
                    naar uw computer wordt gestuurd als u een website bezoekt. Cookies worden gebruikt om informatie op
                    te slaan over bezoekersgedrag. Deze website maakt gebruik van cookies voor Google Analytics, een
                    programma dat webstatistieken bijhoudt. Hiermee kunnen we onder meer bezoekersaantallen zien,
                    bekijken welke pagina’s worden bezocht en nagaan hoe lang een bezoeker op een bepaalde pagina
                    blijft. Met deze informatie kunnen wij onze website verbeteren. Cookies bevatten alleen een nummer,
                    en geen persoonsgegevens. De informatie die wordt verzonden is dus altijd anoniem. De informatie die
                    wij hiermee verzamelen gebruiken we nooit om persoonlijke gegevens te achterhalen van bezoekers van
                    onze website. Ook geven wij deze informatie nooit door aan andere partijen. Google Analytics, het
                    programma waarmee wij de webstatistieken verzamelen, is evenmin gerechtigd om informatie door te
                    spelen aan derden.
                </p>
                <Heading tag="h2" variant="h3">
                    Persoonlijke gegevens
                </Heading>
                <p>
                    Als u een account aanmaakt, vragen wij u om uw voor- en achternaam en e-mailadres. Het opgeven van
                    uw functie is optioneel. Uw gegevens gebruiken we, zodat u voor uw relevante informatie
                    gemakkelijker kunt terugvinden en om, indien nodig, contact met u te kunnen opnemen of om uw
                    relevante informatie te kunnen toesturen. Als uw functie bekend is, kunnen we u nog gerichter
                    informatie toesturen. Akwa GGZ zal uw persoonlijke gegevens nooit voor andere doeleinden gebruiken
                    of doorgeven aan derden, tenzij het nodig is voor de doeleinden waarvoor ze zijn verstrekt. De
                    verstrekte gegevens worden in een beveiligde omgeving opgeslagen. Wij bewaren uw gegevens niet
                    langer dan nodig is dan voor het doeleinde waarvoor we uw gegevens hebben verzameld.
                </p>
                <Heading tag="h2" variant="h3">
                    Gegevens verwijderen
                </Heading>
                <p>
                    U heeft altijd het recht om uw verstrekte persoonsgegevens te verwijderen uit ons bestand. Dat doet
                    u door uw account te verwijderen onder het kopje Mijn profiel/account.
                </p>
                <Heading tag="h2" variant="h3">
                    Externe links
                </Heading>
                <p>
                    Deze website bevat links naar webpagina’s van andere partijen. Akwa GGZ is niet verantwoordelijk
                    voor de inhoud van deze pagina’s of voor het privacybeleid van deze partijen.
                </p>
            </div>
        );
    };

    if (renderForModal === true) {
        return renderContent();
    }

    return (
        <>
            <DefaultHeader />
            <Page title={title}>{renderContent()}</Page>
            <ContactTeaser />
            <Footer />
        </>
    );
};

export default PrivacyCookiesPage;
