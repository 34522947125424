import React from 'react';
import ArticleDetailMeta from './components/ArticleDetailMeta';
import useFetchArticleDetailsOnIdChange from '../../../../hooks/useFetchArticleOnIdChange';
import DefaultHeader from '../../../shared/header/DefaultHeader';
import Footer from '../shared/Footer';
import ContactTeaser from '../shared/ContactTeaser/ContactTeaser';
import ArticleHeader from './components/ArticleHeader';
import RelatedStandard from './components/RelatedStandard';
import ActionLink, { SECONDARY } from '../../../shared/ActionLink';
import { createArticleOverviewPath } from '../../../../routing/urlGenerator';
import ContentRender from '../../../shared/lexicalRender/ContentRender';

const ArticleDetail = () => {
    const { article } = useFetchArticleDetailsOnIdChange();

    return (
        <div>
            <DefaultHeader />
            {!!article && (
                <main className="section section--light">
                    <ArticleDetailMeta article={article} />
                    <article className="container">
                        <ActionLink direction="left" style={SECONDARY} to={createArticleOverviewPath()}>
                            Actueel
                        </ActionLink>
                        <ArticleHeader article={article} />
                        <div className="layout">
                            <div className="layout__item layout__item--lg-8">
                                <ContentRender content={article.description} contentType={article.descriptionType} />
                            </div>
                            <aside className="layout__item layout__item--lg-4">
                                {article.standard && <RelatedStandard standard={article.standard} />}
                            </aside>
                        </div>
                    </article>
                </main>
            )}
            <ContactTeaser />
            <Footer />
        </div>
    );
};

export default ArticleDetail;
