import { getBooleanFromCookieStorage, writeBooleanToCookieStorage } from '../../../../utility/cookieStorageHelper';
import { writeToDataLayer } from '../utilities/dataLayerUtilities';

const COOKIE_NAME = 'cookie_consent_acquired';
const COOKIE_EXPIRATION_LENGTH = 365; // days

export default function useConfirmDeclineCookie(doHideModal: Function) {
    const cookieValue = getBooleanFromCookieStorage(COOKIE_NAME);

    const onCookieConfirm = () => {
        doHideModal();

        writeBooleanToCookieStorage(COOKIE_NAME, true, COOKIE_EXPIRATION_LENGTH);

        writeToDataLayer({
            event: 'cookie-consent-allow',
            eventCategory: 'cookie-consent',
            eventAction: 'allow',
        });
    };

    const onCookieDecline = () => {
        doHideModal();

        writeBooleanToCookieStorage(COOKIE_NAME, false, COOKIE_EXPIRATION_LENGTH);
    };

    return { cookieValue, onCookieConfirm, onCookieDecline };
}
