import { ProjectSideProductToolType, projectSideProductCategoryGroupLabels } from '../model/ProjectSideProduct';

export type CategoryGroup = {
    title: string;
    filters: string[];
};

export function getCategoryGroupLabels(): CategoryGroup {
    const filters: string[] = Object.keys(ProjectSideProductToolType).map(
        (toolType) => projectSideProductCategoryGroupLabels[toolType as ProjectSideProductToolType]
    );

    return {
        title: 'Categorie',
        filters: filters.filter( Boolean ),
    };
}
