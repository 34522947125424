import { Helmet } from 'react-helmet';
import { FOR_TEACHERS_PAGE_TITLE } from '../../../../helper/seoHelper';
import useFetchSideProductsCategoryDetails from '../../../../hooks/useFetchSideProductCategoryDetails';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import DefaultHeader from '../../../shared/header/DefaultHeader';
import Heading from '../../../shared/heading/Heading';
import CardOverview from '../../shared/card/CardOverview';
import Card from '../../shared/card/components/Card';
import { createGuestLecturesPagePath } from '../../../../routing/ggzUrlGenerator';
import { createSideProductsOverviewPath } from '../../../../routing/urlGenerator';
import ContactTeaser from '../shared/ContactTeaser/ContactTeaser';
import Footer from '../shared/Footer';
import React from 'react';
import styles from './ForTeachersPage.module.scss';
import guestlecturesThumbUrl from '../../../../../images/ggz/gastcollege-thumb.jpg';

const ForTeachersPage = () => {
    const { categoryDetails } = useFetchSideProductsCategoryDetails();
    const headingVariant = useIsMobile() ? 'h3' : 'h1';

    if (!categoryDetails || categoryDetails?.length === 0) return null;

    const categoriesToInclude = ['workCard', 'patientToolkit', 'caseCard', 'video'];

    return (
        <>
            <Helmet>
                <title>{FOR_TEACHERS_PAGE_TITLE}</title>
            </Helmet>
            <DefaultHeader />
            <section className="section section--light">
                <div className="container">
                    <Heading tag="h1" variant={headingVariant}>
                        Voor docenten
                    </Heading>
                    <p className={styles.description}>
                        Geef je les aan studenten, leerlingen of professionals in opleiding? De hulpmiddelen van Akwa
                        GGZ geven uitleg over de zorgstandaarden. Voor gebruik tijdens colleges, lessen of ander
                        onderwijs. Zo maken zorgverleners in spe kennis met zorgstandaarden.
                    </p>
                    <CardOverview>
                        <Card
                            title={'Gastcolleges'}
                            linkText={'Meer over Gastcolleges'}
                            description={
                                'Boek hier kosteloos een gastcollege over het wat, waarom en hoe van zorgstandaarden. Voor studenten en voor zorgverleners in spe, verzorgd door Akwa GGZ.'
                            }
                            imageUrl={guestlecturesThumbUrl}
                            path={createGuestLecturesPagePath()}
                        />
                        {categoryDetails.map(
                            (item) =>
                                categoriesToInclude.includes(item.identifier) && (
                                    <Card
                                        key={item.id}
                                        title={item.title}
                                        linkText={'Bekijk ' + item.shortTitle}
                                        description={item.shortDescription}
                                        imageUrl={item.imageUrl}
                                        path={createSideProductsOverviewPath(item.slug)}
                                    />
                                )
                        )}
                    </CardOverview>
                </div>
            </section>
            <ContactTeaser />
            <Footer />
        </>
    );
};

export default ForTeachersPage;
