import React from 'react';
import useShowHide from '../../../../../hooks/useShowHide';
import { useIsMobile } from '../../../../../hooks/useIsMobile';
import Button from '../../../../shared/buttons/Button';
import styles from '../OverviewPageLayout.module.scss';
import ArrowDownIcon from '../../../../../../../icons/ggz/chevron-down.svg';
import ArrowUpIcon from '../../../../../../../icons/ggz/chevron-up.svg';

type Props = {
    children: React.ReactNode;
};

const OverviewFilterToggle: React.VFC<Props> = ({ children }) => {
    const { visible, toggle } = useShowHide();
    const isMobile = useIsMobile();

    // filters do not need to be toggleable on larger devices
    if (!isMobile) {
        return <>{children}</>;
    }

    return (
        <div className={styles.filterToggleContainer}>
            <Button isFull isCleared additionalClassName={styles.toggleButton} onClick={() => toggle()}>
                Filters {visible ? <ArrowUpIcon className={styles.icon} /> : <ArrowDownIcon className={styles.icon} />}
            </Button>
            {visible && <div className={styles.filterItemsWrapper}>{children}</div>}
        </div>
    );
};

export default OverviewFilterToggle;
