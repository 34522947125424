import React, { VFC } from 'react';
import Heading from '../../../shared/heading/Heading';
import Button, { ButtonStyles, ButtonTypes } from '../../../shared/buttons/Button';
import styles from './InformationBlock.module.scss';
import ArrowRightIcon from '../../../../../../icons/ggz/arrow-right.svg';
import createClassName from 'classnames';

type StyleOptions = 'primary' | 'secondary';

type Props = {
    heading: string;
    description: string;
    buttonText: string;
    linkPath: string;
    isDeflated?: boolean;
    isLarge?: boolean;
    style?: StyleOptions;
    children?: React.ReactNode,
};

const InformationBlock: VFC<Props> = ({
    heading,
    description,
    buttonText,
    linkPath,
    isDeflated = false,
    isLarge = false,
    style = 'primary',
    children,
}) => {
    const sectionClassNames = createClassName('', {
        section: !isDeflated,
        'section--light': style === 'primary' && !isDeflated,
        'section--secondary': style === 'secondary',
    });

    const informationBlockWrapperClassNames = createClassName(styles.informationBlockWrapper, {
        [styles.isPrimary]: style === 'primary',
        [styles.isSecondary]: style === 'secondary',
        [styles.isLarge]: isLarge,
    });

    return (
        <section className={sectionClassNames}>
            <div className="container">
                <div className={informationBlockWrapperClassNames}>
                    <div className={styles.informationBlockContent}>
                        <Heading tag="h2" variant="h3">
                            {heading}
                        </Heading>
                        {children}
                        <p>{description}</p>
                        <Button style={ButtonStyles.tertiary} type={ButtonTypes.link} to={linkPath}>
                            {buttonText}
                            <ArrowRightIcon className="button__icon" />
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default InformationBlock;
