import Heading from '../../../../shared/heading/Heading';
import { ProjectSideProductToolType, projectSideProductToolTypeLabels } from '../../../../../model/ProjectSideProduct';
import React, { VFC } from 'react';
import useFetchSideProductsCategoryDetails from '../../../../../hooks/useFetchSideProductCategoryDetails';
import styles from '../SideProductOverviewPage.module.scss';
import ContentRender from '../../../../shared/lexicalRender/ContentRender';

type Props = {
    title?: string;
    activeFilter: string | null | undefined;
};

const CategoryIntroduction: VFC<Props> = ({ title, activeFilter }) => {
    const { categoryDetails } = useFetchSideProductsCategoryDetails();

    if (!activeFilter || !categoryDetails || categoryDetails?.length === 0) return null;

    const activeCategory = categoryDetails.find(
        (categoryDetailsItem) => categoryDetailsItem.identifier === activeFilter
    );

    return (
        <div>
            <Heading tag="h1" variant="h2">
                {title ?? projectSideProductToolTypeLabels[activeFilter as ProjectSideProductToolType]}
            </Heading>
            {activeCategory && (
                <div className={styles.content}>
                    <ContentRender content={activeCategory.description} contentType={activeCategory.descriptionType} />
                </div>
            )}
        </div>
    );
};

export default CategoryIntroduction;
