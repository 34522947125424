import React, { useRef, useState } from 'react';
import OverviewPageLayout from '../overviewPageLayout/OverviewPageLayout';
import OverviewAsideFilters from '../overviewPageLayout/components/OverviewAsideFilters';
import StandardList from './components/StandardList';
import OverviewListWrapper from '../overviewPageLayout/components/OverviewListWrapper';
import { STANDARDS_PAGE_TITLE } from '../../../../helper/seoHelper';
import { standardFilterGroups } from './utility/constants';
import styles from './StandardOverviewPage.module.scss';
import AlphabeticalNavigation from './components/AlphabeticalNavigation';
import useFetchStandardOverviewItems from '../../../../hooks/useFetchStandardOverviewItems';
import Loader from '../../../shared/Loader';
import { prepareItemsForDisplay } from './utility/sortingAndGroupingUtilities';
import StandardOverviewSearchForm from './components/StandardOverviewSearchForm';
import { StandardOverviewItem } from '../../../../model/standardOverview/StandardOverviewItem';
import { debounce } from '../../../../utility/debounce';

const StandardOverviewPage = () => {
    const { items } = useFetchStandardOverviewItems();
    const [activeFilter, setActiveFilter] = useState<string | null>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const [filteredItems, setFilteredItems] = useState<Record<string, StandardOverviewItem[]> | null>(null);

    if (!items) {
        return <Loader />;
    }

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            return setFilteredItems(null);
        }

        const filteredByTitle = items.filter((item) => {
            return item.title.toLowerCase().includes(event.target.value.toLowerCase());
        });

        setFilteredItems(prepareItemsForDisplay(filteredByTitle, activeFilter));
    };

    const debouncedSearch = debounce(handleSearch, 300);

    const updateFilter = (filter: string | null) => {
        setActiveFilter(filter);
        setFilteredItems(null);

        if (searchRef.current) {
            searchRef.current.value = '';
        }

        window.scroll(0, 0);
    };

    const itemsGroupedByFirstLetter = prepareItemsForDisplay(items, activeFilter);

    return (
        <OverviewPageLayout seoTitle={STANDARDS_PAGE_TITLE}>
            <div className={styles.stickySideMenu}>
                <OverviewAsideFilters
                    filterGroups={standardFilterGroups}
                    activeFilter={activeFilter}
                    setActiveFilter={updateFilter}
                    clearFiltersTitle="Zorgstandaarden A-Z"
                >
                    <AlphabeticalNavigation
                        standardListItems={filteredItems ?? itemsGroupedByFirstLetter}
                        hasActiveFilter={!!activeFilter}
                    />
                </OverviewAsideFilters>
            </div>
            <OverviewListWrapper>
                <StandardOverviewSearchForm searchRef={searchRef} onChange={debouncedSearch} />
                <StandardList items={filteredItems ?? itemsGroupedByFirstLetter} />
            </OverviewListWrapper>
        </OverviewPageLayout>
    );
};

export default StandardOverviewPage;
