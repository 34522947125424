import React, { useEffect } from 'react';
import CookieConsentPopup from './components/CookieConsentPopup';
import useShowHideModal from '../../../hooks/useShowHideModal';
import useConfirmDeclineCookie from './hooks/useConfirmDeclineCookie';
import createClassName from 'classnames';

const GDPRTreshold = () => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal();

    const { cookieValue, onCookieConfirm, onCookieDecline } = useConfirmDeclineCookie(doHideModal);

    useEffect(() => {
        if (typeof cookieValue !== 'boolean') {
            doShowModal();
        }
    }, [cookieValue, doShowModal]);

    const className = createClassName('cookie-consent-popup', {
        'cookie-consent-popup-is-active': showModal,
    });

    if (showModal) {
        return <CookieConsentPopup className={className} onConfirm={onCookieConfirm} onDecline={onCookieDecline} />;
    }

    return null;
};

export default GDPRTreshold;
