import * as React from 'react';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '../../../../icons/ggz/arrow-right.svg';
import ArrowLeftIcon from '../../../../icons/ggz/arrow-left.svg';
import createClassName from 'classnames';

export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const DEFAULT = 'default';

type Props = {
    to: string;
    style?: 'primary' | 'secondary' | 'default';
    additionalClassNames?: string;
    direction?: 'left' | 'right';
    arrowPosition?: 'left' | 'right';
    undecorated?: boolean;
    external?: boolean;
    children: React.ReactNode;
};

const ActionLink = ({
    to,
    style = DEFAULT,
    additionalClassNames,
    direction = 'right',
    arrowPosition = 'left',
    undecorated = false,
    external = false,
    children,
}: Props) => {
    const linkClassName = createClassName(
        'link link--action',
        {
            'link--primary': style === PRIMARY,
            'link--secondary': style === SECONDARY,
            'link--default': style === DEFAULT,
            'link--undecorated': undecorated,
        },
        additionalClassNames
    );

    const iconClassNames = createClassName('icon', {
        'icon--right': arrowPosition === 'right',
        'icon--left': arrowPosition === 'left',
    });

    const arrowIcon =
        direction === 'right' ? (
            <ArrowRightIcon className={iconClassNames} />
        ) : (
            <ArrowLeftIcon className={iconClassNames} />
        );

    return external ? (
        <a href={to} className={linkClassName} target={'_blank'}>
            {arrowPosition === 'left' && arrowIcon}
            <span>{children}</span>
            {arrowPosition === 'right' && arrowIcon}
        </a>
    ) : (
        <Link to={to} className={linkClassName}>
            {arrowPosition === 'left' && arrowIcon}
            <span>{children}</span>
            {arrowPosition === 'right' && arrowIcon}
        </Link>
    );
};

export default ActionLink;
