import React from 'react';
import DefaultHeader from '../../../shared/header/DefaultHeader';
import Heading from '../../../shared/heading/Heading';
import useFetchArticlesOnPageChange from '../../../../hooks/useFetchArticlesOnPageChange';
import CollectionPagination from '../../shared/CollectionPagination';
import Loader from '../../../shared/Loader';
import ArticleList from '../../shared/articleList/ArticleList';
import ContactTeaser from '../shared/ContactTeaser/ContactTeaser';
import Footer from '../shared/Footer';

const ArticleOverview = () => {
    const { articles, onPageChange } = useFetchArticlesOnPageChange();

    return (
        <>
            <DefaultHeader />
            <main className="section section--light">
                <div className="container">
                    <div className="layout layout--align-center">
                        <div className="layout__item layout__item--xl-10">
                            <Heading tag="h1">Actueel</Heading>
                            {articles && articles.results.length > 0 ? (
                                <>
                                    <ArticleList.List>
                                        {articles.results.map((article) => (
                                            <ArticleList.ListItem
                                                key={article.id}
                                                article={article}
                                                showImage={false}
                                            />
                                        ))}
                                    </ArticleList.List>
                                    <CollectionPagination
                                        collection={articles}
                                        currentPage={articles.currentPage}
                                        onPageChange={onPageChange}
                                    />
                                </>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
            </main>
            <ContactTeaser />
            <Footer />
        </>
    );
};

export default ArticleOverview;
