import React from 'react';
import useFetchSpotlightArticles from '../../../../hooks/useFetchSpotlightArticles';
import Heading from '../../../shared/heading/Heading';
import { createArticleOverviewPath } from '../../../../routing/urlGenerator';
import ArticleList from '../../shared/articleList/ArticleList';
import Button, { ButtonStyles, ButtonTypes } from '../../../shared/buttons/Button';
import ArrowRightIcon from '../../../../../../icons/ggz/arrow-right.svg';

const NO_OF_SPOTLIGHT_ITEMS = 2;

const LatestArticles = () => {
    const { articles } = useFetchSpotlightArticles(NO_OF_SPOTLIGHT_ITEMS);

    if (!articles) {
        return null;
    }

    return (
        <section className="section section--light">
            <div className="container">
                <Heading tag="h2" variant="h5" uppercase>
                    Actueel
                </Heading>
                <ArticleList.List isCompact>
                    {articles.map((article) => (
                        <ArticleList.ListItem key={article.id} article={article} showImage={true} isSmall />
                    ))}
                </ArticleList.List>
                <Button type={ButtonTypes.link} style={ButtonStyles.tertiary} to={createArticleOverviewPath()}>
                    Meer artikelen <ArrowRightIcon className="button__icon" />
                </Button>
            </div>
        </section>
    );
};

export default LatestArticles;
