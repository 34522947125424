import React, { VFC } from 'react';
import DefaultHeader from '../../../shared/header/DefaultHeader';
import Spotlight from './components/Spotlight';
import Footer from '../shared/Footer';
import ContactTeaser from '../shared/ContactTeaser/ContactTeaser';
import useTrackVisitedPageTypeOnMount from '../../../../hooks/useTrackVisitedPageTypeOnChange';
import { pageType } from '../../../../constants/pageTypes';
import useScrollToTopOnMount from '../../../../hooks/useScrollToTopOnMount';
import LatestArticles from '../latestArticles/LatestArticles';
import { Helmet } from 'react-helmet';
import { createPagePath } from '../../../../routing/urlGenerator';
import DecisionAidApp from '../decisionAidApp/DecisionAidApp';
import InformationBlock from '../informationBlock/InformationBlock';
import { EXPLAIN_STANDARD_TYPES_SLUG, VISION_ON_CARE_PAGE_SLUG } from '../../../../constants/pageSlugs';
import MobileSpotlightImage from './components/MobileSpotlightImage';
import { getDefaultSeoTitle } from '../../../../helper/seoHelper';

const Home: VFC = () => {
    useTrackVisitedPageTypeOnMount(pageType.home);
    useScrollToTopOnMount();

    return (
        <div>
            <Helmet>
                <title>{getDefaultSeoTitle()}</title>
            </Helmet>
            <DefaultHeader />
            <MobileSpotlightImage />
            <Spotlight />
            <InformationBlock
                isLarge
                isDeflated
                heading="Wat zijn zorgstandaarden?"
                description="In de zorgstandaarden vind je algemene aanbevelingen voor het voorkomen, herkennen, diagnosticeren en behandelen van psychische klachten en stoornissen. Ze bieden een gedegen basis voor gezamenlijke besluitvorming en goede zorg."
                buttonText="Meer over zorgstandaarden "
                linkPath={createPagePath(EXPLAIN_STANDARD_TYPES_SLUG)}
            >
                <p>
                    <div style={{'padding': '56.25% 0 0 0', 'position': 'relative'}}>
                        <iframe src="https://player.vimeo.com/video/930998229?h=a1fb08de40&title=0&byline=0&portrait=0"
                                style={{'position': 'absolute', 'top': 0, 'left': 0, 'width': '100%', 'height': '100%'}}
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </p>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </InformationBlock>
            <LatestArticles/>
            <InformationBlock
                style="secondary"
                isDeflated
                heading="Visie op zorg"
                description="In de zorg ligt de focus op gezondheid en herstel. De mogelijkheden en krachten van de mens staan centraal. In de ggz zijn samen beslissen, zelfmanagement en eigen regie daarom onmisbare elementen."
                buttonText="Lees de visie op zorg "
                linkPath={createPagePath(VISION_ON_CARE_PAGE_SLUG)}
            />
            <DecisionAidApp />
            <ContactTeaser />
            <Footer />
        </div>
    );
};

export default Home;
