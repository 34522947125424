import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResetPasswordForm from '../../shared/forgotPassword/ResetPasswordForm';
import { connect } from 'react-redux';
import { createResetPasswordAction } from '../../../../actions/userActionFactory';
import { extractPath } from '../../../../helper/objectPathHelper';
import { STATUS_CODE_NOT_FOUND } from '../../../../constants/statusCode';
import { toast } from 'react-toastify';
import HeaderLogo from '../../../shared/header/components/HeaderLogo';
import { withRouter } from 'react-router-dom';
import { createDashboardEntryPath } from '../../../../routing/dashboardUrlGenerator';
import { createForgotPasswordPath } from '../../../../routing/urlGenerator';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this._onSubmit = this._onSubmit.bind(this);
        this._resetPasswordFormEl = null;
    }

    /**
     * @param {String} newPassword
     *
     * @private
     */
    _onSubmit(newPassword) {
        var { dispatch, history } = this.props,
            action = createResetPasswordAction(this._token, newPassword);

        dispatch(action)
            .then(() => {
                // use window.location.href to force a hard redirect instead of client side redirect, to make sure that
                // the new authentication cookie is set, and the 'data-loggedin' flag is passed to the application component
                window.location.href = createDashboardEntryPath();
            })
            .catch((error) => {
                if (extractPath('response.status', error) === STATUS_CODE_NOT_FOUND) {
                    toast.error('De door u gebruikte link is niet meer geldig. Doorloop het proces opnieuw!');

                    history.push(createForgotPasswordPath());
                }
            });
    }

    /**
     * @returns {String}
     */
    render() {
        this._token = this.props.location.pathname.split('/').pop();

        return (
            <div className="wrapper wrapper--bg-secondary">
                <div className="container">
                    <div className="layout layout--align-center">
                        <div className="layout__item layout__item--xs-12 layout__item--md-10 layout__item--lg-7 layout__item--xl-6">
                            <header className="u-margin--top-lg u-margin--bottom-lg">
                                <HeaderLogo />
                            </header>
                            <main className="island island--large island--light">
                                <h3 className="heading heading--flat">Wachtwoord resetten</h3>
                                <ResetPasswordForm
                                    token={this._token}
                                    onSubmit={this._onSubmit}
                                    ref={(resetPasswordFormEl) => (this._resetPasswordFormEl = resetPasswordFormEl)}
                                />
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(connect()(ResetPassword));
