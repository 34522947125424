import React, { ReactElement } from 'react';
import Tooltip from '../../../../shared/Tooltip';
import { StandardOverviewItem } from '../../../../../model/standardOverview/StandardOverviewItem';
import StandardTabsTooltipContent from './StandardTabsTooltipContent';

type Props = {
    item: StandardOverviewItem;
    children: ReactElement;
};

const StandardTabsTooltip: React.FC<Props> = ({ item, children }) => {
    const content = <StandardTabsTooltipContent item={item} />;

    return (
        <Tooltip
            content={content}
            placement="bottom-start"
            interactive={true}
            theme="light menu"
            touch={false}
            maxWidth="80vw"
            delay={[250, null]}
            offset={[25, 0]}
        >
            {children}
        </Tooltip>
    );
};

export default StandardTabsTooltip;
