import { Helmet } from 'react-helmet';
import React from 'react';
import { composeSeoTitle } from '../../../../../helper/seoHelper';
import { Page } from '../../../../../model/Page';

type Props = {
    page: Page;
};

const PageDetailMeta = ({ page }: Props) => {
    const seoTitle = composeSeoTitle(null, page.title, 'Artikelen');

    return (
        <Helmet>
            <title>{seoTitle}</title>
            <meta title={seoTitle} />
            <meta name="description" content={page.seoDescription} />
            <meta property="og:type" content="page" />
            <meta prefix="og: http://ogp.me/ns#" property="og:site_name" content="GGZ Standaarden" />
            <meta prefix="og: http://ogp.me/ns#" property="og:locale" content="nl_NL" />
            <meta prefix="og: http://ogp.me/ns#" property="og:title" content={seoTitle} />
            <meta prefix="og: http://ogp.me/ns#" property="og:description" content={page.seoDescription} />
            <meta prefix="og: http://ogp.me/ns#" property="og:url" content={window.location.toString()} />
            <meta prefix="og: http://ogp.me/ns#" property="og:type" content="page" />
            <meta property="og:image" content={page.seoImageUrl} />
        </Helmet>
    );
};

export default PageDetailMeta;
