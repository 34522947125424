import React from 'react';
import Page from '../../../shared/page/Page';
import useTrackVisitedPageTypeForPage from '../hooks/useTrackVisitedPageTypeForPage';
import DefaultHeader from '../../../../shared/header/DefaultHeader';
import ContactTeaser from '../../shared/ContactTeaser/ContactTeaser';
import Footer from '../../shared/Footer';

const ContactPage = () => {
    const title = 'Contact';

    useTrackVisitedPageTypeForPage(title);

    return (
        <>
            <DefaultHeader />
            <Page title={title}>
                <p>
                    Deze kennisbank is ontwikkeld door en in beheer van Akwa GGZ.
                    <br />
                    Voor meer informatie over deze kennisbank en vragen kunt u contact opnemen met Akwa GGZ via:
                </p>
                <p>
                    Tel: <a href="tel:0303079270">030-307 92 70</a>
                    <br />
                    E-mail: <a href="mailto:ggzstandaarden@akwaggz.nl">ggzstandaarden@akwaggz.nl</a>
                </p>
                <p>
                    Zie ook{' '}
                    <a href="https://www.akwaggz.nl" target="_blank" rel="noopener noreferrer">
                        www.akwaggz.nl
                    </a>
                </p>
            </Page>
            <ContactTeaser />
            <Footer />
        </>
    );
};

export default ContactPage;
