import styles from '../ProjectOverview.module.scss';
import Heading from '../../../../shared/heading/Heading';
import React, { ReactNode } from 'react';
import useCurrentTenant from '../../../../../hooks/useCurrentTenant';

type Props = {
    children: ReactNode;
};

const ProjectInProgressOverview = ({ children }: Props) => {
    const { isSKILZ, isGGZ } = useCurrentTenant();
    const style = isGGZ ? 'primary' : 'secondary';

    return (
        <>
            {isSKILZ && <div className={styles.sectionSpacer} />}
            <Heading tag="h3" variant="h2" align="center" style={style}>
                {isSKILZ && 'In ontwikkeling'}
                {isGGZ && 'In ontwikkeling/herziening'}
            </Heading>
            <p className={styles.description}>
                {isSKILZ &&
                    'Bekijk hieronder welke richtlijnen op dit moment ontwikkeld worden en in welke fase van ontwikkeling deze zich bevinden.'}
                {isGGZ &&
                    'Bekijk hieronder welke standaarden op dit moment ontwikkeld worden en in welke fase van ontwikkeling deze zich bevinden.'}
            </p>
            {children}
        </>
    );
};

export default ProjectInProgressOverview;
