import React, { VFC } from 'react';
import styles from '../OverviewPageLayout.module.scss';
import Button from '../../../../shared/buttons/Button';
import createClassName from 'classnames';

export type Props = {
    children: React.ReactNode;
    isActive: boolean;
    onClick?: () => void;
};

const OverviewFilterItem: VFC<Props> = ({ children, isActive, onClick }) => {
    const classNames = createClassName(styles.filterItem, {
        [styles.isActive]: isActive,
    });

    return (
        <li className={styles.filterListItem}>
            <Button
                additionalClassName={classNames}
                isCleared
                onClick={onClick}
                data-track-id="overview_page_filter_click"
            >
                {children}
            </Button>
        </li>
    );
};

export default OverviewFilterItem;
