import React from 'react';
import PaginatedCollection from '../../../../../model/collection/paginatedCollection';
import { Element } from 'react-scroll';
import { getProjectTypeTitleSingular } from '../../../../../constants/types';
import ProjectSearchItem from '../../../../../model/ProjectSearchItem';
import SearchResult from './SearchResult';
import { TenantType } from '../../../../../constants/settings';
import { NESTED_SEARCHRESULTS_CONTAINER_ID } from '../../../../../constants/searchResults';
import Heading from '../../../../shared/heading/Heading';

type Props = {
    nestedResults: PaginatedCollection<ProjectSearchItem>;
    currentQuery: string;
};

const NestedSearchResults = ({ nestedResults, currentQuery }: Props) => {
    const totalNumberOfResults = nestedResults.totalNumberOfResults;

    const prefix = totalNumberOfResults === 1 ? '1 resultaat' : `${totalNumberOfResults} resultaten`;

    return (
        <Element name={NESTED_SEARCHRESULTS_CONTAINER_ID}>
            <Heading
                tag={'h2'}
                variant={'h3'}
                className="heading u-margin--bottom-md"
            >{`${prefix} voor '${currentQuery}'`}</Heading>
            {nestedResults.map((result) => (
                <SearchResult
                    key={result.id}
                    result={result}
                    breadcrumbItems={[
                        getProjectTypeTitleSingular(TenantType.GGZ, result.projectType),
                        ...result.breadcrumbTitles,
                    ]}
                />
            ))}
        </Element>
    );
};

export default NestedSearchResults;
