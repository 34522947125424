import * as React from 'react';
import { ReactElement } from 'react';
import classnames from '../StandardOverviewPage.module.scss';
import { Element } from 'react-scroll';

type Props = {
    firstLetter: string;
    name: string;
    children: ReactElement[];
};

const StandardListGroup = ({ firstLetter, name, children }: Props) => (
    <Element name={name}>
        <div className={classnames.group}>
            <span className={classnames.firstLetter} aria-hidden>
                {firstLetter}
            </span>
            <div className={classnames.groupItems}>
                {React.Children.map(children, (child) => (
                    <div className={classnames.listItem} key={child.key}>
                        {child}
                    </div>
                ))}
            </div>
        </div>
    </Element>
);

export default StandardListGroup;
