import React from 'react';
import PropTypes from 'prop-types';
import { createSearchPath } from '../routing/urlGenerator';
import { withRouter } from 'react-router-dom';

/**
 * @param {SearchForm} SearchForm
 *
 * @returns {SearchDispatcher}
 */
export default function createDispatchSearchFormOnSubmitComponent(SearchForm) {
    class SearchDispatcher extends React.Component {
        /**
         * @param {Object} props
         */
        constructor(props) {
            super(props);

            this._onFormSubmit = this._onFormSubmit.bind(this);
        }

        /**
         * @param {Object} formData
         *
         * @private
         */
        _onFormSubmit(formData) {
            var newPath = createSearchPath(formData.query);

            this.props.history.push(newPath);
        }

        /**
         * @returns {XML}
         */
        render() {
            var props = {
                ...this.props,
                onSubmit: this._onFormSubmit,
            };

            return <SearchForm {...props} />;
        }
    }

    SearchDispatcher.propTypes = {
        history: PropTypes.object.isRequired,
    };

    return withRouter(SearchDispatcher);
}
