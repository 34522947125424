import React from 'react';
import Heading from '../../../../shared/heading/Heading';
import styles from '../SideProductOverview.module.scss';

type Props = {
    title: string;
    thumbnailUrl: string;
    url: string;
    breadcrumbs: string[] | null;
    trackId: string;
    trackData?: string;
};

const SideProductOverviewInteractiveCaseCardItem = ({
    title,
    thumbnailUrl,
    url,
    breadcrumbs,
    trackId,
    trackData,
}: Props) => {
    return (
        <div>
            <a
                href={url}
                target="_blank"
                className={styles.linkWrapper}
                data-track-id={trackId || 'download-side-product'}
                data-track-data={trackData}
            >
                <div className={styles.thumbnailContainer}>
                    <img className={styles.thumbnail} src={thumbnailUrl} alt={`Casus preview: ${title}`} />
                </div>
                <div>
                    {breadcrumbs && (
                        <p className={styles.breadcrumb}>
                            {breadcrumbs &&
                                breadcrumbs.map((breadcrumb, index) => {
                                    return (
                                        <>
                                            {breadcrumb}
                                            {index + 1 !== breadcrumbs?.length && (
                                                <span className={styles.separator}> / </span>
                                            )}
                                        </>
                                    );
                                })}
                        </p>
                    )}
                    <Heading tag="h5" flatten className={styles.heading}>
                        {title}
                    </Heading>
                </div>
            </a>
        </div>
    );
};

export default SideProductOverviewInteractiveCaseCardItem;
