import * as apiClient from '../api/client/apiClient';
import { createAction, Thunk } from './factory';
import type { Action } from './factory';
import {
    createSideProductCategoryHighlightsUpdateApiPath,
    createSideProductOverviewApiPath,
} from '../routing/apiUrlGenerator';
import { toast } from 'react-toastify';
import { FETCH_SIDE_PRODUCT_OVERVIEW, UPDATE_HIGHLIGHTED_SIDE_PRODUCTS } from './types';

export function createFetchSideProductOverviewItemsAction(toolType?: string): Action {
    const promise: Promise<any> = apiClient.get(createSideProductOverviewApiPath(toolType));

    return createAction(FETCH_SIDE_PRODUCT_OVERVIEW, promise);
}

export function createUpdateHighlightedSideProductsAction(sideProductIds: string[]): Thunk {
    return (dispatch) => {
        const action = createAction(
            UPDATE_HIGHLIGHTED_SIDE_PRODUCTS,
            apiClient.post(createSideProductCategoryHighlightsUpdateApiPath(), { sideProductIds }),
            { sideProductIds }
        );

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            return toast.success('De uitgelichte items voor het hulpmiddel zijn gewijzigd!');
        });
    };
}
