import React, { ReactNode, VFC } from 'react';
import Heading from '../../../../shared/heading/Heading';
import styles from '../CardOverview.module.scss';
import ActionLink from '../../../../shared/ActionLink';
import { Link } from 'react-router-dom';
import createClassName from 'classnames';

type Props = {
    title: string;
    description: string;
    imageUrl?: string;
    icon?: ReactNode;
    linkText?: string;
    path: string;
    isHighlighted?: boolean;
    trackingId?: string;
    isEditorContent?: boolean;
    external?: boolean;
};

const Card: VFC<Props> = ({
    title,
    description,
    imageUrl,
    icon,
    linkText,
    path,
    isHighlighted = false,
    trackingId,
    external = false,
}) => {
    const cardClassNames = createClassName(styles.card, {
        [styles.isHighlighted]: isHighlighted,
    });

    const headingClassNames = createClassName(styles.heading, {
        [styles.isCropped]: !!imageUrl,
    });

    const content = (
        <div className={styles.container}>
            {imageUrl && (
                <div className={styles.imageContainer}>
                    <div className={styles.imageWrapper}>
                        <img src={imageUrl} alt={`${title} afbeelding`} className={styles.image} />
                    </div>
                </div>
            )}
            <div className={cardClassNames}>
                <div className={styles.contentWrapper}>
                    <div className={styles.headingContainer}>
                        {!!icon && <span className={styles.iconContainer}>{icon}</span>}
                        <Heading tag="h3" className={headingClassNames}>
                            {title}
                        </Heading>
                    </div>
                    <p className={styles.description}>{description}</p>
                    <ActionLink
                        to={path}
                        undecorated
                        arrowPosition="right"
                        additionalClassNames={styles.actionLink}
                        external={external}
                    >
                        {linkText || `Bekijk ${title.toLowerCase()}`}
                    </ActionLink>
                </div>
            </div>
        </div>
    );

    return external ? (
        <a href={path} className={styles.wrapper} data-track-id={trackingId} target={'_blank'}>
            {content}
        </a>
    ) : (
        <Link to={path} className={styles.wrapper} data-track-id={trackingId}>
            {content}
        </Link>
    );
};

export default Card;
