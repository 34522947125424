import styles from '../DecisionAidApp.module.scss';
import { appleStoreLink, googlePlayStoreLink } from '../utility/constants';
import appleStoreImageUrl from '../../../../../../images/ggz/app-store.png';
import googlePlayStoreImageUrl from '../../../../../../images/ggz/google-play.png';
import React, { FC } from 'react';

const AppStoreButtonGroup: FC = () => (
    <div className={styles.appStoreButtonGroup}>
        <a href={appleStoreLink} data-track-id="decision_aid_app_click">
            <img className={styles.appStoreLogo} alt="Apple Store logo" src={appleStoreImageUrl} />
        </a>
        <a href={googlePlayStoreLink} data-track-id="decision_aid_app_click">
            <img className={styles.appStoreLogo} alt="Google Play Store logo" src={googlePlayStoreImageUrl} />
        </a>
    </div>
);

export default AppStoreButtonGroup;
