import { ProjectSectionType } from '../constants/projectSection';
import { ExistingSubject } from '../context/comments/CommentSubjectContext';
import ParagraphDetailProject from '../model/paragraphDetail/ParagraphDetailProject';
import ProjectDetail from '../model/projectDetail/ProjectDetail';
import ParagraphDetail from '../model/paragraphDetail/ParagraphDetail';
import { generatePath } from './urlGenerator';

export function apiUrlGenerator(): string {
    return generatePath('project_start');
}

export function createAddWorkingGroupMemberToProjectApiPath(externalProjectId: string): string {
    return generatePath('add_working_group_member_to_project', {
        projectId: externalProjectId,
    });
}

export function createRemoveWorkingGroupMemberFromProjectApiPath(
    externalProjectId: string,
    externalUserId: string
): string {
    return generatePath('remove_working_group_member_from_project', {
        projectId: externalProjectId,
        userId: externalUserId,
    });
}

export function createAddExternalPartyMemberToProjectApiPath(externalProjectId: string): string {
    return generatePath('add_external_party_member_to_project', {
        projectId: externalProjectId,
    });
}

export function createRemoveExternalPartyMemberFromProjectApiPath(
    externalProjectId: string,
    externalUserId: string
): string {
    return generatePath('remove_external_party_member_from_project', {
        projectId: externalProjectId,
        userId: externalUserId,
    });
}

export function createAddSoundingBoardMemberToProjectApiPath(externalProjectId: string): string {
    return generatePath('add_sounding_board_member_to_project', {
        projectId: externalProjectId,
    });
}

export function createRemoveSoundingBoardMemberFromProjectApiPath(
    externalProjectId: string,
    externalUserId: string
): string {
    return generatePath('remove_sounding_board_member_from_project', {
        projectId: externalProjectId,
        userId: externalUserId,
    });
}

export function createRequestFeedbackFromSoundingBoardMembersApiPath(externalProjectId: string): string {
    return generatePath('project_request_feedback_from_sounding_board_members', {
        projectId: externalProjectId,
    });
}

export function createRequestFeedbackFromExternalPartyMembersApiPath(externalProjectId: string): string {
    return generatePath('project_request_feedback_from_external_party_members', {
        projectId: externalProjectId,
    });
}

export function createRemindFeedbackFromExternalPartyMembersApiPath(externalProjectId: string): string {
    return generatePath('project_external_party_members_remind_of_feedback_request', {
        projectId: externalProjectId,
    });
}

export function createRequestFeedbackFromWorkingGroupMembersApiPath(externalProjectId: string): string {
    return generatePath('project_request_feedback_from_working_group_members', {
        projectId: externalProjectId,
    });
}

export function createStopConsultationPhaseForProjectApiPath(externalProjectId: string): string {
    return generatePath('project_end_consultation_phase', {
        projectId: externalProjectId,
    });
}

export function createRequestAuthorizationFromExternalPartyMembersApiPath(externalProjectId: string): string {
    return generatePath('project_request_authorization_from_external_party_members', {
        projectId: externalProjectId,
    });
}

export function createRemindAuthorizationFromExternalPartyMembersApiPath(externalProjectId: string): string {
    return generatePath('project_external_party_members_remind_of_pending_authorization', {
        projectId: externalProjectId,
    });
}

export function createStartRevisionProjectApiPath(standardId: string): string {
    return generatePath('project_start_revision', { standardId });
}

export function createProjectOverviewApiPath(): string {
    return generatePath('project_overview');
}

export function createDashboardBranchOverviewApiPath(query = ''): string {
    return generatePath('ggz_branch_overview_api', { query });
}

export function createAddBranchApiPath(): string {
    return generatePath('ggz_branch_create_api');
}

export function createUpdateBranchApiPath(id: string): string {
    return generatePath('ggz_branch_update_api', { id });
}

export function createBranchEditApiPath(id: string): string {
    return generatePath('ggz_branch_view_api', {
        id,
    });
}

export function createDashboardReferenceOverviewApiPath(page: number, query = ''): string {
    return generatePath('project_reference_overview', {
        p: page,
        query,
    });
}

export function createCurrentUserApiPath(): string {
    return generatePath('api_current_user');
}

export function createSettingsApiPath(): string {
    return generatePath('ggz_settings_api');
}

export function createCloseProjectApiPath(externalId: string): string {
    return generatePath('project_close', { projectId: externalId });
}

export function createDuplicateProjectApiPath(externalId: string): string {
    return generatePath('project_duplicate', { projectId: externalId });
}

export function createAllowWorkingGroupMembersWritingAccessApiPath(externalId: string): string {
    return generatePath('project_authorize_toggle', { projectId: externalId });
}

export function createUpdateProjectStatusApiPath(externalId: string): string {
    return generatePath('project_update_status', { projectId: externalId });
}

export function createUpdateProjectApiPath(externalId: string): string {
    return generatePath('project_update', { projectId: externalId });
}

export function createProjectDetailApiPath(externalProjectId: string, allowClosed = false): string {
    return generatePath('project_detail', {
        id: externalProjectId,
        allowClosed,
    });
}

export function createProjectIndexApiPath(externalProjectId: string): string {
    return generatePath('project_index', { id: externalProjectId });
}

export function createChapterDetailApiPath(projectTypeSlug: string, projectSlug: string, chapterSlug: string): string {
    return generatePath('chapter_detail_by_slug', {
        type: projectTypeSlug,
        projectSlug: projectSlug,
        chapterSlug: chapterSlug,
    });
}

export function createProjectDetailBySlugApiPath(type: string, slug: string): string {
    return generatePath('project_detail_by_slug', { type, slug });
}

export function createAddChapterToProjectApiPath(externalProjectId: string): string {
    return generatePath('project_add_chapter', {
        projectId: externalProjectId,
    });
}

export function createArchiveCommentaryForChaptersApiPath(externalProjectId: string): string {
    return generatePath('comment_archive_selected', {
        projectId: externalProjectId,
    });
}

export function createUpdateProjectChapterApiPath(externalProjectId: string, externalChapterId: string): string {
    return generatePath('update_chapter', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
    });
}

export function createProjectRemoveChapterApiPath(externalProjectId: string, externalChapterId: string): string {
    return generatePath('project_remove_chapter', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
    });
}

export function createProjectGenerateConceptPDFApiPath(externalProjectId: string): string {
    return generatePath('project_generate_concept_pdf', {
        projectId: externalProjectId,
    });
}

export function createProjectGenerateCommentOverviewFileApiPath(externalProjectId: string): string {
    return generatePath('project_comments_export', {
        projectId: externalProjectId,
    });
}

export function createProjectMoveChapterApiPath(externalProjectId: string, externalChapterId: string): string {
    return generatePath('project_move_chapter', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
    });
}

export function createProjectMoveParagraphApiPath(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string
): string {
    return generatePath('chapter_move_paragraph', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
        paragraphId: externalParagraphId,
    });
}

export function createAddParagraphToChapterApiPath(externalChapterId: string, externalProjectId: string): string {
    return generatePath('chapter_add_paragraph', {
        chapterId: externalChapterId,
        projectId: externalProjectId,
    });
}

export function createParagraphDetailApiPath(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string,
    forReadingPurposes: boolean
): string {
    return generatePath('paragraph_detail', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
        id: externalParagraphId,
        forReading: forReadingPurposes,
    });
}

export function createUpdateSubParagraphApiPath(externalProjectId: string, externalSubParagraphId: string): string {
    return generatePath('update_sub_paragraph', {
        projectId: externalProjectId,
        subParagraphId: externalSubParagraphId,
    });
}

export function createUpdateSubParagraphTitleApiPath(externalProjectId: string, externalSubParagraphId: string): string {
    return generatePath('update_sub_paragraph_title', {
        projectId: externalProjectId,
        subParagraphId: externalSubParagraphId,
    });
}

export function createParagraphRemoveSubParagraphApiPath(
    externalProjectId: string,
    externalParagraphId: string,
    externalSubParagraphId: string
): string {
    return generatePath('paragraph_remove_sub_paragraph', {
        projectId: externalProjectId,
        paragraphId: externalParagraphId,
        subParagraphId: externalSubParagraphId,
    });
}

export function createAddSubParagraphToParagraphApiPath(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string
): string {
    return generatePath('add_sub_paragraph_to_paragraph', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
        paragraphId: externalParagraphId,
    });
}

export function createUpdateParagraphApiPath(externalProjectId: string, externalParagraphId: string): string {
    return generatePath('update_paragraph', {
        projectId: externalProjectId,
        paragraphId: externalParagraphId,
    });
}

export function createUpdateParagraphTitleApiPath(externalProjectId: string, externalParagraphId: string): string {
    return generatePath('update_paragraph_title', {
        projectId: externalProjectId,
        paragraphId: externalParagraphId,
    });
}

export function createChapterRemoveParagraphApiPath(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string
): string {
    return generatePath('chapter_remove_paragraph', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
        paragraphId: externalParagraphId,
    });
}

export function createProjectImageUploadApiPath(): string {
    return generatePath('project_upload_image');
}

export function createProjectBreadcrumbsApiPath(): string {
    return generatePath('project_breadcrumbs');
}

export function createProjectBreadcrumbsDetailApiPath(compositeId: string): string {
    return generatePath('project_breadrumb_detail', { compositeId });
}

export function createProjectReferenceSearchApiPath(query: string): string {
    return generatePath('project_reference_search', { query });
}

export function createProjectReferenceSearchByIdPath(id: number): string {
    return generatePath('project_reference_detail', { id });
}

export function createProjectReferencePath(description: string): string {
    return generatePath('project_reference_create', {
        description: description,
    });
}

export function createProjectReferenceEditApiPath(id: number): string {
    return generatePath('project_reference_edit', { id });
}

export function createProjectReferenceDeleteApiPath(id: number): string {
    return generatePath('project_reference_archive', { id });
}

export function createProjectSearchApiPath(
    query: string,
    page = 1,
    externalProjectId: string | undefined | null = null
): string {
    return generatePath('project_search', {
        query,
        page,
        projectId: externalProjectId,
    });
}

export function createProjectEmbedChapterApiPath(externalProjectId: string): string {
    return generatePath('project_embed_chapter', {
        projectId: externalProjectId,
    });
}

export function createProjectUpdatePatientIntroductionApiPath(externalProjectId: string): string {
    return generatePath('project_update_patient_introduction', {
        projectId: externalProjectId,
    });
}

export function createChapterEmbedParagraphApiPath(externalProjectId: string, externalChapterId: string): string {
    return generatePath('chapter_embed_paragraph', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
    });
}

export function createSupplyFeedbackApiPath(): string {
    return generatePath('project_supply_feedback');
}

export function createParagraphEmbedSubParagraphApiPath(
    externalProjectId: string,
    externalParagraphId: string
): string {
    return generatePath('paragraph_embed_sub_paragraph', {
        projectId: externalProjectId,
        paragraphId: externalParagraphId,
    });
}

export function createMoveSubParagraphApiPath(
    externalProjectId: string,
    externalParagraphId: string,
    externalSubParagraphId: string
): string {
    return generatePath('paragraph_move_sub_paragraph', {
        projectId: externalProjectId,
        paragraphId: externalParagraphId,
        subParagraphId: externalSubParagraphId,
    });
}

export function createProjectAddSideProductApiPath(externalProjectId: string): string {
    return generatePath('project_add_side_product', {
        projectId: externalProjectId,
    });
}

export function createProjectUpdateSideProductApiPath(externalProjectId: string, sideProductId: string) {
    return generatePath('project_update_side_product', {
        projectId: externalProjectId,
        id: sideProductId,
    });
}

export function createProjectMoveSideProductApiPath(externalProjectId: string, sideProductId: string) {
    return generatePath('project_move_side_product', {
        projectId: externalProjectId,
        id: sideProductId,
    });
}

export function createProjectRemoveSideProductApiPath(externalProjectId: string, sideProductId: string) {
    return generatePath('project_remove_side_product', {
        projectId: externalProjectId,
        id: sideProductId,
    });
}

export function createProjectUpdateLinkApiPath(externalProjectId: string, externalId: string) {
    return generatePath('project_update_link', {
        projectId: externalProjectId,
        linkId: externalId,
    });
}

export function createProjectAddLinkToProjectApiPath(externalProjectId: string) {
    return generatePath('project_add_link', {
        projectId: externalProjectId,
    });
}

export function createAuthorizeProjectApiPath(externalProjectId: string) {
    return generatePath('authorize_project_by_external_party_member', {
        projectId: externalProjectId,
    });
}

export function createEditAuthorizationStatusForMemberApiPath(externalProjectId: string) {
    return createAuthorizeProjectApiPath(externalProjectId);
}

export function createRemoveLinkFromProjectApiPath(externalProjectId: string, externalLinkId: string) {
    return generatePath('project_remove_link', {
        projectId: externalProjectId,
        linkId: externalLinkId,
    });
}

export function createMarkChapterSearchableApiPath(externalChapterId: string): string {
    return generatePath('chapter_mark_searchable', {
        chapterId: externalChapterId,
    });
}

export function createMarkChapterNotSearchableApiPath(externalChapterId: string): string {
    return generatePath('chapter_mark_not_searchable', {
        chapterId: externalChapterId,
    });
}

export function createPublishProjectApiPath(externalProjectId: string): string {
    return generatePath('project_publish', {
        projectId: externalProjectId,
    });
}

export function createUserRegistrationApiPath(): string {
    return generatePath('api_register');
}

export function createChangeEmailApiPath(): string {
    return generatePath('api_change_email');
}

export function createSubscribeToNewsletterApiPath(): string {
    return generatePath('api_newsletter_subscribe');
}

export function createEditProfileApiPath(externalUserId: string): string {
    return generatePath('api_edit_profile', {
        id: externalUserId,
    });
}

export function createChangePasswordApiPath(externalUserId: string): string {
    return generatePath('api_change_password', {
        id: externalUserId,
    });
}

export function createCheckEmailInUseApiPath(email: string): string {
    return generatePath('api_check_email_in_use', {
        email: email,
    });
}

export function createAddBookmarkApiPath(): string {
    return generatePath('api_bookmark_add');
}

export function createEditBookmarkApiPath(externalId: number): string {
    return generatePath('api_bookmark_edit', { id: externalId });
}

export function createAddBookmarkTagApiPath(): string {
    return generatePath('api_bookmark_tag_add');
}

export function createEditBookmarkTagApiPath(externalId: number): string {
    return generatePath('api_bookmark_tag_edit', { id: externalId });
}

export function createDeleteBookmarkTagApiPath(externalId: number): string {
    return generatePath('api_bookmark_tag_delete', {
        id: externalId,
    });
}

export function createRemoveBookmarkApiPath(externalId: number): string {
    return generatePath('api_bookmark_remove', { id: externalId });
}

export function createArticleOverviewApiPath(
    page: number,
    standardId: string | null,
    perPage: number | null,
    includeUnpublished: boolean
): string {
    const params: Record<string, any> = {
        page,
    };

    if (standardId) {
        params.standardId = standardId;
    }

    if (perPage) {
        params.perPage = perPage;
    }

    if (includeUnpublished) {
        params.includeUnpublished = '1';
    }

    return generatePath('api_article_index', params);
}

export function createArticleSpotlightApiPath(numberOfItems: number): string {
    return generatePath('api_article_spotlight', { take: numberOfItems });
}

export function createArticleDetailApiPath(id: number, isEdit: boolean): string {
    return generatePath('api_article_detail', {
        id,
        // eslint-disable-next-line camelcase
        is_edit: isEdit ? '1' : '0',
    });
}

export function createFetchPublishedPageDetailApiPath(slug: string) {
    return generatePath('ggz_page_detail_published_api', { page_slug: slug });
}

export function createFetchPageDetailApiPath(id: string, isEdit: boolean) {
    return generatePath('ggz_page_detail_api', {
        id,
        // eslint-disable-next-line camelcase
        is_edit: isEdit ? '1' : '0',
    });
}

export function createFetchPageOverviewApiPath(
    page: number,
    standardId: string | null,
    perPage: number | null,
    includeUnpublished: boolean
) {
    const params: Record<string, any> = {
        page,
    };

    if (standardId) {
        params.standardId = standardId;
    }

    if (perPage) {
        params.perPage = perPage;
    }

    if (includeUnpublished) {
        params.includeUnpublished = '1';
    }

    return generatePath('ggz_page_index_api');
}

export function createPageCreateApiPath(): string {
    return generatePath('ggz_page_create_api');
}

export function createPageUpdateApiPath(id: string): string {
    return generatePath('ggz_page_update_api', { id });
}

export function createPageDeleteApiPath(id: string): string {
    return generatePath('ggz_page_delete_api', { id });
}

export function createArticleCreateApiPath(): string {
    return generatePath('api_article_create');
}

export function createArticleUpdateApiPath(id: number): string {
    return generatePath('api_article_update', { id });
}

export function createArticleDeleteApiPath(id: number): string {
    return generatePath('api_article_delete', { id });
}

export function createForgotPasswordApiPath(): string {
    return generatePath('api_forgot_password');
}

export function createResetPasswordApiPath(token: string): string {
    return generatePath('api_reset_password', { token });
}

export function createGetCurrentUserBookmarksApiPath(): string {
    return generatePath('api_current_user_bookmarks');
}

export function createAnonimizeAccountApiPath(): string {
    return generatePath('api_anonimize_account');
}

export function createUserOverviewApiPath(page: number, query = ''): string {
    return generatePath('api_user_overview', {
        p: page,
        query,
    });
}

export function createUserDetailApiPath(externalUserId: string): string {
    return generatePath('api_user_detail', {
        userId: externalUserId,
    });
}

export function createUserUpdateApiPath(externalUserId: string): string {
    return generatePath('api_user_update', {
        userId: externalUserId,
    });
}

export function createAddUserApiPath(): string {
    return generatePath('api_user_create');
}

export function createUserDeactivateApiPath(externalUserId: string): string {
    return generatePath('api_user_deactivate', {
        userId: externalUserId,
    });
}

export function createSecurityVoteApiPath(): string {
    return generatePath('api_security_vote');
}

export function createStandardOverviewApiPath(): string {
    return generatePath('standard_publication_overview');
}

export function createStandardAllOverviewApiPath(): string {
    return generatePath('standard_all_overview');
}

export function createStandardArchivedOverviewApiPath(): string {
    return generatePath('standard_archived_overview');
}

export function createStandardArchiveApiPath(externalStandardId: string): string {
    return generatePath('standard_archive', {
        standardId: externalStandardId,
    });
}

export function createProjectCommentApiPath(externalProjectId: string): string {
    return generatePath('comment_project_comment', {
        projectId: externalProjectId,
    });
}

export function createParagraphCommentApiPath(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string
): string {
    return generatePath('comment_paragraph_comment', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
        paragraphId: externalParagraphId,
    });
}

export function createSubParagraphCommentApiPath(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string,
    externalSubParagraphId: string
): string {
    return generatePath('comment_subparagraph_comment', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
        paragraphId: externalParagraphId,
        subParagraphId: externalSubParagraphId,
    });
}

export function createReactToCommentApiPath(externalProjectId: string, externalCommentId: string): string {
    return generatePath('comment_react', {
        projectId: externalProjectId,
        commentId: externalCommentId,
    });
}

export function createArchiveCommentApiPath(externalProjectId: string, externalCommentId: string): string {
    return generatePath('comment_archive', {
        projectId: externalProjectId,
        id: externalCommentId,
    });
}

export function createToggleCommentVisibilityApiPath(externalProjectId: string, externalCommentId: string): string {
    return generatePath('comment_toggle_visibility', {
        projectId: externalProjectId,
        id: externalCommentId,
    });
}

export function createViewEventHistoryApiPath(
    externalProjectId: string,
    aggregate: string,
    aggregateId: string,
    currentPage: number,
    textOnly: boolean
): string {
    return generatePath('project_view_event_history', {
        projectId: externalProjectId,
        aggregate,
        aggregateId,
        page: currentPage,
        textOnly,
    });
}

export function createViewEventHistoryDetailsApiPath(externalProjectId: string, eventId: number): string {
    return generatePath('project_view_event_history_details', {
        projectId: externalProjectId,
        id: eventId,
    });
}

export function createProjectEventIndexApiPath(
    aggregateType: ProjectSectionType | null,
    aggregateId: string | null,
    externalProjectId: string | null,
    page: number
): string {
    const queryParams: Record<string, any> = {
        page,
    };

    if (aggregateType && aggregateId) {
        queryParams.aggregateType = aggregateType;
        queryParams.aggregateId = aggregateId;
    }

    if (externalProjectId) {
        queryParams.projectId = externalProjectId;
    }

    return generatePath('project_event_index', queryParams);
}

export function createFetchCommentsUrl(
    externalProjectId: string,
    externalChapterId: string | null,
    externalParagraphId: string | null,
    subject: ExistingSubject
): string {
    if (subject instanceof ParagraphDetailProject || subject instanceof ProjectDetail) {
        return generatePath('comment_project_overview', {
            projectId: externalProjectId,
        });
    }

    if (subject instanceof ParagraphDetail) {
        return generatePath('comment_paragraph_overview', {
            projectId: externalProjectId,
            chapterId: externalChapterId,
            paragraphId: subject.externalId,
        });
    }

    return generatePath('comment_subparagraph_overview', {
        projectId: externalProjectId,
        chapterId: externalChapterId,
        paragraphId: externalParagraphId,
        subParagraphId: subject.externalId,
    });
}

export function createSideProductOverviewApiPath(toolType?: string): string {
    if (!toolType) return generatePath('side_product_overview');

    return generatePath('side_product_overview', { toolType });
}

export function createSideProductCategoryDetailApiPath(id: string, isEdit: boolean): string {
    return generatePath('ggz_side_product_tool_detail_api', {
        id,
        // eslint-disable-next-line camelcase
        is_edit: isEdit ? '1' : '0',
    });
}

export function createSideProductCategoryDetailsApiPath(): string {
    return generatePath('ggz_side_product_tool_index_api');
}

export function createSideProductCategoryUpdateApiPath(id: string): string {
    return generatePath('ggz_side_product_tool_update_api', { id });
}

export function createFetchProjectOverviewPreviews(): string {
    return generatePath('project_overview_previews');
}

export function createSideProductCategoryHighlightsUpdateApiPath(): string {
    return generatePath('side_product_highlight');
}

export function createFetchFaqOverviewApiPath(page: number | null, perPage: number | null) {
    const params: Record<string, any> = {
        page,
    };

    if (perPage) {
        params.perPage = perPage;
    }

    return generatePath('ggz_faq_item_index_api');
}

export function createFetchFaqOverviewListApiPath() {
    return generatePath('ggz_faq_item_list_api');
}

export function createFaqCreateApiPath(): string {
    return generatePath('ggz_faq_item_create_api');
}

export function createFaqUpdateApiPath(id: string): string {
    return generatePath('ggz_faq_item_update_api', { id });
}

export function createFaqDeleteApiPath(id: string): string {
    return generatePath('ggz_faq_item_delete_api', { id });
}

export function createFetchFaqDetailApiPath(id: string, isEdit: boolean) {
    return generatePath('ggz_faq_item_detail_api', {
        id,
        // eslint-disable-next-line camelcase
        is_edit: isEdit ? '1' : '0',
    });
}

export function createAddRecommendedStandardsApiPath(standardId: string): string {
    return generatePath('standard_add_recommended_standards', { standardId: standardId });
}

export function createSideProductOpenFileApiPath(sideProductId: string): string {
    return generatePath('side_product_open_file', { sideProductId: sideProductId });
}
