// user
export const CHANGE_CURRENT_USER_AUTHENTICATION_STATUS = 'CHANGE_CURRENT_USER_AUTHENTICATION_STATUS';
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const EDIT_BOOKMARK = 'EDIT_BOOKMARK';
export const ADD_BOOKMARK_TAG = 'ADD_BOOKMARK_TAG';
export const DELETE_BOOKMARK_TAG = 'DELETE_BOOKMARK_TAG';
export const EDIT_BOOKMARK_TAG = 'EDIT_BOOKMARK_TAG';
export const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK';
export const GET_BOOKMARKS = 'GET_BOOKMARKS';
export const GET_BOOKMARK_TAGS = 'GET_BOOKMARK_TAGS';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ANONIMIZE_ACCOUNT = 'ANONIMIZE_ACCOUNT';
export const ADD_USER = 'ADD_USER';
export const CHECK_IS_GRANTED = 'CHECK_IS_GRANTED';
export const FETCH_USER_OVERVIEW = 'FETCH_USER_OVERVIEW';

// project
export const START_PROJECT = 'START_PROJECT';
export const FETCH_PROJECT_OVERVIEW = 'FETCH_PROJECT_OVERVIEW';
export const CLOSE_PROJECT = 'CLOSE_PROJECT';
export const DUPLICATE_PROJECT = 'DUPLICATE_PROJECT';

export const ALLOW_WORKING_GROUP_MEMBER_WRITING_ACCESS = 'ALLOW_WORKING_GROUP_MEMBER_WRITING_ACCESS';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_STATUS = 'UPDATE_PROJECT_STATUS';
export const FETCH_PROJECT_DETAILS = 'FETCH_PROJECT_DETAILS';
export const CLEAR_PROJECT_DETAILS = 'CLEAR_PROJECT_DETAILS';
export const FETCH_PROJECT_INDEX = 'FETCH_PROJECT_INDEX';
export const PROJECT_ADD_SIDE_PRODUCT = 'PROJECT_ADD_SIDE_PRODUCT';
export const PROJECT_UPDATE_SIDE_PRODUCT = 'PROJECT_UPDATE_SIDE_PRODUCT';
export const PROJECT_MOVE_SIDE_PRODUCT = 'PROJECT_MOVE_SIDE_PRODUCT';
export const PROJECT_REMOVE_SIDE_PRODUCT = 'PROJECT_REMOVE_SIDE_PRODUCT';
export const PROJECT_ADD_LINK = 'PROJECT_ADD_LINK';
export const PROJECT_UPDATE_LINK = 'PROJECT_UPDATE_LINK';
export const PROJECT_REMOVE_LINK = 'PROJECT_REMOVE_LINK';
export const PUBLISH_PROJECT = 'PUBLISH_PROJECT';
export const ADD_WORKING_GROUP_MEMBER_TO_PROJECT = 'ADD_WORKING_GROUP_MEMBER_TO_PROJECT';
export const REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT = 'REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT';
export const REQUEST_FEEDBACK_FROM_WORKING_GROUP_MEMBERS = 'REQUEST_FEEDBACK_FROM_WORKING_GROUP_MEMBERS';
export const ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT = 'ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT';
export const REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT = 'REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT';
export const ADD_SOUNDING_BOARD_MEMBER_TO_PROJECT = 'ADD_SOUNDING_BOARD_MEMBER_TO_PROJECT';
export const REMOVE_SOUNDING_BOARD_MEMBER_FROM_PROJECT = 'REMOVE_SOUNDING_BOARD_MEMBER_FROM_PROJECT';
export const REQUEST_FEEDBACK_FROM_EXTERNAL_PARTY_MEMBERS = 'REQUEST_FEEDBACK_FROM_EXTERNAL_PARTY_MEMBERS';
export const REMIND_FEEDBACK_FROM_EXTERNAL_PARTY_MEMBERS = 'REMIND_FEEDBACK_FROM_EXTERNAL_PARTY_MEMBERS';
export const REQUEST_FEEDBACK_FROM_SOUNDING_BOARD_MEMBERS = 'REQUEST_FEEDBACK_FROM_SOUNDING_BOARD_MEMBERS';
export const REQUEST_AUTHORIZATION_FROM_EXTERNAL_PARTY_MEMBERS = 'REQUEST_AUTHORIZATION_FROM_EXTERNAL_PARTY_MEMBERS';
export const REMIND_AUTHORIZATION_FROM_EXTERNAL_PARTY_MEMBERS = 'REMIND_AUTHORIZATION_FROM_EXTERNAL_PARTY_MEMBERS';
export const AUTHORIZE_PROJECT = 'AUTHORIZE_PROJECT';
export const EDIT_AUTHORIZATION_STATUS = 'EDIT_AUTHORIZATION_STATUS';
export const PROJECT_POST_COMMENT = 'PROJECT_POST_COMMENT';
export const PROJECT_FETCH_COMMENTS = 'PROJECT_FETCH_COMMENTS';
export const PROJECT_REACT_ON_COMMENT = 'PROJECT_REACT_ON_COMMENT';
export const PROJECT_ARCHIVE_COMMENT = 'PROJECT_ARCHIVE_COMMENT';
export const PROJECT_TOGGLE_COMMENT_VISIBILITY = 'PROJECT_TOGGLE_COMMENT_VISIBILITY';
export const PROJECT_STOP_CONSULTATION_PHASE = 'PROJECT_STOP_CONSULTATION_PHASE';
export const SET_LAST_SEARCHED_ITEM = 'SET_LAST_SEARCHED_ITEM';
export const PROJECT_UPDATE_PATIENT_INTRODUCTION = 'PROJECT_UPDATE_PATIENT_INTRODUCTION';

export const RESET_PARAGRAPH_DETAILS = 'RESET_PARAGRAPH_DETAILS';

// chapter
export const ADD_CHAPTER_TO_PROJECT = 'ADD_CHAPTER_TO_PROJECT';
export const UPDATE_PROJECT_CHAPTER = 'UPDATE_PROJECT_CHAPTER';
export const MOVE_PROJECT_CHAPTER = 'MOVE_PROJECT_CHAPTER';
export const PROJECT_EMBED_CHAPTER = 'PROJECT_EMBED_CHAPTER';
export const PROJECT_REMOVE_CHAPTER = 'PROJECT_REMOVE_CHAPTER';
export const FETCH_PROJECT_CHAPTER_DETAILS = 'FETCH_PROJECT_CHAPTER_DETAILS';
export const MARK_CHAPTER_SEARCHABLE = 'MARK_CHAPTER_SEARCHABLE';
export const MARK_CHAPTER_NOT_SEARCHABLE = 'MARK_CHAPTER_NOT_SEARCHABLE';
export const ARCHIVE_COMMENTS_CHAPTER = 'ARCHIVE_COMMENTS_CHAPTER';

// paragraph
export const ADD_PARAGRAPH_TO_CHAPTER = 'ADD_PARAGRAPH_TO_CHAPTER';
export const FETCH_PARAGRAPH_DETAILS = 'FETCH_PARAGRAPH_DETAILS';
export const UPDATE_PROJECT_PARAGRAPH = 'UPDATE_PROJECT_PARAGRAPH';
export const UPDATE_PROJECT_PARAGRAPH_TITLE = 'UPDATE_PROJECT_PARAGRAPH_TITLE';
export const PROJECT_REMOVE_PARAGRAPH = 'PROJECT_REMOVE_PARAGRAPH';
export const MOVE_PROJECT_PARAGRAPH = 'MOVE_PROJECT_PARAGRAPH';
export const PARAGRAPH_DETAIL_HAS_CHANGES_CHANGED = 'PARAGRAPH_DETAIL_HAS_CHANGES_CHANGED';
export const PROJECT_EMBED_PARAGRAPH = 'PROJECT_EMBED_PARAGRAPH';

// sub paragraph
export const UPDATE_PROJECT_SUBPARAGRAPH = 'UPDATE_PROJECT_SUBPARAGRAPH';
export const ADD_SUB_PARAGRAPH_TO_PARAGRAPH = 'ADD_SUB_PARAGRAPH_TO_PARAGRAPH';
export const START_SUB_PARAGRAPH = 'START_SUB_PARAGRAPH';
export const REMOVE_NON_PERSISTED_SUB_PARAGRAPH = 'REMOVE_NON_PERSISTED_SUB_PARAGRAPH';
export const PARAGRAPH_DETAIL_SUBPARAGRAPH_HAS_CHANGES_CHANGED = 'PARAGRAPH_DETAIL_SUBPARAGRAPH_HAS_CHANGES_CHANGED';
export const PROJECT_EMBED_SUB_PARAGRAPH = 'PROJECT_EMBED_SUB_PARAGRAPH';
export const MOVE_SUB_PARAGRAPH = 'MOVE_SUB_PARAGRAPH';
export const PROJECT_REMOVE_SUB_PARAGRAPH = 'PROJECT_REMOVE_SUB_PARAGRAPH';

// standard
export const FETCH_STANDARD_OVERVIEW = 'FETCH_STANDARD_OVERVIEW';
export const FETCH_STANDARD_ALL_OVERVIEW = 'FETCH_STANDARD_ALL_OVERVIEW';
export const ARCHIVE_STANDARD = 'ARCHIVE_STANDARD';
export const ADD_RECOMMENDED_STANDARDS = 'ADD_RECOMMENDED_STANDARDS';

// side product overview
export const FETCH_SIDE_PRODUCT_OVERVIEW = 'FETCH_SIDE_PRODUCT_OVERVIEW';

// article
export const FETCH_ARTICLE_OVERVIEW = 'FETCH_ARTICLE_OVERVIEW';

// branch
export const FETCH_BRANCH_OVERVIEW = 'FETCH_BRANCH_OVERVIEW';
export const ADD_BRANCH = 'ADD_BRANCH';
export const UPDATE_BRANCH = 'UPDATE_BRANCH';

// settings
export const FETCH_SETTINGS = 'FETCH_SETTINGS';

// content
export const UPDATE_HIGHLIGHTED_SIDE_PRODUCTS = 'UPDATE_HIGHLIGHTED_SIDE_PRODUCTS';

// reference
export const FETCH_REFERENCE_OVERVIEW = 'FETCH_REFERENCE_OVERVIEW';

export const ADD_REFERENCE = 'ADD_REFERENCE';
