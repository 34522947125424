import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    createFrontendEntryPath,
    createSearchPath,
    createUserRegistrationPath,
    createProjectDetailPath,
    createContactPagePath,
    createExplainStandardTypesPagePath,
    createDisclaimerPagePath,
    createPrivacyAndCookiesPagePath,
    createArticleDetailPath,
    createArticleOverviewPath,
    createStandardsOverviewPagePath,
    createStandardsPagePath,
    createSideProductsPagePath,
    createResetPasswordPath,
    createForgotPasswordPath,
    createPageDetailPath,
} from '../../../routing/urlGenerator';
import Home from './home/Home';
import PageNotFound from './page/components/PageNotFound';
import ProjectDetail from './lazyLoading/LazyLoadingProjectDetail';
import GlobalSearchResults from './searchResults/GlobalSearchResults'; // @todo add lazy loading?
import ContactPage from './page/components/ContactPage';
import ExplainStandardTypesPage from './page/components/ExplainStandardTypesPage';
import UserRegistration from './userRegistration/UserRegistration';
import DisclaimerPage from './page/components/DisclaimerPage';
import { createGetCurrentUserAction } from '../../../actions/userActionFactory';
import ForgotPassword from './forgotPassword/ForgotPassword';
import ResetPassword from './forgotPassword/ResetPassword';
import User from '../../../model/User';
import PrivacyCookiesPage from './page/components/PrivacyCookiesPage';
import type { GlobalState } from '../../../store/types';
import ErrorBoundary from '../shared/errorBoundary/ErrorBoundary';
import ArticleDetail from './articleDetail/ArticleDetail';
// import CommercialModal from './commercialModal/CommercialModal';
import ArticleOverview from './articleOverview/ArticleOverview';
import SubscribeToNewsletter from './subscribeToNewsletter/SubscribeToNewsletter';
import StandardOverviewPage from './standardOverviewPage/StandardOverviewPage';
import SideProductOverviewPage from './sideProductOverviewPage/SideProductOverviewPage';
import SideProductsPage from './sideProductsPage/SideProductsPage';
import { createGetSettingsAction } from '../../../actions/settingsActionFactory';
import { SettingsReducerState } from '../../../reducers/settingsReducer';
import { createForTeachersPagePath, createSideProductsOverviewPagePath } from '../../../routing/ggzUrlGenerator';
import GDPRTreshold from '../../shared/gdprTreshold/GDPRTreshold';
import PageDetail from './pageDetail/PageDetail';
import ForTeachersPage from './forTeachersPage/ForTeachersPage';

type ReduxSuppliedProps = {
    authenticated: boolean;
    currentUser: User | undefined | null;
    settings: SettingsReducerState;
};

const GgzApp = () => {
    const dispatch = useDispatch();

    const { authenticated, currentUser, settings } = useSelector<GlobalState, ReduxSuppliedProps>(
        ({ authenticated, currentUser, settings }) => ({ authenticated, currentUser, settings })
    );

    useEffect(() => {
        if (authenticated && !currentUser) {
            dispatch(createGetCurrentUserAction());
        }

        if (!settings) {
            dispatch(createGetSettingsAction());
        }
    }, []);

    return (
        <div className="wrapper">
            <ErrorBoundary>
                <SubscribeToNewsletter type="modal" />
                <Switch>
                    <Route path={createResetPasswordPath()} component={ResetPassword} />
                    <Route exact path={createFrontendEntryPath()} component={Home} />
                    <Route exact path={createStandardsPagePath()}>
                        <Redirect to={createStandardsOverviewPagePath()} />
                    </Route>
                    <Route exact path={createStandardsOverviewPagePath()} component={StandardOverviewPage} />
                    <Route exact path={createSideProductsPagePath()} component={SideProductsPage} />
                    <Route exact path={createSideProductsOverviewPagePath()} component={SideProductOverviewPage} />
                    <Route exact path={createContactPagePath()} component={ContactPage} />
                    <Route exact path={createExplainStandardTypesPagePath()} component={ExplainStandardTypesPage} />
                    <Route exact path={createDisclaimerPagePath()} component={DisclaimerPage} />
                    <Route exact path={createPrivacyAndCookiesPagePath()} component={PrivacyCookiesPage} />
                    <Route exact path={createSearchPath()} component={GlobalSearchResults} />
                    <Route exact path={createUserRegistrationPath()} component={UserRegistration} />
                    <Route exact path={createArticleOverviewPath()} component={ArticleOverview} />
                    <Route exact path={createArticleDetailPath()} component={ArticleDetail} />
                    <Route exact path={createPageDetailPath()} component={PageDetail} />
                    <Route exact path={createForTeachersPagePath()} component={ForTeachersPage} />
                    <Route path={createProjectDetailPath()} component={ProjectDetail} />
                    <Route exact path={createForgotPasswordPath()} component={ForgotPassword} />
                    <Route component={PageNotFound} />
                </Switch>
                {/*<CommercialModal />*/}
                <GDPRTreshold />
            </ErrorBoundary>
        </div>
    );
};

export default GgzApp;
