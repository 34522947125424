import React, { VFC } from 'react';
import { createSideProductsPagePath, createStandardsOverviewPagePath } from '../../../../../routing/urlGenerator';
import Heading from '../../../../shared/heading/Heading';
import spotlightImageUrl from '../../../../../../images/ggz/akwa-ggz-spotlight.png';
import styles from './Spotlight.module.scss';
import SearchForm, { SearchIconPlacement } from '../../../shared/searchForm/SearchForm';
import createDispatchSearchFormOnSubmitComponent from '../../../../../hoc/SearchDispatcher';
import ListIcon from '../../../../../../../icons/ggz/list.svg';
import SideProductsIcon from '../../../../../../../icons/ggz/side-products.svg';
import DecideTogetherIcon from '../../../../../../../icons/ggz/decide-together.svg';
import EvaluationIcon from '../../../../../../../icons/ggz/team-talks.svg';
import Card from '../../../shared/card/components/Card';
import CardOverview from '../../../shared/card/CardOverview';
import { createDecideTogetherPagePath } from '../../../../../routing/ggzUrlGenerator';
import SearchIcon from '../../../../../../../icons/ggz/search.svg';
import { useHistory } from 'react-router-dom';
import { writeToDataLayer } from '../../../../shared/gdprTreshold/utilities/dataLayerUtilities';

const AutoDispatchingSearchForm = createDispatchSearchFormOnSubmitComponent(SearchForm);

const Spotlight: VFC = () => {
    const history = useHistory();

    const onSuggestionClick = (searchValue: string) => {
        writeToDataLayer({
            event: 'search-suggestion-click',
            eventCategory: 'search-suggestion',
            eventAction: 'click',
            value: searchValue,
        });

        return history.push(`/zoekresultaten/${searchValue}?page=1`);
    };

    return (
        <article className={styles.spotlight}>
            <div className="container">
                <div className={styles.spotlightWrapper}>
                    <div className={styles.spotlightContent}>
                        <Heading tag="h1" variant="h2" flatten>
                            Zorgstandaarden voor de geestelijke gezondheidszorg
                        </Heading>
                        <div className={styles.subTitle}>gemaakt door patiënten, hun naasten en professionals</div>
                        {/* @ts-ignore types cannot be read for component as it is not part of Typescript */}
                        <AutoDispatchingSearchForm
                            className={styles.searchForm}
                            placeholder="Zoek op standaard of trefwoord"
                            searchIconPlacement={SearchIconPlacement.field}
                            formFieldType="solid"
                            style="dark"
                            showSubmitButton={false}
                            icon={<SearchIcon className="icon--color-primary form__field-icon" />}
                            rounded
                        />
                        <div className={styles.searchSuggestions}>
                            <em>
                                Veel gezocht:{' '}
                                <span
                                    onClick={() => onSuggestionClick('herstelondersteuning')}
                                    className={styles.suggestion}
                                >
                                    herstelondersteuning
                                </span>
                                ,{' '}
                                <span onClick={() => onSuggestionClick('trauma')} className={styles.suggestion}>
                                    trauma
                                </span>
                                ,{' '}
                                <span onClick={() => onSuggestionClick('zingeving')} className={styles.suggestion}>
                                    zingeving
                                </span>
                            </em>
                        </div>
                    </div>
                    <img className={styles.spotlightImage} src={spotlightImageUrl} alt="Akwa GGZ" />
                </div>
                <CardOverview>
                    <Card
                        isHighlighted
                        title="Zorgstandaarden A-Z"
                        description="Algemene aanbevelingen voor preventie, herkenning, diagnostiek en behandeling van psychische aandoeningen."
                        linkText="Bekijk standaarden"
                        path={createStandardsOverviewPagePath()}
                        icon={<ListIcon />}
                        trackingId="homepage_spotlight_action_link_click"
                    />
                    <Card
                        isHighlighted
                        title="Hulpmiddelen"
                        description="Hulpmiddelen bij het gebruik van standaarden, zoals werkkaarten, de keuzehulp-app en casussen."
                        path={createSideProductsPagePath()}
                        icon={<SideProductsIcon />}
                        trackingId="homepage_spotlight_action_link_click"
                    />
                    <Card
                        isHighlighted
                        title="Samen beslissen"
                        description="Ondersteuning voor Samen beslissen met diverse materialen zoals video, folder en webinar."
                        linkText="Meer over samen beslissen"
                        path={createDecideTogetherPagePath()}
                        icon={<DecideTogetherIcon />}
                        trackingId="homepage_spotlight_action_link_click"
                    />
                    <Card
                        isHighlighted
                        title="Evalueren"
                        description="Evalueren in de behandeling is onderdeel van het zorgproces en draagt bij aan goede zorg."
                        linkText="Meer over evalueren"
                        path={'https://akwaggz.nl/evalueren'}
                        icon={<EvaluationIcon />}
                        trackingId="homepage_spotlight_action_link_click"
                        external
                    />
                </CardOverview>
            </div>
        </article>
    );
};

export default Spotlight;
