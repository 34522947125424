import React from 'react';
import { Helmet } from 'react-helmet';
import { composeSeoTitle, truncateSeoDescription } from '../../../../../helper/seoHelper';
import type { DetailArticle } from '../../../../../model/Article';

type Props = {
    article: DetailArticle;
};

const ArticleDetailMeta = ({ article }: Props) => {
    const seoTitle = composeSeoTitle(null, article.meta.title, 'Artikelen');
    const seoDescription = truncateSeoDescription(article.meta.description);

    return (
        <Helmet>
            <title>{seoTitle}</title>
            <meta title={seoTitle} />
            <meta name="description" content={seoDescription} />
            <meta property="og:type" content="article" />
            <meta prefix="og: http://ogp.me/ns#" property="og:site_name" content="GGZ Standaarden" />
            <meta prefix="og: http://ogp.me/ns#" property="og:locale" content="nl_NL" />
            <meta prefix="og: http://ogp.me/ns#" property="og:title" content={seoTitle} />
            <meta prefix="og: http://ogp.me/ns#" property="og:description" content={seoDescription} />
            <meta prefix="og: http://ogp.me/ns#" property="og:url" content={window.location.toString()} />
            <meta prefix="og: http://ogp.me/ns#" property="og:type" content="article" />
        </Helmet>
    );
};

export default ArticleDetailMeta;
