import React, { VFC } from 'react';
import HeaderLogo from '../../../shared/header/components/HeaderLogo';
import ForgotPasswordForm from '../../shared/forgotPassword/ForgotPasswordForm';

const ForgotPassword: VFC = () => (
    <div className="wrapper wrapper--bg-secondary">
        <div className="container">
            <div className="layout layout--align-center">
                <div className="layout__item layout__item--xs-12 layout__item--md-10 layout__item--lg-7 layout__item--xl-6">
                    <header className="u-margin--top-lg u-margin--bottom-lg">
                        <HeaderLogo />
                    </header>
                    <main className="island island--large island--light">
                        <h3>Wachtwoord vergeten?</h3>
                        <p>
                            Vul in het onderstaande formulier je e-mailadres is, hierna wordt er een link naar je
                            gestuurd waarmee je wachtwoord gereset kan worden.
                        </p>
                        <ForgotPasswordForm allowLabels={false} />
                    </main>
                </div>
            </div>
        </div>
    </div>
);

export default ForgotPassword;
