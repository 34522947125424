import React from 'react';
import Heading from '../../../../shared/heading/Heading';
import type { DetailArticle } from '../../../../../model/Article';
import { formatLongDay } from '../../../../../helper/dateTimeHelper';

type Props = {
    article: DetailArticle;
};

const ArticleHeader = ({ article }: Props) => {
    const { title, author, publishedAt } = article;

    return (
        <header className="layout article-header">
            <div className="layout__item layout__item--lg-8">
                <Heading tag="h1" className="article-header__title">
                    {title}
                </Heading>
                <div className="article-header__meta">
                    <strong className="article-header__author">{author.name.full}</strong>
                    {author.profession && (
                        <>
                            <span> - </span>
                            <span className="article-header__profession">{article.author.profession}</span>
                        </>
                    )}
                    {publishedAt && (
                        <time dateTime={publishedAt} className="article-header__published-at">
                            Gepubliceerd op {formatLongDay(publishedAt)}
                        </time>
                    )}
                </div>
            </div>
        </header>
    );
};

export default ArticleHeader;
