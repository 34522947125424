import React, { VFC } from 'react';
import styles from '../OverviewPageLayout.module.scss';

type Props = {
    children: React.ReactNode;
};

const OverviewListWrapper: VFC<Props> = ({ children }) => <div className={styles.listWrapper}>{children}</div>;

export default OverviewListWrapper;
