import React, { VFC } from 'react';
import Heading from '../../../shared/heading/Heading';
import styles from './DecisionAidApp.module.scss';
import AppStoreButtonGroup from './components/AppStoreButtonGroup';
import MockupImage from './components/MockupImage';

const DecisionAidApp: VFC = () => (
    <section className="section section--light">
        <div className="container">
            <div className={styles.decisionAidAppWrapper}>
                <div className={styles.decisionAidAppDescription}>
                    <Heading tag="h2" variant="h3" flatten>
                        Download de keuzehulp app
                    </Heading>
                    <p>
                        Download de gratis app met keuzehulpen. Aan de hand van korte vragen volgt een concreet advies
                        voor zorg en behandeling, gebaseerd op de inhoud van de zorgstandaarden.{' '}
                        <a href="https://www.ggzstandaarden.nl/hulpmiddelen/keuzehulpen" target="_blank">
                            Lees meer.
                        </a>
                    </p>
                    <AppStoreButtonGroup />
                </div>
                <MockupImage />
            </div>
        </div>
    </section>
);

export default DecisionAidApp;
