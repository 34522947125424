import React, { VFC } from 'react';
import { Link } from 'react-router-dom';
import { StandardOverviewItem } from '../../../../../model/standardOverview/StandardOverviewItem';
import StandardTabsTooltip from './StandardTabsTooltip';

type Props = {
    item: StandardOverviewItem;
};

const StandardListItem: VFC<Props> = ({ item }) => (
    <StandardTabsTooltip item={item}>
        <Link to={item.path} className="link link--primary">
            {item.shortTitle ?? item.title}
        </Link>
    </StandardTabsTooltip>
);

export default StandardListItem;
