import React from 'react';
import Button, { LINK } from '../../buttons/Button';
import { createPrivacyAndCookiesPagePath } from '../../../../routing/urlGenerator';
import CheckIcon from '../../../../../../icons/ggz/check.svg';
import CloseIcon from '../../../../../../icons/ggz/close.svg';

type Props = {
    className: string;
    onConfirm: () => void;
    onDecline: () => void;
};

const CookieConsentPopup = ({ className, onConfirm, onDecline }: Props) => {
    if (window.location.pathname === createPrivacyAndCookiesPagePath()) {
        return null;
    }

    return (
        <div className={className} role="alertdialog" aria-describedby="cookie-consent-description" aria-hidden="true">
            <div className="cookie-consent-popup__content">
                <p className="cookie-consent-popup__description" id="cookie-alert-description">
                    Wij gebruiken functionele cookies om deze website goed te laten functioneren en analytische cookies
                    om het gebruik van de website te kunnen meten en verbeteren. Deze cookies gebruiken{' '}
                    <strong>geen persoonsgegevens</strong>.{' '}
                    <a
                        href={createPrivacyAndCookiesPagePath()}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cookie-consent-popup__link"
                    >
                        Meer informatie
                    </a>
                    .
                </p>
                <div className="cookie-consent-popup__actions">
                    <Button
                        style={LINK}
                        onClick={onConfirm}
                        additionalClassName="cookie-consent-popup__approve-button"
                        isCleared
                    >
                        <CheckIcon />
                    </Button>
                    <Button
                        style={LINK}
                        onClick={onDecline}
                        additionalClassName="cookie-consent-popup__decline-button"
                        isCleared
                    >
                        <CloseIcon />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsentPopup;
