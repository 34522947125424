import Tabs from '../../../../shared/tabs/Tabs';
import Tab from '../../../../shared/tabs/components/Tab';
import styles from '../SideProductOverview.module.scss';
import React, { useState } from 'react';
import { SideProductOverviewItemType } from '../../../../../model/sideProductOverview/SideProductOverview';
import SideProductOverview from '../SideProductOverview';
import SideProductOverviewItem from './SideProductOverviewItem';
import Heading from '../../../../shared/heading/Heading';
import { appleStoreLink, googlePlayStoreLink } from '../../../ggz/decisionAidApp/utility/constants';
import AppStoreButtonGroup from '../../../ggz/decisionAidApp/components/AppStoreButtonGroup';
import MockupImage from '../../../ggz/decisionAidApp/components/MockupImage';
import toolkitsImageUrl from '../../../../../../images/ggz/toolkits-multiple.jpg';

const FOR_PATIENTS = 'Voor patiënten';
const FOR_PROFESSIONALS = 'Voor professionals';

type Props = {
    toolkitsSideProducts: SideProductOverviewItemType[];
};

const ToolkitsSideProductOverview = ({ toolkitsSideProducts }: Props) => {
    const [activeTab, setActiveTab] = useState(FOR_PROFESSIONALS);

    if (!toolkitsSideProducts) {
        return null;
    }

    return (
        <div>
            <div>
                <Tabs style={'outline'}>
                    <Tab isActive={activeTab === FOR_PROFESSIONALS} onClick={() => setActiveTab(FOR_PROFESSIONALS)}>
                        <span className={styles.tab}>{FOR_PROFESSIONALS}</span>
                    </Tab>
                    <Tab isActive={activeTab === FOR_PATIENTS} onClick={() => setActiveTab(FOR_PATIENTS)}>
                        <span className={styles.tab}>{FOR_PATIENTS}</span>
                    </Tab>
                </Tabs>
            </div>
            <div>
                {activeTab === FOR_PROFESSIONALS && (
                    <div>
                        <div className={styles.content}>
                            <p>
                                Deze keuzehulp geeft je in een paar minuten concreet en patiënt-specifiek advies voor
                                zorg en behandeling. Vul hiervoor een beknopt aantal vragen in. Het advies kan je
                                gebruiken in het gesprek met de patiënt naasten, om samen te beslissen welke behandeling
                                het beste bij past. De keuzehulpen zijn allemaal gebaseerd op de zorgstandaarden. Ze
                                zijn ontwikkeld en getoetst door ggz-professionals.
                            </p>
                            <p>
                                Op dit moment zijn er keuzehulpen voor{' '}
                                <a href="https://web.alii.care/protocols/3338?client=akwa">ADHD</a>,{' '}
                                <a href="https://web.alii.care/protocols/f75e6902-1661-414a-ad80-7bb0ea11f359?client=akwa">Alcohol</a>,{' '}
                                <a href="https://web.alii.care/protocols/2952?client=akwa">Angststoornissen</a>,{' '}
                                <a href="https://web.alii.care/protocols/3594?client=akwa">Autisme</a>,{' '}
                                <a href="https://web.alii.care/protocols/3234?client=akwa">Bipolaire stoornissen</a>,{' '}
                                <a href="https://web.alii.care/protocols/9fa2388a-2dd5-49f0-a59f-568312ff69a3?client=akwa">
                                    Depressieve stoornissen
                                </a>
                                ,{' '}
                                <a href="https://web.alii.care/protocols/4669aae3-dc1a-4b70-b1e4-4d779b53a5f1?client=akwa">
                                    Drugs
                                </a>
                                ,{' '}
                                <a href="https://web.alii.care/protocols/5f2b09b2-a4fa-4713-852d-e42b17f4809e?client=akwa">
                                    Eetstoornissen
                                </a>
                                ,{' '}
                                <a href="https://web.alii.care/protocols/3158?client=akwa">
                                    Persoonlijkheidsstoornissen
                                </a>
                                , <a href="https://web.alii.care/protocols/3154?client=akwa">Psychose</a>,{' '}
                                <a href="https://web.alii.care/protocols/0230d044-6b39-4c1d-8f8a-d68dc78eb3e4?client=akwa">
                                    Psychotrauma
                                </a>{' '}
                                en{' '}
                                <a href="https://web.alii.care/protocols/1dcd8b26-8af3-42da-9d9d-ec2fef17968d?client=akwa">
                                    SOLK/ALK
                                </a>
                                .
                            </p>
                            <Heading tag="h5" flatten>
                                Keuzehulpen via desktopversie
                            </Heading>
                            <p>
                                De keuzehulpen zijn via een desktopversie beschikbaar bij de betreffende zorgstandaard
                                op GGZ Standaarden. Kijk daarvoor bij de standaard onder het tabblad ‘Hulpmiddelen’.
                            </p>
                        </div>
                        <div className={styles.decisionAidAppContainer}>
                            <div>
                                <Heading tag="h5" flatten>
                                    Keuzehulpen in gratis app
                                </Heading>
                                <p>
                                    Alle keuzehulpen zijn beschikbaar in de gratis app GGZ Standaarden via{' '}
                                    <a href={googlePlayStoreLink} target="_blank">
                                        Google Play
                                    </a>{' '}
                                    of in de{' '}
                                    <a href={appleStoreLink} target="_blank">
                                        Appstore
                                    </a>
                                    .
                                </p>
                                <AppStoreButtonGroup />
                            </div>
                            <MockupImage additionalClassName={styles.mockupImage} />
                        </div>
                    </div>
                )}
                {activeTab === FOR_PATIENTS && (
                    <div className={styles.content}>
                        <p>
                            Om als patiënt samen met je behandelaar de juiste keuzes te kunnen maken voor een
                            behandeling, is het belangrijk dat je weet welke mogelijkheden er allemaal zijn. De
                            keuzehulpen Samen beslissen geven patiënten en hun naasten dit overzicht. Die keuzehulpen
                            bij het begrijpen van de psychische klachten. Ze geven uitleg over hoe de mogelijke
                            behandelingen werken en wat ze aan voor- en nadelen hebben.
                        </p>
                        <p>
                            De keuzehulpen zijn gebaseerd op de zorgstandaarden en ontwikkeld door MIND met patiënten en
                            naasten uit hun achterban en professionals.
                            <br />
                            <br />
                            <a href={'https://akwaggz.nl/toolkits/'} target={'_blank'}>
                                Bestel hier kosteloos de hulpmiddelen
                            </a>
                        </p>
                        <img src={toolkitsImageUrl} alt={'Keuzehulpen'} className={styles.toolkitImage} />
                        <SideProductOverview emptyStateText="Geen resultaten..." style="list">
                            {toolkitsSideProducts.map((toolkitSideProduct) => (
                                <SideProductOverviewItem
                                    key={toolkitSideProduct.id}
                                    title={toolkitSideProduct.title}
                                    url={toolkitSideProduct.path || ''}
                                    trackId="download-side-product"
                                    trackData={JSON.stringify({
                                        type: toolkitSideProduct.type,
                                    })}
                                />
                            ))}
                        </SideProductOverview>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ToolkitsSideProductOverview;
