import React from 'react';
import PaginatedCollection from '../../../../../model/collection/paginatedCollection';
import { Link as AnchorLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import { createSearchPath } from '../../../../../routing/urlGenerator';
import ProjectSearchItem from '../../../../../model/ProjectSearchItem';
import {
    NESTED_SEARCHRESULTS_CONTAINER_ID,
    SIDE_PRODUCT_SEARCHRESULTS_CONTAINER_ID,
    TOPLEVEL_SEARCHRESULTS_CONTAINER_ID,
} from '../../../../../constants/searchResults';

type Props = {
    toplevelResults: PaginatedCollection<ProjectSearchItem>;
    sideProductResults: PaginatedCollection<ProjectSearchItem>;
    nestedResults: PaginatedCollection<ProjectSearchItem>;
    currentPage: number;
    currentQuery: string;
};

const CombinedAmountOfResults = ({
    toplevelResults,
    sideProductResults,
    nestedResults,
    currentPage,
    currentQuery,
}: Props) => {
    if (toplevelResults.totalNumberOfResults === 0 || sideProductResults.totalNumberOfResults === 0) {
        return null;
    }

    const amountOfToplevelResults = toplevelResults.totalNumberOfResults;
    const amountOfSideProductResults = sideProductResults.totalNumberOfResults;
    const amountOfNestedResults = nestedResults.totalNumberOfResults;

    const prefixedToplevelResults =
        amountOfToplevelResults === 1 ? '1 standaard' : `${amountOfToplevelResults} standaarden`;
    const prefixedSideProductResults =
        amountOfSideProductResults === 1 ? '1 hulpmiddel' : `${amountOfSideProductResults} hulpmiddelen`;
    const prefixedNestedResults =
        amountOfNestedResults === 1 ? '1 overig resultaat' : `${amountOfNestedResults} overige resultaten`;

    return (
        <header className="search-results__total-amount">
            {currentPage === 1 ? (
                <AnchorLink
                    to={TOPLEVEL_SEARCHRESULTS_CONTAINER_ID}
                    smooth
                    className="search-results__total-amount-link"
                >
                    {prefixedToplevelResults}
                </AnchorLink>
            ) : (
                <Link to={createSearchPath(currentQuery)} className="search-results__total-amount-link">
                    {prefixedToplevelResults}
                </Link>
            )}
            ,{' '}
            <AnchorLink
                to={SIDE_PRODUCT_SEARCHRESULTS_CONTAINER_ID}
                smooth
                className="search-results__total-amount-link"
            >
                {prefixedSideProductResults}
            </AnchorLink>{' '}
            en{' '}
            <AnchorLink to={NESTED_SEARCHRESULTS_CONTAINER_ID} smooth className="search-results__total-amount-link">
                {prefixedNestedResults}
            </AnchorLink>{' '}
            gevonden
        </header>
    );
};

export default CombinedAmountOfResults;
