import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../../../../shared/form/FormGroup';
import FormErrorMessage from '../../../../shared/form/FormErrorMessage';
import { validateObject } from '../../../../../validation/validator';
import Modal from '../../../../shared/modal/Modal';
import DisclaimerPage from '../../page/components/DisclaimerPage';
import Loader from '../../../../shared/Loader';
import { createDisclaimerPagePath, createPrivacyAndCookiesPagePath } from '../../../../../routing/urlGenerator';
import Recaptcha from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import PrivacyCookiesPage from '../../page/components/PrivacyCookiesPage';

const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_TOKEN;

class UserRegistrationForm extends Component {
    /**
     * @param {Object} props
     */
    constructor(props) {
        super(props);

        var fields = UserRegistrationForm.generateClearFieldsState();
        var errors = UserRegistrationForm.generateClearErrorState();

        this.state = {
            ...fields,
            errors: errors,
            showDisclaimerModal: false,
            showPrivacyModal: false,
            locked: false,
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._onOpenDisclaimerModal = this._onOpenDisclaimerModal.bind(this);
        this._onCloseDisclaimerModal = this._onCloseDisclaimerModal.bind(this);
        this._onOpenPrivacyModal = this._onOpenPrivacyModal.bind(this);
        this._onClosePrivacyModal = this._onClosePrivacyModal.bind(this);
        this._onReCaptchaSubmitted = this._onReCaptchaSubmitted.bind(this);

        // Refs
        this._recaptchaEl = null;
    }

    /**
     * @returns {Object}
     */
    static generateClearFieldsState() {
        return {
            firstName: '',
            preposition: '',
            lastName: '',
            email: '',
            password: '',
            agreement: false,
            privacy: false,
        };
    }

    /**
     * @returns {Object}
     */
    static generateClearErrorState() {
        return {
            firstName: [],
            preposition: [],
            lastName: [],
            email: [],
            password: [],
            agreement: [],
            privacy: [],
        };
    }

    /**
     * @private
     */
    _lock() {
        this.setState({ locked: true });
    }

    /**
     * @private
     */
    _unlock() {
        this.setState({ locked: false });
    }

    /**
     * @private
     */
    _reset() {
        this.setState({ ...UserRegistrationForm.generateClearFieldsState() });
    }

    /**
     * @public
     */
    onSubmitSuccess() {
        this._unlock();
        this._reset();
    }

    /**
     * @public
     *
     * @param {Object} errors
     */
    onSubmitFailure(errors) {
        var normalizedErrors = {
            ...UserRegistrationForm.generateClearErrorState(),
            ...errors,
        };

        if (typeof errors.reCaptchaToken !== 'undefined' && errors.reCaptchaToken.length > 0) {
            toast.error(
                'Er is mis gegaan met de verwerking van uw registratie. Probeer het nog eens, of neem contact met ons op.'
            );
        }

        this._recaptchaEl.reset();

        this.setState({ errors: normalizedErrors }, () => this._unlock());
    }

    /**
     * @param {Event} event
     * @private
     */
    _onSubmit(event) {
        event.preventDefault();

        // this pushes something to the ReCaptcha server and returns a token. When this token is received, the
        // onChange callback for the recaptcha is triggered. This is where we continue our submit sequence.
        // @see _onReCaptchaSubmitted
        this._recaptchaEl.execute();
    }

    /**
     * @returns {Object}
     *
     * @private
     */
    _extractCurrentInputFromState() {
        var input = {
            ...this.state,
        };

        delete input.errors;
        delete input.showDisclaimerModal;
        delete input.showPrivacyModal;
        delete input.locked;

        return input;
    }

    /**
     * @param {String} field
     * @param {Event} event
     *
     * @private
     */
    _onFieldChange(field, event) {
        var value = event.target.value;

        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }

        this.setState({
            [field]: value,
        });
    }

    /**
     * @returns {Promise}
     *
     * @private
     */
    _validate() {
        var input = this._extractCurrentInputFromState();

        return validateObject(input, this.props.constraintSet).then((result) => {
            this.setState({
                errors: result.errorMessages,
            });

            return result.containsErrors;
        });
    }

    /**
     * @param {Event} event
     *
     * @private
     */
    _onOpenDisclaimerModal(event) {
        event.preventDefault();
        this.setState({ showDisclaimerModal: true });
    }

    /**
     * @private
     */
    _onCloseDisclaimerModal() {
        this.setState({ showDisclaimerModal: false });
    }

    /**
     * @param {Event} event
     *
     * @private
     */
    _onOpenPrivacyModal(event) {
        event.preventDefault();
        this.setState({ showPrivacyModal: true });
    }

    /**
     * @private
     */
    _onClosePrivacyModal() {
        this.setState({ showPrivacyModal: false });
    }

    /**
     * @param {String} fieldName
     *
     * @returns {Array|null}
     *
     * @private
     */
    _renderFormFieldErrorMessages(fieldName) {
        var { errors } = this.state;

        if (!errors[fieldName]) {
            return null;
        }

        return errors[fieldName].map((error, index) => {
            return <FormErrorMessage key={index}>{error}</FormErrorMessage>;
        });
    }

    /**
     * @param {String} fieldName
     * @param {String|null=} label
     * @param {String|null=} placeholder
     * @param {String=} type
     * @param {Boolean=} autoFocus
     *
     * @returns {String}
     *
     * @private
     */
    _renderTextFieldGroup(fieldName, label = null, placeholder = null, type = 'text', autoFocus = false) {
        var { errors, locked } = this.state;

        return (
            <FormGroup hasErrors={errors[fieldName].length > 0}>
                {label ? (
                    <label className="form__label" htmlFor={fieldName}>
                        {label}
                    </label>
                ) : null}
                <input
                    type={type}
                    name={fieldName}
                    id={fieldName}
                    className="form__field"
                    placeholder={placeholder}
                    value={this.state[fieldName]}
                    disabled={locked}
                    onChange={this._onFieldChange.bind(this, fieldName)}
                    autoFocus={autoFocus}
                />
                {this._renderFormFieldErrorMessages(fieldName)}
            </FormGroup>
        );
    }

    /**
     * @returns {String}
     *
     * @private
     */
    _renderAgreementCheckbox() {
        var { errors, agreement, locked } = this.state;

        return (
            <FormGroup hasErrors={errors.agreement.length > 0}>
                <div className="form-choice form-choice--checkbox">
                    <input
                        type="checkbox"
                        name="agreement"
                        className="form-choice__input"
                        id="agreement-checkbox"
                        onChange={this._onFieldChange.bind(this, 'agreement')}
                        checked={agreement}
                        disabled={locked}
                    />
                    <label className="form-choice__label" htmlFor="agreement-checkbox">
                        <div>
                            Ik ga akkoord met de &nbsp;
                            <a href={createDisclaimerPagePath()} onClick={this._onOpenDisclaimerModal} className="link">
                                algemene voorwaarden
                            </a>
                        </div>
                    </label>
                    {this._renderFormFieldErrorMessages('agreement')}
                </div>
            </FormGroup>
        );
    }

    /**
     * @returns {String}
     *
     * @private
     */
    _renderPrivacyCheckbox() {
        var { errors, privacy, locked } = this.state;

        return (
            <FormGroup hasErrors={errors.privacy.length > 0}>
                <div className="form-choice form-choice--checkbox">
                    <input
                        type="checkbox"
                        name="privacy"
                        className="form-choice__input"
                        id="privacy-checkbox"
                        onChange={this._onFieldChange.bind(this, 'privacy')}
                        checked={privacy}
                        disabled={locked}
                    />
                    <label className="form-choice__label" htmlFor="privacy-checkbox">
                        <div>
                            Ik geef toestemming dat de door mij opgegeven persoonsgegevens mogen worden bewaard in
                            overeenstemming met het{' '}
                            <a
                                href={createPrivacyAndCookiesPagePath()}
                                onClick={this._onOpenPrivacyModal}
                                className="link"
                            >
                                privacybeleid
                            </a>
                        </div>
                    </label>
                    {this._renderFormFieldErrorMessages('privacy')}
                </div>
            </FormGroup>
        );
    }

    /**
     * @returns {String}
     *
     * @private
     */
    _renderSubmit() {
        if (this.state.locked) {
            return <Loader />;
        }

        return (
            <FormGroup hasErrors={false}>
                <button type="submit" className="button button--primary button--large">
                    Registreren
                </button>
            </FormGroup>
        );
    }

    /**
     * @param {String} reCaptchaToken
     * @private
     */
    _onReCaptchaSubmitted(reCaptchaToken) {
        this._validate().then((containsErrors) => {
            if (!containsErrors) {
                this._lock();

                this.props.onSubmit(this._extractCurrentInputFromState(), reCaptchaToken);
            } else {
                this._recaptchaEl.reset();
            }
        });
    }

    /**
     * @returns {String}
     */
    render() {
        var { showDisclaimerModal, showPrivacyModal } = this.state;

        return (
            <form onSubmit={this._onSubmit} className="form form--disable-field-labels" noValidate>
                <label className="form__label">Naam</label>
                <div className="layout">
                    <div className="layout__item layout__item--sm-5">
                        {this._renderTextFieldGroup('firstName', null, 'Voornaam', 'text', true)}
                    </div>
                    <div className="layout__item layout__item--sm-2 layout__item--skinny-sm">
                        {this._renderTextFieldGroup('preposition', null, 't.v.')}
                    </div>
                    <div className="layout__item layout__item--sm-5">
                        {this._renderTextFieldGroup('lastName', null, 'Achternaam')}
                    </div>
                </div>
                {this._renderTextFieldGroup('email', 'E-mail', 'E-mail', 'email')}
                {this._renderTextFieldGroup('password', 'Wachtwoord', 'Wachtwoord', 'password')}
                {this._renderAgreementCheckbox()}
                {this._renderPrivacyCheckbox()}
                <Recaptcha
                    ref={(recaptchaEl) => (this._recaptchaEl = recaptchaEl)}
                    sitekey={RECAPTCHA_SITE_KEY}
                    size="invisible"
                    onChange={this._onReCaptchaSubmitted}
                />
                {this._renderSubmit()}

                <Modal
                    title="Algemene voorwaarden"
                    isOpen={showDisclaimerModal}
                    onDecline={this._onCloseDisclaimerModal}
                >
                    <DisclaimerPage renderForModal={true} />
                </Modal>
                <Modal title="Privacy- en cookiebeleid" isOpen={showPrivacyModal} onDecline={this._onClosePrivacyModal}>
                    <PrivacyCookiesPage renderForModal={true} />
                </Modal>
            </form>
        );
    }
}

UserRegistrationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    constraintSet: PropTypes.object.isRequired,
};

export default UserRegistrationForm;
