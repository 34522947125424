import React, { useMemo } from 'react';
import { StandardOverviewItem } from '../../../../../model/standardOverview/StandardOverviewItem';
import { resolveLinkInformationForTab } from '../../../../../resolver/projectDetailTabsResolver';
import { Link } from 'react-router-dom';
import Heading from '../../../../shared/heading/Heading';
import { resolveTabQuicklinks } from '../utility/tabQuicklinksResolver';
import useCurrentTenant from '../../../../../hooks/useCurrentTenant';

type Props = {
    item: StandardOverviewItem;
};

const StandardTabsTooltipContent: React.FC<Props> = ({ item }) => {
    const { tenant } = useCurrentTenant();
    const tabsToBeDisplayed = useMemo(() => resolveTabQuicklinks(item), [item.sideProductTypes, item.linkTypes]);

    if (!tenant) return null;

    const links = tabsToBeDisplayed.map((identifier) =>
        resolveLinkInformationForTab(identifier, tenant, item.type, item.typeSlug, item.slug)
    );

    return (
        <>
            <Heading tag="h6">{item.title}</Heading>
            <ul>
                {links.map(({ title, path }) => (
                    <li key={path}>
                        <Link
                            to={path}
                            className="link link--secondary"
                            data-track-id="standard_tooltip_link"
                            data-track-data={JSON.stringify({ title })}
                        >
                            {title}
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default StandardTabsTooltipContent;
